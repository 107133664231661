/**
 * Created by mac on 2/25/20
 */

OpenCards.prototype.acceptCard = function (card) {
    card.collectMarksAndComponents();
    if (!card.isOpen()) {
        card.flip();
    }

    var playCallback = function (timeout) {
        timeout = timeout || Card.PLAY_TIMEOUT;
        card.registerAnimationTime(timeout);

        this.addCard(card);
        this.moveToOpen(card, timeout);
        this.counter.setTimeout(function () {}, timeout * 1000);
    }.bind(this);

    card.beforePlayCard(function () {
        var interceptor = Game.currentGame.getInterceptor(card);
        if (interceptor) {
            interceptor.intercept(card, playCallback);
        } else {
            playCallback();
        }
    });
};

OpenCards.prototype.shift = function (card, silent) {
    var lastTop = this.getCurrentCard();
    if (!silent) {
        Game.currentGame.counter.setTimeout(function () {}, OpenCards.SHIFT_TIMEOUT * 1000);
    }
    this.addCard(card);
    this.trigger("shift", card, lastTop, silent);
};

OpenCards.prototype.moveToOpen = function (card, duration) {
    card.trigger("moveToOpen", duration, function () {
        if (card.owner === this) {
            this.trigger("hidePrevious", card);
        }
    }.bind(this));
};

OpenCards.prototype.replaceCurrentCard = function (oldCard, newCard, delay) {
    if (this.cards.length > 0 && this.cards[this.cards.length - 1] === oldCard) {
        this.cards[this.cards.length - 1] = newCard;
        this.trigger("beforeReplace", newCard);
        setTimeout(function () {
            this.trigger("replace", oldCard, newCard);
        }.bind(this), delay || 0);
    }
};

OpenCards.prototype.canPlay = function (card) {
    var open = this.getCurrentCard();
    return open && card.canPlay(open);
};

OpenCards.prototype.pop = function () {
    var card = this.cards.pop();
    Game.currentGame.counter.setTimeout(function () {}, 0);

    card.setOwner(Game.currentGame.table);
    this.trigger("pop", card);
    return card;
};

OpenCards.prototype.isFull = function () {
    return false;
};

OpenCards.SHIFT_TIMEOUT = 0.3;