/**
 * Created by olga on 12.01.2024
 */

var EditorCardBlock = function (options) {
    this.cards = options.cards;
    Object.assign(this, options.rect);
    this.r = 0;

    this.onGetView = function () {};
    this.onSelect = function () {};
    this.onActivateAlignPoint = function () {};
    this.onDeactivateAlignPoints = function () {};
};

EditorCardBlock.prototype.getViewPosition = function () {
    return { point: cc.p(this.x, this.y) };
};

EditorCardBlock.prototype.getZ = function () {
    return 100;
};

EditorCardBlock.prototype.activateAlignPoint = function (align) {
    this.onActivateAlignPoint(align);
};

EditorCardBlock.prototype.deactivateAlignPoints = function () {
    this.onDeactivateAlignPoints();
};

EditorCardBlock.prototype.getAlignLines = function () {
    var lines = {
        x: [{ x: this.x, align: EditorCardBlock.ALIGN.LEFT }, { x: this.x + this.width / 2, align: EditorCardBlock.ALIGN.CENTER_X }, { x: this.x + this.width, align: EditorCardBlock.ALIGN.RIGHT }],
        y: [{ y: this.y, align: EditorCardBlock.ALIGN.DOWN }, { y: this.y + this.height / 2, align: EditorCardBlock.ALIGN.CENTER_Y }, { y: this.y + this.height, align: EditorCardBlock.ALIGN.UP }]
    };

    Object.values(lines).flat().forEach(function (line) {
        line.block = this;
    }.bind(this));
    return lines;
};

EditorCardBlock.prototype.select = function (select) {
    this.selected = select;
    this.onSelect(select);
};

EditorCardBlock.prototype.getRotation = Card.prototype.getRotation;
EditorCardBlock.prototype.setPosition = Card.prototype.setPosition;

EditorCardBlock.ALIGN = {
    CENTER_X: "centerX",
    CENTER_Y: "centerY",
    UP: "up",
    DOWN: "down",
    LEFT: "left",
    RIGHT: "right"

};