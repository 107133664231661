/**
 * Created by Andrey Popov on 12/28/22.
 */

var CardsCounterView = cleverapps.Layout.extend({
    ctor: function (cardCounter) {
        this.cardCounter = cardCounter;

        var caption = cleverapps.UI.generateTTFText("Cards: ", cleverapps.styles.FONTS.EDITOR_BLUE_TEXT);
        var value = cleverapps.UI.generateTTFText("_____", cleverapps.styles.FONTS.EDITOR_BLUE_TEXT);
        var warning = cleverapps.UI.generateTTFText("no stacks", cleverapps.styles.FONTS.EDITOR_BLUE_TEXT);

        cardCounter.onRefresh = this.createListener(function () {
            value.setFontFillColor(cardCounter.isValid ? cleverapps.styles.COLORS.COLOR_BLUE : cleverapps.styles.COLORS.DARK_RED);
            value._setUpdateTextureDirty();

            value.setString(cardCounter.countOfCards + (cardCounter.isValid ? "" : "!"));

            if (cardCounter.existsStackFromCards) {
                warning.setFontFillColor(cleverapps.styles.COLORS.DARK_RED);
                warning._setUpdateTextureDirty();
                warning.setString("stacks!");
            } else {
                warning.setFontFillColor(cleverapps.styles.COLORS.COLOR_BLUE);
                warning._setUpdateTextureDirty();
                warning.setString("no stacks");
            }
        });

        cleverapps.UI.applyHover(this, {
            onMouseOver: this.onMouseOver.bind(this),
            onMouseOut: this.onMouseOut.bind(this)
        });

        var layout = new cleverapps.Layout([caption, value], {
            direction: cleverapps.UI.HORIZONTAL
        });

        this._super([layout, warning], {
            direction: cleverapps.UI.VERTICAL
        });

        cardCounter.onRefresh();
    },

    onMouseOver: function () {
        if (this.hint) {
            this.hint.removeFromParent();
        }

        if (!this.cardCounter.isValid) {
            this.hint = new cleverapps.UI.Tooltip(this.background, {
                text: "Should be from " + this.cardCounter.lowValidValue + " to " + this.cardCounter.upValidValue,
                size: cleverapps.styles.UI.Button.tooltipSize,
                location: cleverapps.UI.Tooltip.LOCATION_ABOVE
            });
            cleverapps.scenes.getRunningScene().movingNode.addChild(this.hint);
        }
    },

    onMouseOut: function () {
        if (this.hint) {
            this.hint.removeFromParent();
        }
    }
});