/**
 * Created by Andrey Popov on 22.09.20
 */

var SuitCardView = CardView.extend({
    ctor: function (card) {
        this._super(card, {
            animation_json: bundles.card.jsons.suit_card_json
        });
    }
});