/**
 * Created by mac on 2/25/20
 */

cleverapps.Combo = function (game, options) {
    cleverapps.EventEmitter.call(this);

    options = options || {};

    this.game = game;
    this.level = options.level || 0;
    this.currentGoal = options.goal || 0;
    this.progressPoints = options.progressPoints || new Array(cleverapps.Combo.COMBO_LEVEL_WITH_PRIZE);
    this.resetAfterPrize = false;
};

cleverapps.Combo.prototype = Object.create(cleverapps.EventEmitter.prototype);
cleverapps.Combo.prototype.constructor = cleverapps.Combo;

cleverapps.Combo.prototype.getInfo = function () {
    return {
        level: this.level,
        goal: this.currentGoal,
        progressPoints: this.progressPoints
    };
};

cleverapps.Combo.prototype.reset = function () {
    if (this.level > 0) {
        this.level = 0;
        this.trigger("changeLevel");
    }

    this.resetAfterPrize = false;
};

cleverapps.Combo.prototype.isFull = function () {
    return this.level >= cleverapps.Combo.COMBO_LEVEL_WITH_PRIZE;
};

cleverapps.Combo.prototype.increaseLevel = function (progressPoint) {
    this.level++;
    this.resetAfterPrize = false;
    if (this.isFull()) {
        cleverapps.eventBus.trigger("taskEvent", DailyTasks.GET_FULL_COMBO);
        if (this.level > cleverapps.Combo.COMBO_LEVEL_WITH_PRIZE) {
            this.afterOverflow();
        }
    }

    this.afterIncrease();

    if (progressPoint) {
        this.progressPoints[this.level - 1] = progressPoint;
    }
    this.trigger("changeLevel");

    cleverapps.audio.playSound(bundles.game.urls["combo_effect_" + this.level]);
};

cleverapps.Combo.prototype.decreaseLevel = function () {
    if (this.level > 0) {
        this.level--;
        this.trigger("changeLevel");
    } else if (this.resetAfterPrize) {
        this.level = cleverapps.Combo.COMBO_LEVEL_WITH_PRIZE - 1;
        this.afterDecrease();
        this.trigger("changeLevel");
        this.resetAfterPrize = false;
    }
};

cleverapps.Combo.prototype.afterIncrease = function () {
    cleverapps.audio.playSound(bundles.game.urls.scale_bonus_effect);

    if (this.isFull()) {
        cleverapps.exclamation.show(cleverapps.Random.mathChoose(cleverapps.exclamation.getCongratulations()));
        cleverapps.audio.playSound(bundles.game.urls.reward_effect);
    }
};

cleverapps.Combo.prototype.afterDecrease = function () {
    do {
        this.currentGoal = Math.max(0, this.currentGoal - 1);
    } while (!this.isCurrentGoalAvailable());

    this.trigger("updateGoal");
};

cleverapps.Combo.prototype.afterOverflow = function () {
    this.spawn();
};

cleverapps.Combo.prototype.spawn = function () {
    if (!this.isFull()) {
        return;
    }

    var cards = cleverapps.Combo.GOALS[this.currentGoal].cards;
    cards.map(function (card) {
        return TileFactory.Create(card, {
            noMarks: true
        });
    }).forEach(function (card, i) {
        this.game.setTimeout(function () {
            this.giveReward(card);
        }.bind(this), i * 300);
    }, this);

    this.level -= cleverapps.Combo.COMBO_LEVEL_WITH_PRIZE;
    this.resetAfterPrize = true;
    do {
        this.currentGoal = (this.currentGoal + 1) % cleverapps.Combo.GOALS.length;
    } while (!this.isCurrentGoalAvailable());

    this.game.setTimeout(function () {
        this.trigger("changeLevel");
        this.trigger("updateGoal");
    }.bind(this), 300);
};

cleverapps.Combo.prototype.giveReward = function (card) {
    this.trigger("reward", card);

    this.game.setTimeout(function () {
        Game.currentGame.hand.push(card, { fromTable: true });
    }, cleverapps.Combo.FLY_DURATION);
};

cleverapps.Combo.prototype.isCurrentGoalAvailable = function () {
    var available = cleverapps.Combo.GOALS[this.currentGoal].available;
    return !available || cleverapps.user.checkAvailable(available);
};

cleverapps.Combo.isAvailable = function () {
    return cleverapps.user.checkAvailable(cleverapps.Combo.AVAILABLE);
};

cleverapps.Combo.GOALS = [{
    cards: [{
        feature: "regular"
    }]
}, {
    cards: [{
        feature: "regular"
    }, {
        feature: "regular"
    }]
}, {
    cards: [{
        feature: "wild"
    }],
    available: { level: 0.65 }
}];

cleverapps.Combo.AVAILABLE = {
    level: 0.26
};

cleverapps.Combo.COMBO_LEVEL_WITH_PRIZE = 4;

cleverapps.Combo.FLY_DURATION = 300;
