/**
 * Created by Andrey Popov on 12.05.20
 */

TilesTutorial.TUTORIAL_LEVELS = [
    {
        episode: 0,
        level: 0,
        handValues: ["2h"],
        tutorial: [{
            force: "InitialTutorialMessage1"
        }]
    },
    {
        episode: 0,
        level: 1,
        handValues: ["2s", "6", "2"],
        tutorial: cleverapps.arrayFill(3, { action: "highlight" }).concat([{
            force: "HandTutorialMessage",
            amountExtraCards: 8
        }])
    },
    {
        episode: 0,
        level: 2,
        handValues: ["k", "6", "8"],
        tutorial: [{
            force: "BonusTutorialMessage",
            filter: ["4"]
        }].concat(cleverapps.arrayFill(2, { action: "highlight" }))
    },
    {
        episode: 0,
        level: 5,
        handValues: ["8"],
        tutorial: [{
            force: "WildCardTutorialMessage",
            type: TilesTutorial.TYPE_WILDCARD
        }, {
            force: ""
        }]
    },
    {
        episode: 1,
        level: 0,
        handValues: ["j"],
        tutorial: [{
            type: TilesTutorial.TYPE_JACKPOT,
            force: "JackpotTutorialMessage",
            filter: ["t"]
        }]
    },
    {
        episode: 1,
        level: 7,
        importantCards: [IceOnCard],
        handValues: ["9"],
        tutorial: [{
            filter: [IceOnCard],
            force: "IceTutorialMessage"
        }]
    },
    {
        episode: 1,
        level: 13,
        handValues: ["2"],
        tutorial: [{
            force: "IncDecValueTutorialMessage"
        }]
    },
    {
        episode: 2,
        level: 9,
        handValues: ["q"],
        tutorial: [
            {
                force: "KeyLockTutorialMessage"
            }
        ]
    },
    {
        episode: 3,
        level: 0,
        handValues: ["5"],
        importantCards: [StreakCard],
        tutorial: [{
            force: "DogStreakTutorialMessage",
            filter: ["4", StreakCard]
        }]
    },
    {
        episode: 3,
        level: 3,
        handValues: ["2"],
        importantCards: [SharkCard],
        tutorial: [{
            force: "SharkTutorialMessage",
            filter: ["3", SharkCard]
        }]
    },
    {
        episode: 3,
        level: 7,
        handValues: ["9"],
        tutorial: [{
            force: "BombTutorialMessage",
            filter: []
        }]
    },
    {
        episode: 4,
        level: 0,
        handValues: ["4"],
        tutorial: [{ force: "TrumpTutorialMessage" }]
    },
    {
        episode: 4,
        level: 7,
        handValues: ["3"],
        tutorial: [{
            force: "MagnetTutorialMessage"
        }]
    },
    {
        episode: 5,
        level: 11,
        handValues: ["a"],
        tutorial: [{
            force: "DoubleTutorialMessage"
        }]
    },
    {
        episode: 7,
        level: 0,
        handValues: ["8"],
        tutorial: [{
            force: "MouseAndCheeseTutorialMessage",
            filter: [MouseCard, CheeseCard]
        }]
    },
    {
        episode: 8,
        level: 6,
        handValues: ["9h"],
        tutorial: [{
            force: "SuitCard1TutorialMessage",
            filter: [SuitCard]
        }, {
            force: "SuitCard2TutorialMessage",
            filter: ["2"]
        }]
    },
    {
        episode: 13,
        level: 0,
        handValues: ["2"],
        importantCards: [FlagsOnCard],
        tutorial: [{
            force: "Flag1TutorialMessage",
            filter: ["a", FlagsOnCard]
        }, {
            force: "Flag2TutorialMessage",
            filter: ["k", FlagsOnCard]
        }]
    },
    {
        episode: 71,
        level: 0,
        handValues: ["4"],
        tutorial: [{
            force: "CurtainTutorialMessage1",
            filter: [CurtainCard]
        }, {
            force: "CurtainTutorialMessage2",
            filter: [CurtainCard]
        }]
    },
    {
        episode: 74,
        level: 0,
        handValues: ["8h"],
        importantCards: [SafeCard],
        tutorial: [{
            force: "SafeTutorialMessage1",
            filter: [SafeCard]
        }, {
            force: "SafeTutorialMessage2",
            filter: [SafeCard]
        }, {
            force: "SafeTutorialMessage3",
            filter: [SafeCard]
        }]
    }
];
