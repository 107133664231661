/**
 * Created by slava on 7/3/20
 */

var ExtraHandView = cc.Node.extend({
    ctor: function (extraHand) {
        this._super();

        this.setAnchorPoint(0.5, 0.5);

        this.extraHand = extraHand;

        this.update(true);
        extraHand.on("update", this.update.bind(this));
        extraHand.on("showForce", this.showForce.bind(this));
    },

    update: function (silent) {
        if (this.state === this.extraHand.visible) {
            return;
        }

        this.state = this.extraHand.visible;
        this.offer = this.extraHand.offer;

        if (this.state) {
            this.showCardsAnimation();
            this.amount.setString("+" + this.offer.moves);
            this.updatePrice();
        }

        if (this.endGameButton) {
            this.endGameButton.setVisible(!this.offer.free);
        }

        if (silent) {
            this.visible = this.state;
            this.scale = 1;
            return;
        }

        this.stopAllActions();
        if (this.state) {
            this.visible = true;
            this.setScale(0);
            this.runAction(new cc.ScaleTo(0.25, 1));
        } else {
            this.runAction(new cc.Sequence(
                new cc.ScaleTo(0.1, 0),
                new cc.CallFunc(function () {
                    this.visible = false;
                }.bind(this))
            ));
        }
    },

    showForce: function () {
        cleverapps.forces.create(this, this.offer.force);
    },

    showCardsAnimation: function () {
        var styles = cleverapps.styles.ExtraHandView;

        if (this.extraCardsAnimation) {
            return;
        }

        this.extraCardsAnimation = new cleverapps.Spine(bundles.card.jsons.extra_json);
        this.extraCardsAnimation.setAnimation(0, "idle", true);
        this.extraCardsAnimation.setPositionRound(styles.extraCards);
        this.addChild(this.extraCardsAnimation);

        this.endGameButton = new cleverapps.UI.Button({
            text: "EndGame",
            height: styles.button.height,
            width: styles.button.width,
            onClicked: function () {
                Game.currentGame.trigger("outOfMoves", this.offer);
            }.bind(this)
        });
        this.endGameButton.setPositionRound(styles.button);

        var amount = this.amount = cleverapps.UI.generateImageText("", cleverapps.styles.FONTS.EXTRA_HAND_TEXT);
        amount.setPositionRound(styles.amount);
        amount.setLocalZOrder(2);
        this.extraCardsAnimation.addChild(amount);

        this.setPositionRound(styles);
        this.addChild(this.endGameButton);

        cleverapps.UI.onClick(this.extraCardsAnimation, this.extraHand.onCardsClicked.bind(this.extraHand));
        cleverapps.UI.applyHover(this.extraCardsAnimation);
        cleverapps.UI.wrap(this);
    },

    updatePrice: function () {
        var styles = cleverapps.styles.ExtraHandView.price;

        if (this.offerPrice) {
            this.offerPrice.removeFromParent();
            delete this.offerPrice;
        }

        var textMessage = (this.offer.type === "ads") ? "## Watch" : this.offer.text;
        var text = new TextWithIcon(textMessage, {
            font: cleverapps.styles.FONTS.TEXT
        });
        text.fitTo(styles.width);

        this.offerPrice = new cc.Scale9Sprite(bundles.timer.frames.timer_bg_png);
        this.offerPrice.setContentSize2(text.width + 2 * styles.padding.x, text.height + 2 * styles.padding.y);
        this.offerPrice.addChild(text);
        text.setPositionRound(this.offerPrice.width / 2, this.offerPrice.height / 2);

        this.offerPrice.setPositionRound(styles);
        this.extraCardsAnimation.addChild(this.offerPrice);
    }
});

cleverapps.styles.ExtraHandView = {
    x: -50,
    y: 12,

    extraCards: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 114 }
    },

    amount: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 45 }
    },

    price: {
        x: { align: "center" },
        y: { align: "bottom", dy: 0 },
        width: 230,

        padding: {
            x: 5,
            y: 1
        }
    },

    button: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 25 },
        height: 79,
        width: 170
    }
};