/**
 * Created by Andrey Popov on 01.09.20
 */

var CheeseCard = function (options) {
    options = options || {};
    Card.call(this, options);

    this.feature = "cheese";
};

CheeseCard.prototype = Object.create(Card.prototype);
CheeseCard.constructor = CheeseCard;

CheeseCard.prototype.getViewClass = function () {
    return CheeseCardView;
};

CheeseCard.prototype.getSkin = function () {
    return this.isOpen() || cleverapps.environment.isSceneWithPreview() ? "cheese" : "backside";
};

CheeseCard.prototype.canPlay = function () {
    return this.isOpen() && !this.isBlocked() && MouseCard.FindTop() !== undefined;
};

CheeseCard.prototype.canBeAttacked = function () {
    return false;
};

CheeseCard.prototype.canBeReplacedWithBoosters = function () {
    return false;
};

CheeseCard.prototype.handleClick = function () {
    var mouseCard = MouseCard.FindTop();
    if (!this.isOpen() || mouseCard === undefined) {
        this.trigger("wrongMove");
        return;
    }

    mouseCard.taken = true;

    this.trigger("feed", mouseCard);

    setTimeout(function () {
        mouseCard.trigger("afterFeed");
        Game.currentGame.table.removeCard(mouseCard);
        Game.currentGame.table.removeCard(this);
        Game.currentGame.counter.trigger();
    }.bind(this), 1000 * CheeseCard.PLAY_TIMEOUT);

    Game.currentGame.log.clear();
    return true;
};

CheeseCard.PLAY_TIMEOUT = 0.5;