/**
 * Created by Ivan on 27.06.2024
 */

var BallView = cc.Node.extend({
    ctor: function (ball) {
        this._super();

        this.ball = ball;
        this.setAnchorPoint(0.5, 0.5);

        var view = new cleverapps.Spine(bundles.pinball.jsons.ball_json);
        view.setAnimation(0, "animation");
        this.setContentSize(view.getContentSize());
        this.addChild(view);
        view.setPositionRound(this.width / 2, this.height / 2);
        this.setVisible(false);

        ball.updateRadius(Math.max(view.width, view.height) / 2);
        ball.on("updatePosition", this.createListener(this.updatePosition.bind(this)), this);
        ball.on("show", this.createListener(this.show.bind(this)), this);
        ball.on("hide", this.createListener(this.hide.bind(this)), this);
    },

    updatePosition: function () {
        this.setPositionRound(PinballGame.getViewPosition(this.ball.position));
    },

    show: function () {
        this.setVisible(true);
    },

    hide: function () {
        this.setVisible(false);
    }
});
