/**
 * Created by olga on 10.10.2023
 */

IceOnCardView.prototype.ctor = cleverapps.extendFunc(IceOnCardView.prototype.ctor, function () {
    this._super.apply(this, arguments);
    this.animation.visible = cleverapps.environment.isSceneWithPreview();
});

IceOnCardView.prototype.show = function () {
    this.animation.setOpacity(0);
    this.animation.visible = true;
    this.animation.runAction(new cc.FadeIn(0.4));
};

IceOnCardView.prototype.hide = function () {
    this.animation.visible = false;
};
