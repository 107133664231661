/**
 * Created by Andrey Popov on 1/24/23.
 */

var ChainOnCard = function (card) {
    this.card = card;

    this.onShowUp = function () {};
    this.onHide = function () {};
    this.onDestroy = function () {};
};

ChainOnCard.prototype.getViewClass = function () {
    return ChainOnCardView;
};

ChainOnCard.prototype.handleFlip = function () {
    return true;
};

ChainOnCard.prototype.toJSON = function () {
    return {
        name: "chain"
    };
};

ChainOnCard.prototype.destroy = function () {
    this.onDestroy();

    Game.currentGame.counter.setTimeout(function () {
        this.card.removeComponent(ChainOnCard);
    }.bind(this), 1700);
};

ChainOnCard.prototype.isBlocked = function () {
    return true;
};

ChainOnCard.prototype.handleClick = function () {
    return true;
};

ChainOnCard.Unlock = function () {
    var chainCardIndex = -1;
    var cards = Game.currentGame.table.cards;
    for (var i = 0; i < cards.length; i++) {
        var chain = cards[i].findComponent(ChainOnCard);
        if (chain && !chain.taken) {
            chainCardIndex = i;
        }
    }

    if (chainCardIndex === -1) {
        if (cleverapps.config.debugMode) {
            throw "level error (key without any chain)";
        }
        return undefined;
    }

    var chainToReturn = cards[chainCardIndex].findComponent(ChainOnCard);
    chainToReturn.taken = true;

    setTimeout(function () {
        chainToReturn.destroy();
        Game.currentGame.counter.trigger();
    }, 600);

    return chainToReturn;
};

ChainOnCard.prototype.canBeAttacked = function () {
    return false;
};