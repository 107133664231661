/**
 * Created by Ivan on 01.08.2024
 */

var Barrier = function (params) {
    params = params || {};
    Body.call(this, params.position, params.radius);

    this.type = params.type;
    this.decelerationFactor = params.decelerationFactor || Barrier.DECELERATION_FACTOR;
    this.hidden = params.hidden;
    this.collisionSound = params.collisionSound || bundles.pinball.urls.walls_sound;
};

Barrier.prototype = Object.create(Body.prototype);
Barrier.prototype.constructor = Barrier;

Barrier.prototype.playSound = function () {
    this.collisionSound && cleverapps.audio.playSound(this.collisionSound);
};

Barrier.prototype.handleCollision = function (ball) {
    var ballPos = ball.position;
    var ballRadius = ball.radius;
    var barrierRadius = this.radius;

    var distance = cc.pDistance(ballPos, this.position);
    if (distance <= ballRadius + barrierRadius) {
        this.playSound();
        var normalVector = cc.pNormalize(cc.pSub(ballPos, this.position));
        var dotProduct = ball.direction.x * normalVector.x + ball.direction.y * normalVector.y;
        var reflection = cc.pSub(ball.direction, cc.pMult(normalVector, 2 * dotProduct));

        var angleOffset = (Math.random() - 0.5) * (Math.PI / 12);
        var newAngle = Math.atan2(reflection.y, reflection.x) + angleOffset;
        var length = cc.pLength(ball.direction);

        ball.direction = cc.p(Math.cos(newAngle) * length, Math.sin(newAngle) * length);

        var overlap = ballRadius + barrierRadius - distance;
        var positionOffset = cc.pMult(normalVector, overlap);

        ball.position = cc.pAdd(ball.position, positionOffset);
        return true;
    }

    return false;
};

Barrier.prototype.onStop = function () {};

Barrier.DECELERATION_FACTOR = 0.95;