/**
 * Created by andrey on 22.08.2020.
 */

var DoubleCard = function (options) {
    Card.call(this, options);

    this.feature = "double";
};

DoubleCard.prototype = Object.create(Card.prototype);
DoubleCard.constructor = DoubleCard;

DoubleCard.prototype.setupValue = function (value) {
    if (!cleverapps.environment.isEditorScene()) {
        this.setRandomValue();
    }

    if (value !== undefined) {
        this.setRank(value.charAt(0));
    } else {
        this.setRank(Math.floor(this.getCardValue() / Card.VALUES.length * 3) + 9);
    }

    value = this.getCardValue();
    this.suits = DoubleCard.SUITS[this.getRank()];
    this.setSuit(this.suits[0]);
    this.values = [this.value, Card.VALUES[value + 1] + this.suits[1]];
};

DoubleCard.prototype.getViewClass = function () {
    return DoubleCardView;
};

DoubleCard.prototype.getScale = function () {
    if (this.owner instanceof OpenCards) {
        return 1;
    }

    return DoubleCard.SCALE;
};

DoubleCard.prototype.getSkin = function () {
    if (!this.isOpen()) {
        return "backside";
    }

    var value = this.getCardValue();
    return "double_" + Card.VALUES[value + 1] + this.getRank();
};

DoubleCard.prototype.canBeReplacedWithBoosters = function () {
    return false;
};

DoubleCard.SUITS = {
    "j": ["h", "s"],
    "q": ["d", "s"],
    "k": ["h", "c"]
};

DoubleCard.SCALE = 1.286;