/**
 * Created by slava on 4/3/20
 */

CardView.prototype.ctor = cleverapps.extendFunc(CardView.prototype.ctor, function (card, options) {
    this._super(card, options);

    card.on("handShowUp", this.handShowUp.bind(this), this);
    card.on("animateLeft", this.animateLeft.bind(this), this);
});

CardView.prototype.shiftToOpen = function (openView, options) {
    this.stopAllActions();

    if (options.silent) {
        this.setIdleAnimation();
        this.setPositionRound(this.parent.convertToNodeSpace(openView.parent.convertToWorldSpace(openView.getPosition())));
        options.callback && options.callback();
        return;
    }

    this.runAction(new cc.Sequence(
        cleverapps.UI.animateCard({
            cardView: this,
            position: function () {
                return { parent: openView.parent, point: openView.getPosition() };
            },
            duration: OpenCards.SHIFT_TIMEOUT,
            animation: "new"
        }),
        new cc.CallFunc(options.callback)
    ));

    cleverapps.audio.playSound(bundles.game.urls.card_effect);
};

CardView.prototype.animateLeft = function (duration, oneCardCallback) {
    this.setRotation(0);
    this.stopAllActions();

    var chest = cleverapps.scenes.getRunningScene().bonusChest;

    this.runAction(new cc.Sequence(
        new cc.PlaySound(bundles.game.urls.collected_effect, {
            throttle: 150
        }),
        new cc.Spawn(
            cleverapps.UI.animateCard({
                cardView: this,
                position: function () {
                    return { parent: chest.animation, point: chest.getFlyTargetPosition() };
                },
                duration: duration,
                animation: cleverapps.UI.animateCard.ANIMATIION_BIT
            }),
            new cc.ScaleTo(duration, 0.6)
        ),
        new cc.CallFunc(function () {
            oneCardCallback();
            chest.addGold();
        }),
        new cc.RemoveSelf()
    ));
};

CardView.prototype.openReturnToHand = function (handView, options) {
    this.setRotation(0);

    this.runAction(new cc.Sequence(
        cleverapps.UI.animateCard({
            cardView: this,
            position: function () {
                return { parent: handView, point: options.finishPosition };
            },
            duration: Card.HAND_RETURN_TIMEOUT
        }),
        new cc.CallFunc(function () {
            options.callback && options.callback();
        })
    ));
};

CardView.prototype.moveToHandFromTable = function (handView, options) {
    var toIntermediatePosition = function () {
        this.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.RotateTo(0.54, 0).easing(cc.easeIn(2)),
                new cc.ScaleTo(0.1, 1),
                cleverapps.UI.animateCard({
                    cardView: this,
                    position: function () {
                        return { parent: handView, point: options.intermediatePosition };
                    },
                    duration: 0.54,
                    midpoint: {
                        x: Math.abs(this.x - options.intermediatePosition.x) * 0.1,
                        y: (options.intermediatePosition.y - this.y) - Math.abs(this.x - options.intermediatePosition.x) * 0.2
                    },
                    noAnimateScale: true,
                    easing: cc.easeInOut(3),
                    replaceParentBack: false
                })
            ),

            new cc.CallFunc(function () {
                toFinishPosition();
            })
        ));
    }.bind(this);

    var toFinishPosition = function () {
        var rotateAngle = Math.abs(options.finishPosition.x - options.intermediatePosition.x) / 10;
        this.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.Sequence(
                    new cc.RotateTo(0.1, rotateAngle).easing(cc.easeIn(1)),
                    new cc.RotateTo(0.1, 0).easing(cc.easeIn(1))
                ),
                cleverapps.UI.animateCard({
                    cardView: this,
                    position: function () {
                        return { parent: handView, point: options.finishPosition };
                    },
                    duration: 0.2,
                    midpoint: {
                        x: Math.abs(options.finishPosition.x - options.intermediatePosition.x) * 0.1,
                        y: (options.finishPosition.y - options.intermediatePosition.y)
                    },
                    replaceParentBack: false
                })
            ),
            new cc.CallFunc(function () {
                options.callback && options.callback();
            })
        ));
    }.bind(this);

    toIntermediatePosition();
};

CardView.prototype.handShowUp = function (f, delay, silent) {
    this.stopAllActions();

    var position = this.card.getViewPosition();

    if (silent) {
        this.setVisible(true);
        this.setPositionRound(position.point);
        this.setRotation(0);
        f();
        return;
    }

    this.setPositionRound(this.x + cleverapps.styles.CardView.handShowUp.x, this.y + cleverapps.styles.CardView.handShowUp.y);
    this.setRotation(cleverapps.styles.CardView.handShowUp.rotation);

    this.runAction(new cc.Sequence(
        new cc.DelayTime(delay),
        new cc.Show(),
        new cc.Spawn(
            new cc.MoveTo(Card.SHOW_UP_TIMEOUT, position.point),
            new cc.RotateTo(Card.SHOW_UP_TIMEOUT, 0)
        ),
        new cc.CallFunc(f)
    ));
};

CardView.prototype.moveToOpen = function (duration, callback) {
    this.setRotation(0);
    var animationTime = this.card.getMoveToOpenAnimationTime();
    this.runAction(new cc.Sequence(
        cleverapps.UI.animateCard({
            cardView: this,
            position: this.card.getViewPosition.bind(this.card),
            duration: duration,
            animation: cleverapps.UI.animateCard.ANIMATIION_BIT,
            animationTime: animationTime
        }),
        new cc.CallFunc(function () {
            callback();
            cleverapps.audio.playSound(bundles.game.urls.collected_hand);
        })
    ));
};

CardView.SCALE_ACTION_TAG = 1;

Object.assign(cleverapps.styles.CardView, {
    handShowUp: {
        x: -30,
        y: -200,
        rotation: -30
    }
});

cleverapps.styles.CardView.width = Card.WIDTH;
cleverapps.styles.CardView.height = Card.HEIGHT;
