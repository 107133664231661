/**
 * Created by spepa on 29/06/20
 */

var WildCardView = CardView.extend({
    ctor: function (card) {
        this._super(card, {
            animation_json: bundles.card.jsons.joker_json
        });
        card.on("makeUndo", this.makeUndo.bind(this));
    },

    onFlip: function () {
    },

    makeUndo: function () {
        var scene = cleverapps.scenes.getRunningScene();
        var wildCardButton = scene.wildCardButton;
        this.replaceParentSamePlace(scene);

        this.runAction(new cc.Sequence(
            cleverapps.UI.animateCard({
                cardView: this,
                position: { parent: wildCardButton.parent, point: wildCardButton.getPosition() },
                duration: 0.3,
                easing: cc.easeInOut(4),
                zOrder: 1,
                animation: "undo"
            }),
            new cc.RemoveSelf()
        ));
    }
});