/**
 * Created by mac on 3/4/20
 */

var HandBlockView = cc.Node.extend({
    ctor: function (handView, undoBlock, openView) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.handView = handView;
        this.openView = openView;

        this.setContentSize2(cleverapps.styles.HandBlockView.size);
        this.setPositionRound(cleverapps.styles.HandBlockView.position);

        this.addChild(handView);

        if (undoBlock) {
            this.addChild(undoBlock);

            this.undoBlock = new HidingNode(undoBlock, cleverapps.UI.VERTICAL);
            cleverapps.meta.registerControl("undoBlock", this.undoBlock);
        }

        if (openView) {
            this.addChild(openView);
        }

        this.setupChildren();
    },

    setupChildren: function () {
        var styles = cleverapps.styles.HandBlockView;

        var addBgHeight = 0;

        if (styles.appleDownControl && this.height === styles.size.height && connector.info.isNative) {
            addBgHeight = styles.appleDownControl;
            this.height += 2 * addBgHeight;
        }

        this.handView.setPositionRound(this.width / 2 + styles.handView[cleverapps.resolution.mode].dx, this.height / 2);

        if (this.undoBlock) {
            this.undoBlock.setPositionRound(
                this.width / 2 + styles.undoBlock[cleverapps.resolution.mode].dx,
                this.height / 2 + styles.undoBlock[cleverapps.resolution.mode].dy
            );
        }

        if (this.openView) {
            this.openView.setPositionRound(this.width / 2 + styles.openCard[cleverapps.resolution.mode].dx, this.height / 2);
        }
    }
});

cleverapps.styles.HandBlockView = {
    appleDownControl: 24,

    size: {
        width: 780,
        height: 200
    },

    undoBlock: [{
        dx: 325,
        dy: -98
    }, {
        dx: 310,
        dy: -98
    }, {
        dx: 310,
        dy: -98
    }],

    handView: [{
        dx: 90
    }, {
        dx: 50
    }, {
        dx: 50
    }],

    openCard: [{
        dx: 220
    }, {
        dx: 180
    }, {
        dx: 180
    }],

    position: [
        {
            x: { align: "center", dx: -190 },
            y: { align: "bottom", dy: 0 }
        },
        {
            x: { align: "center", dx: -150 },
            y: { align: "bottom", dy: 0 }
        },
        {
            x: { align: "center", dx: -150 },
            y: { align: "bottom", dy: 0 }
        }
    ]
};
