/**
 * Created by olga on 24.01.2024
 */

var EditorTableGridView = cc.Node.extend({
    ctor: function (grid) {
        this._super();

        this.grid = grid;

        this.setContentSize2(grid.width * grid.step, grid.height * grid.step);
        this.drawLines();

        this.cubes = [];
        for (var i = 0; i < grid.height; i++) {
            this.cubes.push([]);
            for (var j = 0; j < grid.width; j++) {
                var x = j * this.grid.step;
                var y = i * this.grid.step;
                var cube = this.createDebugLine(x, y, this.grid.step, false, this.grid.step);
                cube.setOpacity(150);
                cube.setAnchorPoint(0, 0);
                this.addChild(cube);
                this.cubes[i].push(cube);
                this.changeColor(j, i, EditorTableGrid.COLOR_NONE);
            }
        }

        grid.getView = function () {
            return this;
        }.bind(this);
        this.grid.onChangeColor = this.createListener(this.changeColor.bind(this));
    },

    changeColor: function (x, y, color) {
        if (color === EditorTableGrid.COLOR_NONE) {
            this.cubes[y][x].setVisible(false);
        } else {
            this.cubes[y][x].setVisible(true);
        }
    },

    drawLines: function () {
        if (this.lines) {
            this.lines.forEach(function (line) {
                line.removeFromParent();
            });
        }

        this.lines = [];

        var x, y, thickness;

        for (var h = 0; h <= this.grid.height; h++) {
            x = 0;
            y = h * this.grid.step;
            thickness = 1;
            if (cleverapps.config.type === "solitaire" && !(y % Card.WIDTH)) {
                thickness = 2;
            }
            var horizontalLine = this.createDebugLine(x, y, this.width, false, thickness);
            horizontalLine.setAnchorPoint(0, 0);
            this.lines.push(horizontalLine);
            this.addChild(horizontalLine);
        }

        for (var w = 0; w <= this.grid.width; w++) {
            x = w * this.grid.step;
            y = 0;
            thickness = 1;
            if (cleverapps.config.type === "solitaire" && !(x % Card.WIDTH)) {
                thickness = 2;
            }
            var verticalLine = this.createDebugLine(x, y, this.height, true, thickness);
            verticalLine.setAnchorPoint(0, 0);
            this.lines.push(verticalLine);
            this.addChild(verticalLine);
        }
    },

    createDebugLine: function (x, y, length, vertical, thickness, color) {
        color = color || cc.color.GREEN;

        var sprite = new cc.Scale9Sprite(bundles.pixel.frames.pixel_png);
        sprite.setContentSize(vertical ? thickness : length, vertical ? length : thickness);
        sprite.setLocalZOrder(-10);
        sprite.setPositionRound(x, y);
        sprite.setColor(color);

        return sprite;
    }

});
