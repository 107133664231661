/**
 * Created by Ivan on 23.08.2024
 */

var PinballGameView = cc.Node.extend({
    ctor: function (pinball, fieldSize) {
        this._super();
        this.pinball = pinball;

        this.fieldSize = fieldSize;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(this.fieldSize);

        this.createBall();
        this.createBarriers();
        this.createPrizes();

        this.pinball.setUpdateCallback(this.updateScheduler.bind(this));
        this.pinball.on("prize_highlight", this.runPrizeHighlight.bind(this));
        this.pinball.on("stop", this.clearPrizeHighlight.bind(this));
    },

    createBall: function () {
        this.ballView = new BallView(this.pinball.ball);
        this.addChild(this.ballView, 1);
    },

    createBarriers: function () {
        var barriers = this.pinball.virtualBarriers.concat(this.pinball.barriers);
        barriers.forEach(function (barrier) {
            var pos = PinballGame.getViewPosition(barrier.position);
            var type = barrier.type;

            if (type === "extra") {
                this.addChild(new ExtraBarrierView(barrier, pos));
            } else if (!barrier.hidden) {
                var view;
                if (type) {
                    view = new cc.Sprite(PinballScene.BARRIER_IMAGE[type]);
                } else {
                    view = new cleverapps.Spine(PinballScene.BARRIER_IMAGE.default);
                    view.setAnimation(0, "animation");
                }
                this.addChild(view);
                barrier.updateRadius && barrier.updateRadius(Math.max(view.width, view.height) / 2);
                view.setPositionRound(pos);
            }
        }.bind(this));
    },

    createPrizes: function () {
        this.prizes = [];
        this.pinball.prizes.forEach(function (prize) {
            var prizeView = new PrizeView(prize);
            this.addChild(prizeView);
            this.prizes.push(prizeView);
        }.bind(this));
    },

    clearPrizeHighlight: function () {
        this.stopAllActions();
    },

    runPrizeHighlight: function () {
        this.prizeHighlightAction && this.prizeHighlightAction.stop();

        var actions = [];

        this.prizes.forEach(function (prize) {
            !prize.isMaxPrize() && actions.push(prize.runHighlightAnimation());
        });

        actions = new cc.Sequence(
            new cc.Spawn(actions),
            new cc.DelayTime(1.5)
        );

        this.prizeHighlightAction = this.runAction(new cc.RepeatForever(actions));
    },

    updateScheduler: function (shouldSchedule) {
        if (shouldSchedule) {
            this.scheduleUpdate();
        } else {
            this.unscheduleUpdate();
        }
    },

    update: function (dt) {
        this.pinball.update(dt);
    },

    win: function () {
        this.pinball.stop();
    },

    giveReward: function () {

    },

    getBackgroundStyles: function () {
        return {
            bundle: "pinball_bg",
            backgroundId: "background"
        };
    },

    getAudioStyles: function () {
        return {
            res: bundles.pinball.urls.music,
            delay: 1.0
        };
    },

    listBundles: function () {
        return ["pinball", "pinball_bg", "game"];
    }
});