/**
 * Created by Andrey Popov on 12.05.20
 */

var FillHandCard = function (options) {
    Card.call(this, options);

    this.amount = options.amount;

    this.feature = "fillhand";
};

FillHandCard.prototype = Object.create(Card.prototype);
FillHandCard.constructor = FillHandCard;

FillHandCard.prototype.getViewClass = function () {
    return FillHandCardView;
};

FillHandCard.prototype.toJSON = function () {
    return Object.assign(Card.prototype.toJSON.call(this), {
        amount: this.amount
    });
};

FillHandCard.prototype.getSkin = function () {
    return "fillhand" + this.amount;
};

FillHandCard.prototype.canPlay = function () {
    return false;
};

FillHandCard.prototype.canBeAttacked = function () {
    return false;
};

FillHandCard.prototype.canBeReplacedWithBoosters = function () {
    return false;
};

FillHandCard.prototype.autoPlay = function () {
    var counter = Game.currentGame.counter;

    var player = new ActionPlayer();

    player.add(function (f) {
        this.registerAnimationTime(FillHandCardView.GET_UP_TIME);
        this.trigger("start");
        counter.setTimeout(f, FillHandCardView.GET_UP_TIME * 1000);
    }.bind(this));

    var cards = [];
    for (var index = 0; index < this.amount; index++) {
        cards.push(TileFactory.Create({}, {
            noMarks: true
        }));
    }

    player.add(cards.map(function (card) {
        return function (f) {
            this.amount--;
            this.trigger("spawn", card);

            Game.currentGame.hand.push(card, {
                insertIndex: 0,
                fromTable: true
            });

            counter.setTimeout(f, 300);
        }.bind(this);
    }, this));

    player.add(function () {
        this.trigger("finish");
        Game.currentGame.table.removeCard(this);
    }.bind(this));

    player.play();
};