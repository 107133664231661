/**
 * Created by Andrey Popov on 10.11.20
 */

var LayerManager = function () {
    this.reset();

    this.onRebuild = function () {};

    this.rebuildLayers();
};

LayerManager.prototype.getLayersCount = function () {
    return this.layers.length;
};

LayerManager.prototype.rebuildLayers = function () {
    var cards = Game.currentGame.table.cards.slice();

    this.reset();

    while (cards.length > 0) {
        var bottomCards = [];

        for (var i = 0; i < cards.length; i++) {
            var isBottomCard = true;
            for (var j = 0; j < cards.length; j++) {
                if (i === j) {
                    continue;
                }

                if (cards[i].overlaps(cards[j]) && i > j) {
                    isBottomCard = false;
                    break;
                }
            }

            if (isBottomCard) {
                bottomCards.push(cards[i]);
            }
        }

        this.layers.push(bottomCards);
        bottomCards.forEach(function (bottomCard) {
            var index = cards.indexOf(bottomCard);
            cards.splice(index, 1);
        });
    }

    this.onRebuild(this.layers.length);
};

LayerManager.prototype.isCardActive = function (card) {
    if (this.layerIndex === this.layers.length - 1) {
        return true;
    }

    for (var i = 0; i <= this.layerIndex; i++) {
        if (this.layers[i] && this.layers[i].indexOf(card) !== -1) {
            return true;
        }
    }

    return false;
};

LayerManager.prototype.selectLayer = function (layerIndex) {
    this.layerIndex = layerIndex;
};

LayerManager.prototype.reset = function () {
    this.layerIndex = 0;
    this.layers = [];
};