/**
 * Created by mac on 4/22/20
 */

var TileGameEditor = function () {
    EditorBase.call(this);

    this.addExtraControls();

    this.prepare();

    Game.currentGame.pagination.on("changePage", this.changePage.bind(this));
    Game.currentGame.pagination.on("removePage", this.removePage.bind(this));
    Game.currentGame.pagination.on("addPage", this.addPage.bind(this));

    this.layerManager = new LayerManager();

    if (cleverapps.config.type === "tile3") {
        this.tileColorsEditor = new TileColorsEditor();

        this.cardsCounter = new CardsCounter();
    }

    this.magic = new MagicStacks(Game.currentGame.table);

    this.orangery = new Orangery(cleverapps.config.type === "tile3" ? Tile3Orangery : SolitaireOrangery);

    this.onChangeLayer = function () {};
};

TileGameEditor.prototype = Object.create(EditorBase.prototype);
TileGameEditor.prototype.constructor = TileGameEditor;

Editor = TileGameEditor;

TileGameEditor.BUNDLES = ["admin"];

TileGameEditor.prototype.addExtraControls = function () {
    var extra = [{
        icon: bundles.editor.frames.editor_flip_x_icon,
        hint: "flip x",
        action: function () {
            Game.currentGame.table.flip({
                x: true
            });
        }
    },
    {
        icon: bundles.editor.frames.editor_flip_y_icon,
        hint: "flip y",
        action: function () {
            Game.currentGame.table.flip({
                y: true
            });
        }
    },
    {
        icon: bundles.admin.frames.change_orientation,
        hint: "change orientation",
        action: function () {
            var orientation = Game.currentGame.table.getOrientation();
            var newOrientation = orientation ^ 1;

            Game.currentGame.table.setOrientation(newOrientation);
        }
    },
    {
        icon: bundles.editor.frames.snap_mode_icon,
        hint: "toggle snap mode",
        action: function () {
            Game.currentGame.table.toggleSnapMode();
        },
        selectOnClick: true
    }
    ];
    if (cleverapps.config.type === "solitaire") {
        extra.push(
            {
                icon: bundles.editor.frames.blocks_mode_icon,
                hint: "toggle blocks mode",
                action: function () {
                    Game.currentGame.table.toggleSelectBlocksMode();
                },
                selectOnClick: true
            }
        );
        extra.push(
            {
                icon: bundles.editor.frames.rotate_level_png,
                hint: "rotate level",
                action: function () {
                    var cards = Game.currentGame.table.cards;
                    var orientation = Game.currentGame.table.getOrientation();
                    Editor.currentEditor.history.add({
                        redo: function () {
                            Game.currentGame.table.clearSelection();
                            cleverapps.cardBlockHandler.adaptCardBlocks(orientation);
                            Game.currentGame.table.trigger("resetCards");
                            Game.currentGame.table.updateBlocks();
                            Editor.currentEditor.save();
                        },

                        undo: Editor.currentEditor.restoreCards(cards)
                    });
                }
            }
        );
    }

    this.controls = this.controls.concat(extra);
};

TileGameEditor.prototype.getSaveData = function () {
    var data = EditorBase.prototype.getSaveData.call(this);
    var hand = Game.currentGame.hand;

    if (hand) {
        var stack = hand.stack;

        data.hand = {
            num: stack.length,
            difficulty: hand.difficulty
        };

        var handCards = [];
        for (var i = stack.length - 1; i >= 0; i--) {
            if (stack[i].value !== undefined && !stack[i].random) {
                handCards.push("" + stack[i].value);
            } else {
                break;
            }
        }
        if (handCards.length > 0) {
            data.hand.cards = handCards;
        } else if (hand.customCards.length > 0) {
            data.hand.cards = hand.customCards;
        }
    }

    var mapCard = function (original) {
        var card = original.toJSON();

        if (original.marks) {
            card.marks = original.marks.map(function (mark) {
                return mark.type ? mark.type : mark;
            });

            if (card.marks.length === 0) {
                card.marks = undefined;
            }
        }

        return card;
    };

    data.screens = [];
    for (var page = 0; page < Game.currentGame.level.content.screens.length; page++) {
        var screen = Game.currentGame.level.content.screens[page];
        if (page === Game.currentGame.pagination.getCurrent()) {
            var cards = Game.currentGame.table.cards.filter(function (card) {
                return !card.magic;
            }).map(mapCard);

            if (cleverapps.config.type === "solitaire") {
                if (Game.currentGame.table.getOrientation() === TileTable.ORIENTATION_HORIZONTAL) {
                    screen.horizontalCards = cards;
                    if (screen.cards && screen.cards.length !== cards.length) {
                        screen.cards = undefined;
                    }
                } else {
                    screen.cards = cards;
                    if (screen.horizontalCards && screen.horizontalCards.length !== cards.length) {
                        screen.horizontalCards = undefined;
                    }
                }
            } else {
                screen.cards = cards;
            }

            if (Game.currentGame.table.getOrientation() === TileTable.ORIENTATION_HORIZONTAL) {
                screen.magicH = Game.currentGame.table.cards.filter(function (card) {
                    return card.magic;
                }).map(mapCard);
            } else {
                screen.magicV = Game.currentGame.table.cards.filter(function (card) {
                    return card.magic;
                }).map(mapCard);
            }
        }

        if (screen.cards && screen.cards.length > 0 || screen.horizontalCards && screen.horizontalCards.length > 0) {
            data.screens.push(screen);
        }
    }

    if (data.screens.length === 0) {
        data.screens = [{
            cards: [{ "x": 0, "y": 0 }],
            magicV: [],
            magicH: [],
            maxValue: 3
        }];
    }

    if (this.cardsCounter) {
        this.cardsCounter.update();
    }

    this.layerManager.rebuildLayers();
    return data;
};

TileGameEditor.prototype._load = function (levelContent) {
    this.hand = levelContent.hand;
    this.screens = levelContent.screens;

    this.magic.refresh();
};

TileGameEditor.prototype.clear = function () {
    Game.currentGame.table.removeAllCards();
};

TileGameEditor.prototype.changePage = function () {
    this.layerManager.rebuildLayers();
    Editor.currentEditor.setLayer(this.layerManager.getLayersCount() - 1);

    if (this.tileColorsEditor) {
        this.tileColorsEditor.refresh();
    }

    if (this.cardsCounter) {
        this.cardsCounter.update();
    }

    this.magic.refresh();
};

TileGameEditor.prototype.addPage = function () {
    Game.currentGame.level.content.screens.push({
        cards: [],
        magicV: [],
        magicH: [],
        maxValue: 3
    });

    this.save();
};

TileGameEditor.prototype.removePage = function (page) {
    Game.currentGame.level.content.screens.splice(page, 1);

    this.save();
};

TileGameEditor.prototype.setLayer = function (layer) {
    this.layerManager.selectLayer(layer);
    Game.currentGame.table.showCardsByLayers();
    Game.currentGame.flip();

    this.onChangeLayer(layer);
};

TileGameEditor.prototype.rebuildLayers = function () {
    return this.layerManager.rebuildLayers();
};

TileGameEditor.prototype.isCardOnActiveLayer = function (card) {
    return this.layerManager.isCardActive(card);
};

TileGameEditor.prototype.restoreCards = function (cards) {
    var history = cards.map(function (card) {
        var prevX = card.x;
        var prevY = card.y;
        var prevR = card.getRotation();
        return function () {
            card.setPosition({
                x: prevX,
                y: prevY,
                r: prevR
            });
            Game.currentGame.table.trigger("editorMoveEnd", [card]);
        };
    });

    return function () {
        history.forEach(function (step) {
            step();
        });

        Game.currentGame.table.flipNormalize();
        this.save();
    }.bind(this);
};

TileGameEditor.prototype.prepare = function () {
    if (bundles.card.jsons.card_json) {
        cleverapps.Spine.removeSlot(bundles.card.jsons.card_json, "shadow_card");
    }
};
