/**
 * Created by Andrey Popov on 18.05.20
 */

var IncDecCardView = CardView.extend({
    ctor: function (card) {
        this._super(card);

        this.setAnchorPoint(0.5, 0.5);

        this.arrows = new cleverapps.Spine(bundles.card.jsons.effects_json);
        this.arrows.setAnimation(0, card.dir === 1 ? "incvalue" : "decvalue", true);
        this.arrows.setPosition(cleverapps.styles.CardView.width / 2, cleverapps.styles.CardView.height / 2);
        this.addChild(this.arrows);

        card.on("hide", this.hide.bind(this));
        card.on("changeRank", this.changeRank.bind(this));

        if (!cleverapps.environment.isSceneWithPreview()) {
            this.updateArrowsVisibility();
            card.on("removeFromTable", this.updateArrowsVisibility.bind(this, true));
        }
    },

    hide: function () {
        if (this.arrows) {
            this.arrows.removeFromParent();
        }
        this.removeFromParent();
    },

    changeRank: function () {
        this.animation.setAnimation(0, "changevalue", false);
        this.animation.setCompleteListener(function () {
            this.animation.setAnimation(0, this.card.isOpen() ? "idle_open" : "idle_close", true);
        }.bind(this));

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.5),
            new cc.CallFunc(function () {
                this.card.trigger("changeValue");
                cleverapps.audio.playSound(bundles.game.urls.rating_effect);
            }.bind(this))
        ));
    },

    animateReturnToScreen: function () {
        this._super();

        this.updateArrowsVisibility();
    },

    updateArrowsVisibility: function (hide) {
        this.arrows.visible = hide ? false : this.card.isOpen() && this.card.owner instanceof TileTable || cleverapps.environment.isSceneWithPreview();
    },

    onFlip: function () {
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.CallFunc(function () {
                this.updateArrowsVisibility(); 
            }.bind(this))
        ));

        CardView.prototype.onFlip.call(this);
    },

    animateLose: function (index) {
        if (this.arrows) {
            this.arrows.removeFromParent();
        }
        return CardView.prototype.animateLose.call(this, index);
    }
});