/**
 * Created by Andrey Popov on 01.09.20
 */

var MouseCardView = CardView.extend({
    ctor: function (card) {
        this._super(card, {
            animation_json: bundles.card.jsons.mouse_json
        });

        this.card.on("afterFeed", this.createListener(this.afterFeed.bind(this)));
    },

    afterFeed: function () {
        this.replaceParentSamePlace(cleverapps.scenes.getMovingNode());
        this.animation.setAnimation(0, "bounce", false);
        this.animation.setCompleteListener(function () {
            this.animation.setCompleteListener();
            this.runAction(new cc.RemoveSelf());
        }.bind(this));
    },

    setIdleAnimation: function () {
        this.animation.setAnimation(1, this.card.isOpen() ? "mouse_idle" : "idle_close", true);
    }
});