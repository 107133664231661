/**
 * Created by Andrey Popov on 12/28/22.
 */

var TileColorsEditor = function () {
    this.onChangeValue = function () {};
};

TileColorsEditor.prototype.setValue = function (value) {
    Game.currentGame.level.content.screens[Game.currentGame.pagination.getCurrent()].maxValue = value;

    Game.currentGame.table.randomizeCards();

    Editor.currentEditor.save();
};

TileColorsEditor.prototype.refresh = function () {
    this.onChangeValue();
};

TileColorsEditor.prototype.getCurrentValue = function () {
    return Game.currentGame.level.content.screens[Game.currentGame.pagination.getCurrent()].maxValue;
};