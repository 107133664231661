/**
 * Created by spepa on 18.02.21
 */

CurtainCardView.prototype.updateCurtainsVisibility = function (hide) {
    var visible = hide ? false : this.card.isOpen() || cleverapps.environment.isSceneWithPreview();
    this.curtains.setTimeScale(1);
    this.curtains.stopAllActions();

    if (visible) {
        this.curtains.setVisible(true);

        if (this.card.sleeping) {
            cleverapps.audio.playSound(bundles.game.urls.curtain_close_effect);
            this.curtains.setAnimationAndIdleAfter(this.getAnimationName(), this.getIdleAnimationName());
        } else {
            this.curtains.setAnimationAndIdleAfter((this.card.magicCurtain ? "magic_" : "") + "emerging", this.getIdleAnimationName());
        }
    } else if (this.curtains.visible && this.card.sleeping) {
        this.curtains.setTimeScale(3.5);
        this.curtains.runAction(new cc.Sequence(
            new cc.DelayTime(0.5),
            new cc.Hide()
        ));
        this.curtains.setAnimation(0, "active", false);
    } else {
        this.curtains.setVisible(false);
    }
};

CurtainCardView.prototype.onFlip = function () {
    this.updateCurtainsVisibility();
    CardView.prototype.onFlip.call(this);
};
