/**
 * Created by mac on 2/25/20
 */

var CardTable = TileTable;

CardTable.prototype.listMissed = function () {
    var openCard = Game.currentGame.open.getCurrentCard();
    if (!openCard) {
        return [];
    }

    return this.cards.filter(function (card) {
        return card.canPlay(openCard);
    });
};

CardTable.prototype.animateMissed = function () {
    this.listMissed().forEach(function (wrongCard) {
        wrongCard.trigger("wrongMove");
    });
};

CardTable.prototype.getRandomOpenedCardsForAttack = function (amount) {
    var cardsToCollect = [];
    for (var i = 0; i < this.cards.length; i++) {
        if (this.cards[i].canBeAttacked() && this.cards[i].isOpen() && !this.cards[i].isNearToDestroy()) {
            cardsToCollect.push(this.cards[i]);
        }
    }

    if (cardsToCollect.length < amount) {
        for (i = this.cards.length - 1; i >= 0; i--) {
            if (this.cards[i].canBeAttacked() && !this.cards[i].isOpen() && !this.cards[i].isNearToDestroy()) {
                cardsToCollect.push(this.cards[i]);
            }
            if (cardsToCollect.length === amount) {
                break;
            }
        }
    }

    var resultCards = cleverapps.Random.shuffle(cardsToCollect).slice(0, amount);
    resultCards.forEach(function (card) {
        card.setNearToDestroy();
    });
    return resultCards;
};

CardTable.prototype.cardsThatCanBeReplacedWithBoosters = function () {
    return this.cards.filter(function (card) {
        var isRightMarks = card.marks.length === 0
            || (card.marks.length === 1 && ["mission", "coin"].indexOf(card.marks[0].type) !== -1);
        var skippedByTutorial = Game.currentGame.cardsTutorial && card.opened;
        return card.canBeReplacedWithBoosters() && isRightMarks && !skippedByTutorial;
    });
};

CardTable.prototype.cardsWithPossibilityOfPlacingUnderThem = function () {
    return cleverapps.Random.shuffle(this.cards.filter(function (card, cardIndex) {
        var invalidCardType = card instanceof Wildcard || card instanceof DoubleCard || card instanceof StreakCard;
        var hasMagnet = card.marks.filter(function (mark) {
            return mark.type === "magnet";
        }).length > 0;
        var hasCardOnTop = this.cards.filter(function (anotherCard, anotherCardIndex) {
            return anotherCardIndex > cardIndex && anotherCard.containsAnyPoint(card, [
                {
                    x: Card.WIDTH / 2,
                    y: Card.HEIGHT / 2 + Card.GRID_XY + 2
                },
                {
                    x: -Card.WIDTH / 2,
                    y: Card.HEIGHT / 2 + Card.GRID_XY + 2
                }]);
        }).length > 0;

        return !invalidCardType && !hasCardOnTop && !hasMagnet;
    }.bind(this)));
};

CardTable.MAX_SCALE = 1.4;