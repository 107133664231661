/**
 * Created by mac on 3/4/20
 */

var HandView = cc.Node.extend({
    ctor: function (hand) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.hand = hand;

        this.cardViews = hand.stack.map(function (card) {
            var ViewClass = card.getViewClass();
            var view = new ViewClass(card);
            this.addChild(view);
            return view;
        }, this);

        this.insertAnimations = 0;
        this.setupChildren();

        hand.on("pop", this.onPop.bind(this));
        hand.on("insert", this.onInsert.bind(this));
        hand.on("adjustPositions", this.adjustPositions.bind(this));

        this.extraHandView = new ExtraHandView(hand.extra);
        this.addChild(this.extraHandView);

        this.hand.getView = function () {
            return this;
        }.bind(this);

        cleverapps.aims.registerTarget("cards", this, {
            controls: "opencards",
            flyingUnderShadow: true,
            noTargetDelta: true,
            flyingAnimation: Reward.SPAWN_CARDS_ANIMATION
        });
    },

    calcPosition: function (index) {
        var margin = cleverapps.styles.HandView.margin;
        var handWidth = (this.hand.stack.length - 1) * margin + cleverapps.styles.CardView.width;
        var maxHandWidth = this.getMaxHandWidth();
        if (handWidth > maxHandWidth) {
            margin = Math.floor((maxHandWidth - cleverapps.styles.CardView.width) / (this.hand.stack.length - 1));
            if (margin < 1) {
                margin = 1;
            }
        }
        return {
            x: -margin * (this.hand.stack.length - 1 - index),
            y: 0
        };
    },

    setupChildren: function () {
        this.adjustPositions(true);
    },

    getMaxHandWidth: function () {
        return Math.min(cleverapps.styles.HandView.maxWidth, cleverapps.resolution.getSceneSize().width / 2 - cleverapps.styles.HandView.maxWidthFromLeft);
    },

    adjustPositions: function (silent) {
        if (this.insertAnimations) {
            this.backFromMovingNode();
        }

        this.hand.stack.forEach(function (card, index) {
            var view = card.onGetView();
            if (!view) {
                return;
            }
            view.setLocalZOrder(index);
            if (view.parent === this && !card.inAnimation()) {
                var position = this.calcPosition(index);
                view.setRotation(0);
                if (silent) {
                    view.setPositionRound(position);
                } else {
                    view.stopAllActions();
                    view.runAction(new cc.MoveTo(0.2, position));
                }
            }
        }, this);

        if (this.insertAnimations) {
            this.upToMovingNode();
        }
    },

    onPop: function () {
        this.cardViews.pop();

        if (!this.hand.isEmpty()) {
            this.adjustPositions();
        }
    },

    upToMovingNode: function () {
        var movingNode = cleverapps.scenes.getMovingNode(this);
        this.cardViews.forEach(function (cardView) {
            if (cardView.parent === this) {
                cardView.realParent = this;
                cardView.replaceParentSamePlace(movingNode, {
                    keepScale: true
                });
            }
        }.bind(this));
    },

    backFromMovingNode: function () {
        this.cardViews.forEach(function (cardView) {
            if (cardView.parent && cardView.parent !== this && !cardView.card.inAnimation()) {
                cardView.replaceParentSamePlace(cardView.realParent, {
                    keepScale: true
                });
            }
        }.bind(this));
    },

    onInsertFromTable: function (cardView, finishPosition, leftward) {
        this.insertAnimations++;

        var intermediateInsertOffsetX = leftward ? cleverapps.styles.HandView.intermediateInsertOffset.x : 0;
        var intermediateInsertOffsetY = cleverapps.styles.HandView.intermediateInsertOffset.y;
        var intermediatePosition = cc.p(finishPosition.x + intermediateInsertOffsetX, finishPosition.y + intermediateInsertOffsetY);
        if (intermediatePosition.x < -this.getMaxHandWidth()) {
            intermediatePosition.x = -this.getMaxHandWidth() + cleverapps.styles.CardView.width / 2;
        }

        this.adjustPositions();

        cardView.moveToHandFromTable(this, {
            finishPosition: finishPosition,
            intermediatePosition: intermediatePosition,
            callback: function () {
                this.insertAnimations--;
                if (this.insertAnimations === 0) {
                    this.backFromMovingNode();
                }
                this.adjustPositions();
            }.bind(this)
        });
    },

    onInsert: function (card, options) {
        var index = options.index;
        var view = card.onGetView();

        if (view) {
            view.setVisible(true);
            var finishPosition = this.calcPosition(index);
            if (options.fromTable) {
                this.onInsertFromTable(view, finishPosition, options.leftward);
            } else {
                view.openReturnToHand(this, {
                    finishPosition: finishPosition,
                    callback: this.adjustPositions.bind(this)
                });
            }
        } else {
            var ViewClass = card.getViewClass();
            view = new ViewClass(card);
            this.addChild(view);
        }
        this.cardViews.splice(index, 0, view);
    }
});

cleverapps.styles.HandView = {
    margin: 18,
    maxWidth: 460,
    maxWidthFromLeft: 124,
    intermediateInsertOffset: {
        x: -200,
        y: 200
    }
};
