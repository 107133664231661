/**
 * Created by r4zi4l on 05.04.2024
 */

RewardTypes.cards = {
    flyingAnimation: Reward.SPAWN_CARDS_ANIMATION,

    getIcon: function () {
        return bundles.reward_icons.frames.cards;
    },

    handler: function (value) {
        if (!this.spawnedCards) {
            this.spawnedCards = [];
        }

        for (var i = 0; i < value; ++i) {
            var card = TileFactory.Create({}, {
                noMarks: true
            });

            this.spawnedCards.push(card);
        }

        return function () {

        };
    }
};
