/**
 * Created by Andrey Popov on 01.09.20
 */

var MouseCard = function (options) {
    options = options || {};
    Card.call(this, options);

    this.feature = "mouse";
};

MouseCard.prototype = Object.create(Card.prototype);
MouseCard.constructor = MouseCard;

MouseCard.prototype.getViewClass = function () {
    return MouseCardView;
};

MouseCard.prototype.getSkin = function () {
    return this.isOpen() || cleverapps.environment.isSceneWithPreview() ? "mouse" : "backside";
};

MouseCard.prototype.canBeAttacked = function () {
    return false;
};

MouseCard.prototype.canPlay = function () {
    return false;
};

MouseCard.prototype.canBeReplacedWithBoosters = function () {
    return false;
};

MouseCard.FindTop = function () {
    if (!Game.currentGame) {
        return;
    }

    var mouseCardIndex = -1;
    var cards = Game.currentGame.table.cards;
    for (var i = 0; i < cards.length; i++) {
        if (cards[i] instanceof MouseCard && !cards[i].taken && cards[i].isOpen()) {
            mouseCardIndex = i;
        }
    }

    if (mouseCardIndex === -1) {
        return undefined;
    }

    return cards[mouseCardIndex];
};