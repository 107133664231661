/**
 * Created by Andrey Popov on 11.09.20
 */

var StreakCard = function (options) {
    options = options || {};
    this.level = options.level || 0;
    this.counter = options.counter || 6;
    Card.call(this, options);

    this.feature = "streak";
    Game.currentGame.table.streakCard = this;
};

StreakCard.prototype = Object.create(Card.prototype);
StreakCard.constructor = StreakCard;

StreakCard.prototype.getViewClass = function () {
    return StreakCardView;
};

StreakCard.prototype.toJSON = function () {
    return Object.assign(Card.prototype.toJSON.call(this), {
        counter: this.counter,
        level: this.level
    });
};

StreakCard.prototype.getSkin = function () {
    return "dog";
};

StreakCard.prototype.canPlay = function () {
    return false;
};

StreakCard.prototype.canBeAttacked = function () {
    return false;
};

StreakCard.prototype.canBeReplacedWithBoosters = function () {
    return false;
};

StreakCard.prototype.increaseLevel = function (card) {
    this.level++;

    var spawnRequired = this.level >= this.counter;
    var shouldCollectBone = card && (Game.currentGame.table.getAmountOfCards() > 0 || spawnRequired);

    this.trigger("incLevel", card, shouldCollectBone ? new Mark("bone") : undefined);

    if (spawnRequired) {
        this.spawn();
    }
};

StreakCard.prototype.spawn = function () {
    this.trigger("beforeSpawn");

    Game.currentGame.counter.setTimeout(function () {
        this.level -= this.counter;
        var rewardCard = TileFactory.Create({ feature: "wild" });

        this.trigger("spawn", rewardCard);

        Game.currentGame.hand.push(rewardCard, {
            insertIndex: cleverapps.Random.random(Game.currentGame.hand.stack.length),
            fromTable: true
        });

        this.trigger("changeLevel");
    }.bind(this), 2000);
};

StreakCard.prototype.decreaseLevel = function () {
    if (this.level > 0) {
        this.level--;
        this.trigger("changeLevel");
    }
};

StreakCard.prototype.reset = function () {
    if (this.level > 0) {
        this.level = 0;
        this.trigger("reset");
    }
};