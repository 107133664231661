/**
 * Created by Andrey Popov on 12.05.2020
 */

TileFactory.Construct = function (item) {
    switch (item.feature) {
        case "incdec":
            return new IncDecCard(item);

        case "wild":
            return new Wildcard(item);

        case "fillhand":
            return new FillHandCard(item);

        case "weakjoker":
            return new WeakJokerCard(item);

        case "double":
            return new DoubleCard(item);

        case "mouse":
            return new MouseCard(item);

        case "cheese":
            return new CheeseCard(item);

        case "jackpot":
            return new JackpotCard(item);

        case "streak":
            return new StreakCard(item);

        case "suit":
            return new SuitCard(item);

        case "shark":
            return new SharkCard(item);

        case "curtain":
            return new CurtainCard(item);

        case "safe":
            return new SafeCard(item);
    }

    return new Card(item);
};