/**
 * Created by Ivan on 17.06.2024
 */

var PinballGame = function (pinball) {
    cleverapps.EventEmitter.call(this);

    this.pinball = pinball;
    this.gravity = cc.p(0, -PinballGame.GRAVITY);
    this.updateCallback = undefined;
    this.stopped = false;
    this.fieldSize = cc.size(PinballGame.FIELD_WIDTH, PinballGame.FIELD_HEIGHT);

    this.createBall();
    this.createBarriers();
    this.createPrizes();
};

PinballGame.prototype = Object.create(cleverapps.EventEmitter.prototype);
PinballGame.prototype.constructor = PinballGame;

PinballGame.prototype.createBall = function () {
    this.ball = new Ball();
};

PinballGame.prototype.createBarrier = function (barrierInfo) {
    var type = barrierInfo.type;
    var pos = cc.p(barrierInfo.x * this.fieldSize.width, barrierInfo.y * this.fieldSize.height);
    var decelerationFactor = barrierInfo.decelerationFactor;
    var prize = barrierInfo.prize;

    if (PinballGame.SPLIT_TO_PARTS[type]) {
        PinballGame.SPLIT_TO_PARTS[type].forEach(function (coordinates) {
            var delta = cc.p(coordinates.x * this.fieldSize.width, coordinates.y * this.fieldSize.height);
            var barrier = new Barrier({
                position: cc.pAdd(pos, delta),
                radius: 8,
                type: "default",
                decelerationFactor: decelerationFactor,
                hidden: true
            });
            this.barriers.push(barrier);
        }.bind(this));

        this.virtualBarriers.push({
            type: type,
            position: pos
        });
    } else {
        var params = {
            position: pos,
            type: type,
            decelerationFactor: decelerationFactor,
            prize: prize,
            collisionSound: ["left_divider", "right_divider"].indexOf(type) < 0 ? bundles.pinball.urls.barrier_sound : undefined
        };

        if (type === "extra") {
            params.radius = 50;
            params.pinballGame = this;
        }

        var barrier = type === "extra" ? new ExtraBarrier(params) : new Barrier(params);
        this.barriers.push(barrier);
    }
};

PinballGame.prototype.createBarriers = function () {
    var barriersCoordinates = PinballGame.BARRIERS_POSITIONS;
    this.barriers = [];
    this.virtualBarriers = [];

    for (var i = 0; i < barriersCoordinates.length; i++) {
        this.createBarrier(barriersCoordinates[i]);
    }
};

PinballGame.prototype.createPrizes = function () {
    var prizesCoordinates = PinballGame.PRIZES_POSITIONS;
    this.prizes = [];

    for (var i = 0; i < prizesCoordinates.length; i++) {
        var pos = cc.p(prizesCoordinates[i].x * this.fieldSize.width, prizesCoordinates[i].y * this.fieldSize.height);

        var prize = new Prize(pos, 15, i);
        this.prizes.push(prize);
    }
};

PinballGame.prototype.update = function (dt) {
    this.ball.updatePosition(dt, this.gravity, this.fieldSize);

    if (this.ball.isWin()) {
        this.win();
        return;
    }

    this.checkCollisions();
};

PinballGame.prototype.createReward = function (amount) {
    var reward = new Reward("pinballTickets", { amount: amount }, {
        target: "ticket"
    });
    reward.receiveReward();

    return reward;
};

PinballGame.prototype.win = function () {
    var prizeIndex = this.getPrizeIndex();
    var reward = this.createReward(Pinball.REWARDS[prizeIndex]);
    this.prizes[prizeIndex].giveReward(reward);

    this.pinball.win();
    this.stop();
};

PinballGame.prototype.checkCollisions = function () {
    for (var index = 0; index < this.barriers.length; index++) {
        var barrier = this.barriers[index];
        if (barrier.handleCollision(this.ball)) {
            this.ball.slowDown(barrier.decelerationFactor);
            if (barrier.prize) {
                var reward = this.createReward(barrier.prize);
                this.barriers[index].giveReward(reward);
            }
            break;
        }
    }
};

PinballGame.prototype.getPrizeIndex = function () {
    var prizeIndex = 0;
    for (var index = 1; index < this.prizes.length; index++) {
        var dist = this.prizes[index].calcDist(this.ball);
        if (dist < this.prizes[prizeIndex].calcDist(this.ball)) {
            prizeIndex = index;
        }
    }

    return prizeIndex;
};

PinballGame.prototype.showBall = function () {
    this.ball.show();
    this.ball.calcDirection(30, 250);

    if (this.updateCallback) {
        this.updateCallback(true);
    }
};

PinballGame.prototype.hideBall = function () {
    this.ball.direction = cc.p(0, 0);
    this.ball.hide();

    if (this.updateCallback) {
        this.updateCallback(false);
    }
};

PinballGame.prototype.setUpdateCallback = function (callback) {
    this.updateCallback = callback;
};

PinballGame.prototype.run = function (position) {
    this.hideBall();
    this.stopped = false;
    this.ball.setPosition(position.x, position.y);
    this.showBall();
    cleverapps.meta.grabFocus(PinballGame.focus);
};

PinballGame.prototype.stop = function () {
    this.hideBall();
    this.barriers.forEach(function (barrier) {
        barrier.onStop();
    });
    this.trigger("stop");
    cleverapps.meta.freeFocus(PinballGame.focus);
};

PinballGame.getViewPosition = function (position) {
    var absolutePosition = cc.p(position.x / PinballGame.FIELD_WIDTH, position.y / PinballGame.FIELD_HEIGHT);
    var fieldViewSize = cleverapps.scenes.getRunningScene().fieldSize;
    return cc.p(fieldViewSize.width * absolutePosition.x, fieldViewSize.height * absolutePosition.y);
};

PinballGame.FIELD_WIDTH = 830;
PinballGame.FIELD_HEIGHT = 833;
PinballGame.GRAVITY = 1200;

PinballGame.SPLIT_TO_PARTS = {
    "romb": [
        { x: -0.062, y: 0.006 },
        { x: 0.065, y: 0.008 },
        { x: 0, y: 0.05 },
        { x: 0, y: -0.04 },
        { x: -0.03, y: 0.029 },
        { x: -0.032, y: -0.022 },
        { x: 0.031, y: 0.029 },
        { x: 0.033, y: -0.022 }
    ],

    "left": [
        { x: -0.029, y: 0 },
        { x: -0.019, y: 0 },
        { x: 0.007, y: 0.03 },
        { x: 0.007, y: -0.03 },
        { x: 0.021, y: -0.042 },
        { x: 0.021, y: 0.042 },
        { x: 0.029, y: -0.049 },
        { x: 0.029, y: 0.049 },
        { x: 0.035, y: -0.055 },
        { x: 0.035, y: 0.055 }
    ],

    "right": [
        { x: 0.029, y: 0 },
        { x: 0.019, y: 0 },
        { x: -0.007, y: 0.03 },
        { x: -0.007, y: -0.03 },
        { x: -0.021, y: -0.042 },
        { x: -0.021, y: 0.042 },
        { x: -0.029, y: -0.049 },
        { x: -0.029, y: 0.049 },
        { x: -0.035, y: -0.055 },
        { x: -0.035, y: 0.055 }
    ]
};

PinballGame.BARRIERS_POSITIONS = [
    { x: 0.2, y: 0.08, type: "left_divider" },
    { x: 0.4, y: 0.08, type: "left_divider" },
    { x: 0.6, y: 0.08, type: "right_divider" },
    { x: 0.8, y: 0.08, type: "right_divider" },
    { x: 0.18, y: 0.16 },
    { x: 0.4, y: 0.16 },
    { x: 0.61, y: 0.16 },
    { x: 0.82, y: 0.16 },
    { x: 0.1, y: 0.26 },
    { x: 0.3, y: 0.26 },
    { x: 0.5, y: 0.26 },
    { x: 0.7, y: 0.26 },
    { x: 0.9, y: 0.26 },
    {
        x: 0.25, y: 0.42, type: "extra", decelerationFactor: 1.2, prize: 1
    },
    {
        x: 0.75, y: 0.42, type: "extra", decelerationFactor: 1.2, prize: 1
    },
    { x: 0.03, y: 0.55, type: "right" },
    { x: 0.5, y: 0.44 },
    { x: 0.97, y: 0.55, type: "left" },
    { x: 0.3, y: 0.56 },
    { x: 0.7, y: 0.56 },
    { x: 0.15, y: 0.63 },
    { x: 0.85, y: 0.63 },
    { x: 0.2, y: 0.72 },
    { x: 0.4, y: 0.72 },
    { x: 0.6, y: 0.72 },
    { x: 0.8, y: 0.72 },
    { x: 0.5, y: 0.60, type: "romb" },
    { x: 0.11, y: 0.81 },
    { x: 0.305, y: 0.81 },
    { x: 0.504, y: 0.81 },
    { x: 0.699, y: 0.81 },
    { x: 0.9, y: 0.81 }
];

PinballGame.PRIZES_POSITIONS = [
    cc.p(0.1, 0.075),
    cc.p(0.3, 0.075),
    cc.p(0.5, 0.075),
    cc.p(0.7, 0.075),
    cc.p(0.91, 0.075)
];

PinballGame.focus = "PinballGameFocus";