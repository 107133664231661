/**
 * Created by mac on 4/23/20
 */

var EditorCardTableView = CardTableView.extend({
    ctor: function (editorTable) {
        this._super(editorTable);

        this.cardBlocks = [];
        this.updateGrid(this.table.grid);

        this.addControls();

        this.table.on("recreate", this.recreate.bind(this));

        this.table.on("addEditor", function (card, index) {
            var cardView = CardView.createCardView(card);

            if (index === undefined) {
                index = this.table.cards.indexOf(card);
            }
            cardView.setLocalZOrder(index);

            cardView.setPositionRound(this.convertToViewPosition(card));
            this.centerNode.addChild(cardView);

            card.components.forEach(function (component) {
                var ComponentViewClass = component.getViewClass();
                var componentView = new ComponentViewClass(component);
                cardView.addChild(componentView);
            });
        }.bind(this));

        this.table.on("removeCardEditor", function (card) {
            var cardView = card.onGetView();
            cardView.removeFromParent();
        });

        this.table.on("editorMove", function (cards, delta) {
            cards.forEach(function (card) {
                var cardView = card.onGetView();
                var base = card.getViewPosition();
                cardView.setPositionRound({
                    x: base.point.x + delta.x,
                    y: base.point.y + delta.y
                });

                cardView.setRotation((card.r + delta.r) % 360);
                cardView.setLocalZOrder(card.getZ() + 10000);
            }, this);
        }.bind(this));

        this.table.on("editorMoveEnd", function (cards) {
            cards.forEach(function (card) {
                var cardView = card.onGetView();
                cardView.setPositionRound(card.getViewPosition().point);
                cardView.setRotation(card.getRotation());
                cardView.setLocalZOrder(card.getZ());
            }, this);
        }.bind(this));

        this.table.on("editorUpdateZ", function (cards) {
            cards.forEach(function (card, index) {
                var cardView = card.onGetView();
                cardView.setLocalZOrder(index);
            }, this);
        }.bind(this));

        this.table.on("editorChangeOrientation", function () {
            this.updateGrid(this.table.grid);
        }.bind(this));

        this.table.on("drawMagicArea", this.createListener(this.drawMagicArea.bind(this)));

        this.table.on("createCardsBlocks", this.createListener(this.createBlocks.bind(this)));
        this.table.on("removeCardsBlocks", this.createListener(this.removeBlocks.bind(this)));

        this.table.on("toggleSnapMode", this.createListener(this.onToggleSnapMode.bind(this)));

        cc.eventManager.addListener({
            event: cc.EventListener.KEYBOARD,

            onKeyPressed: function (key) {
                var table = Game.currentGame.table;

                if (key === cc.KEY.a && cleverapps.keyboardController.isPressed(cc.KEY.ctrl)) {
                    table.selectAll();
                } else if (key === cc.KEY.x && cleverapps.keyboardController.isPressed(cc.KEY.ctrl)) {
                    table.clearSelection();
                } else if (key === cc.KEY.backspace) {
                    table.removeCardsEditor();
                }

                var keys = {};
                keys[cc.KEY.up] = { x: 0, y: 1 };
                keys[cc.KEY.down] = { x: 0, y: -1 };
                keys[cc.KEY.left] = { x: -1, y: 0 };
                keys[cc.KEY.right] = { x: 1, y: 0 };

                if (keys[key]) {
                    var delta = cc.pMult(keys[key], this.table.grid.step);
                    table.editorCardMoveEnd(delta);
                }
            }.bind(this)
        }, this);
    },

    createBlocks: function (blocks) {
        blocks.forEach(function (block) {
            var blockView = new EditorCardBlockView(block);
            this.cardBlocks.push(blockView);
            this.centerNode.addChild(blockView);
        }.bind(this));
    },

    removeBlocks: function () {
        this.cardBlocks.forEach(function (block) {
            block.removeFromParent();
        });
        this.cardBlocks = [];
    },

    onToggleSnapMode: function (snapMode) {
        this.table.cards.forEach(function (card) {
            var cardView = card.onGetView();
            cardView.toggleFrameVisibility(snapMode);
        });
    },

    updateGrid: function (grid) {
        if (this.gridView) {
            this.gridView.removeFromParent(true);
        }
        this.gridView = new EditorTableGridView(grid);
        this.gridView.setLocalZOrder(-1);
        this.setContentSize2(this.gridView.getContentSize());
        this.centerNode.setPositionRound(this.getCenterNodePosition());
        this.addChild(this.gridView);
    },

    drawMagicArea: function (area) {
        if (this.area) {
            this.area.removeFromParent(true);
        }

        this.area = new cc.Node();
        this.addChild(this.area);
        this.area.setScale(0.99);
        this.area.setLocalZOrder(-1);

        this.area.setContentSize(area.width, area.height);
        this.area.setPosition(area.x, area.y);

        this.area.debugBorder();
    },

    getCenterNodePosition: function () {
        var grid = this.table.grid;
        return cc.p(grid.center.x * grid.step, grid.center.y * grid.step);
    },

    recreate: function (card, newCard) {
        var view = card.onGetView();
        var position = view.getPosition();
        var parent = view.getParent();
        var zOrder = view.getLocalZOrder();

        this.removeCard(card);

        var ViewClass = newCard.getViewClass();
        var newView = new ViewClass(newCard);
        newView.setPosition(position);
        parent.addChild(newView, zOrder);

        newCard.components.forEach(function (component) {
            var ComponentViewClass = component.getViewClass();
            var componentView = new ComponentViewClass(component);
            newView.addChild(componentView);
            component.onShow && component.onShow();
            component.onShowUp && component.onShowUp();
        });
    },

    removeCard: function (card) {
        card.clear();
    },

    addControls: function () {
        var startP = undefined;
        cleverapps.UI.onDrag(this, {
            onClick: function (touch) {
                var pos = this.convertTouchToNodeSpace(touch);
                this.table.editorClicked({
                    x: pos.x,
                    y: pos.y
                });
            }.bind(this),

            onDragStart: function (touch) {
                var pos = this.convertTouchToNodeSpace(touch);
                if (!this.table.getSelectedCard() && !Editor.currentEditor.orangery.selected) {
                    startP = cc.p(Math.round(pos.x), Math.round(pos.y));
                }
                return true;
            }.bind(this),

            onDragMove: function (touch) {
                if (startP) {
                    var displacement = this.convertTouchToNodeSpaceDisplacement(touch);
                    var rect = cc.rect(startP.x, startP.y, Math.round(displacement.x), Math.round(displacement.y));
                    if (rect.width < 0) {
                        rect.width *= -1;
                        rect.x -= rect.width;
                    }
                    if (rect.height < 0) {
                        rect.height *= -1;
                        rect.y -= rect.height;
                    }
                    this.drawSelectionArea(rect);
                    this.table.selectArea(this.selectionRect);
                }
            }.bind(this),

            onDragEnd: function () {
                if (startP) {
                    startP = undefined;
                    this.drawSelectionArea();
                }
            }.bind(this)
        });
    },

    drawSelectionArea: function (rect) {
        if (!this.selectionRect) {
            this.selectionRect = cleverapps.UI.createScale9Sprite(bundles.pixel.frames.pixel_png);
            this.selectionRect.setOpacity(120);
            this.selectionRect.setAnchorPoint(0, 0);
            this.addChild(this.selectionRect, 1000);
        }
        this.selectionRect.setVisible(Boolean(rect));
        if (rect) {
            this.selectionRect.setPositionRound(rect);
            this.selectionRect.setContentSize(rect);
        }
    }
});