/**
 * Created by Ivan on 19.08.2024
 */

var Body = function (position, radius) {
    cleverapps.EventEmitter.call(this);

    this.position = position;
    this.radius = radius;
};

Body.prototype = Object.create(cleverapps.EventEmitter.prototype);
Body.prototype.constructor = Body;

Body.prototype.setPosition = function (x, y) {
    this.position = cc.p(x, y);
};

Body.prototype.updateRadius = function (radius) {
    this.radius = radius;
};

Body.prototype.giveReward = function (reward) {
    this.trigger("giveReward", reward);
};