/**
 * Created by mac on 4/22/20
 */

var TileGameEditorView = EditorViewBase.extend({
    ctor: function (editor) {
        if (resolutionScale < 1) {
            throw "resolutionScale must be 1 in editor!";
        }

        this._super(editor);

        var styles = cleverapps.styles.TileGameEditorView;

        var cardTableView = new EditorCardTableView(Game.currentGame.table);
        cardTableView.setPosition(this.width / 2, this.height / 2);
        this.addChild(cardTableView);

        if (typeof EditorHandView !== "undefined") {
            var handView = new EditorHandView(Game.currentGame.hand, Game.currentGame.open, Game.currentGame.log);
            this.addChild(handView);
        }

        var gitButtons = new GitButtonsView();
        this.addChild(gitButtons);
        gitButtons.setPositionRound(styles.gitButtons);

        if (cleverapps.config.type === "tile3") {
            var maxValueView = new TileColorsEditorView(Editor.currentEditor.tileColorsEditor);
            this.addChild(maxValueView);
            maxValueView.setPositionRound(styles.maxValueBlock);

            var cardsCounterView = new CardsCounterView(Editor.currentEditor.cardsCounter);
            cardsCounterView.setPositionRound(styles.cardsCounter);
            this.addChild(cardsCounterView);
        }

        var layersSwitcherView = new LayersSwitcherView(editor.layerManager.getLayersCount());
        this.addChild(layersSwitcherView);
        layersSwitcherView.setPositionRound(styles.layers);

        var magicView = new MagicView(Editor.currentEditor.magic);
        magicView.setPositionRound(styles.magic);
        this.addChild(magicView);

        var paginationView = new EditorPaginationView(Game.currentGame.pagination);
        paginationView.setPositionRound(styles.pagination);
        this.addChild(paginationView);
    }
});

EditorView = TileGameEditorView;

cleverapps.styles.TileGameEditorView = {
    maxValueBlock: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 20 }
    },
    magic: {
        x: { align: "center", dx: 450 },
        y: { align: "bottom", dy: 40 }
    },
    cardsCounter: {
        x: { align: "center", dx: 240 },
        y: { align: "bottom", dy: 70 }
    },
    margin: 50,
    gitButtons: {
        x: { align: "right", dx: -370 },
        y: { align: "top", dy: -20 }
    },
    pagination: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: -90 }
    },
    orientation: {
        x: { align: "center", dx: -350 },
        y: { align: "top", dy: -20 }
    },
    layers: {
        x: { align: "left", dx: 70 },
        y: { align: "center", dy: -50 }
    }
};
