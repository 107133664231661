/**
 * Created by mac on 1/6/23
 */

var MagicView = cleverapps.Layout.extend({
    ctor: function (magic) {
        this.magic = magic;

        var caption = cleverapps.UI.generateTTFText("Magic cards: ", cleverapps.styles.FONTS.EDITOR_BLUE_TEXT);

        var button = new cleverapps.UI.Button({
            text: "magic",
            width: cleverapps.styles.MagicView.button.width,
            height: cleverapps.styles.MagicView.button.height,
            onClicked: magic.regenerate.bind(magic)
        });

        var valueBlock = new IncDecBlock({
            value: magic.value,
            range: [0, 100],
            onChange: magic.setValue.bind(magic)
        });

        this._super([caption, valueBlock, button], {
            direction: cleverapps.UI.VERTICAL
        });

        magic.onRefreshListener = this.createListener(function () {
            valueBlock._setValue(magic.value, true);
        });
    }
});

cleverapps.styles.MagicView = {
    button: {
        width: 120,
        height: 60
    }
};