/**
 * Created by Andrey Popov on 18.05.20
 */

BombOnCardView.prototype.show = function () {
    if (!this.animation) {
        return;
    }
    this.shown = true;

    var styles = cleverapps.styles.BombOnCardView;

    var appearance = new cleverapps.Spine(bundles.card.jsons.effects_json);
    appearance.setAnimation(0, "appearance_corner", false);
    appearance.setPositionRound(styles.animation);
    appearance.setCompleteListenerRemove();
    this.addChild(appearance);

    var scale = 1;
    if (cleverapps.environment.isSceneWithPreview() && styles.editor) {
        this.animation.setPositionRound(styles.editor);
        scale = styles.editor.scale || 1;
    }
    this.animation.setTimeScale(1);
    this.animation.runAction(
        new cc.ScaleTo(0.33, scale)
    );
};

BombOnCardView.prototype.hide = function () {
    if (!this.animation) {
        return;
    }
    this.shown = false;
    this.animation.runAction(new cc.ScaleTo(0.2, 0));
};

cleverapps.overrideStyles(cleverapps.styles.BombOnCardView, {
    animation: {
        y: 20
    }
});