/**
 * Created by Ivan on 17.06.2024
 */

var PinballScene = cleverapps.FixedWidthScene.extend({
    ctor: function (mission) {
        this._super();
        this.mission = mission;
        this.pinball = mission.logic.pinball;

        this.pinball.on("updateProgress", this.createListener(this.updateProgress.bind(this)));
        this.pinball.on("givePrize", this.createListener(this.givePrize.bind(this)));
        this.pinball.on("missionCompleted", this.createListener(this.onCompleted.bind(this)));
        this.pinball.on("updateState", this.createListener(this.updateState.bind(this)));
        this.pinball.on("updateChips", this.createListener(this.updateChipsText.bind(this)));
        this.pinball.on("showForce", this.createListener(this.showForce.bind(this)));
    },

    onSceneLoaded: function () {
        this._super(cleverapps.Environment.SCENE_PINBALL);
        this.createMachine();
        this.createHeader();
        this.createFooter();
        this.createButtons();
        this.createGame();
        this.createRoll();
        this.windowofferButton = new WindowOffer(this, {
            text: "Pinball Shop",
            onClicked: function () {

            },
            offerType: Offers.TYPES.PINBALL_PACK,
            bundle: "pinball",
            badgeText: "SALE"
        });
        this.windowofferButton.update();
        if (this.pinball.checkComplete()) {
            this.onCompleted();
        }
    },

    showForce: function () {
        this.updateChipsText();
        this.updateState();
        cleverapps.meta.display({
            focus: "PinballForce",
            action: function (f) {
                cleverapps.forces.create(this.buttons[2], Forces.PINBALL);
                cleverapps.forces.onceForceClosed = f;
            }.bind(this)
        });
    },

    createMachine: function () {
        var styles = cleverapps.styles.PinballScene;
        var machine = this.machine = new cc.Scale9Sprite(bundles.pinball.frames.pb_bg_png);
        machine.setContentSize2(styles.machine);
        var background = new cc.Scale9Sprite(bundles.pinball.frames.pb_bg_blue_png);
        background.setPositionRound(styles.machine.background);
        background.setLocalZOrder(-1);
        machine.addChild(background);
        this.addChild(machine);
        background.setContentSize2(styles.machine.background);
        machine.setPositionRound(styles.machine);
    },

    playIntroActions: function (f) {
        this.updateState();
        f();
    },

    createHeader: function () {
        var styles = cleverapps.styles.PinballScene.header;

        var header = this.header = new cc.Sprite(bundles.pinball.frames.header_png);
        this.machine.addChild(header, -1);
        header.setPositionRound(styles);

        var chipsText = this.chipsText = new TextWithIcon("%%" + this.mission.result, {
            font: cleverapps.styles.FONTS.PINBALL_CHIPS_TEXT,
            margin: styles.text.margin,
            icons: {
                "%%": bundles.pinball.frames.pb_token_png
            },
            iconMaxHeight: 1.5
        });
        chipsText.fitTo(styles.text.maxWidth);
        header.addChild(chipsText);
        chipsText.setPositionRound(styles.text);
        var offerButton = this.offerButton = new AdsButtonView(this.pinball);
        header.addChild(offerButton);
        offerButton.setVisible(false);
        offerButton.setPositionRound(styles.text);
        this.updateChipsText();
    },

    createFooter: function () {
        var styles = cleverapps.styles.PinballScene.footer;

        var footer = this.footer = new cc.Sprite(bundles.pinball.frames.pb_bg_wood_png);
        this.machine.addChild(footer);
        footer.setPositionRound(styles);

        var progress = this.progress = new ScaledProgressBar({
            progress: bundles.pinball.frames.progress_bar_png,
            background: bundles.pinball.frames.progress_bar_bg_png
        });
        progress.setLength(styles.progress.width);
        progress.setGoal(Pinball.TICKETS_GOAL);
        progress.setPercentage(this.mission.currency);
        footer.addChild(progress);
        progress.setPositionRound(styles.progress);

        var prize = new cc.Sprite(bundles.pinball.frames.pb_ticket_png);
        progress.addChild(prize);
        prize.setPositionRound(styles.progress.prize);
        cleverapps.aims.registerTarget("ticket", prize);

        this.progressGoals = [];
        for (var index = 0; index < Pinball.GOALS.length; index++) {
            var goal = new cc.Sprite(Pinball.GOALS[index].icon);
            progress.addChild(goal);
            goal.setPositionRound(progress.width * Pinball.GOALS[index].percent, progress.height / 2);
            this.progressGoals.push(goal);

            if (index < this.pinball.currentGoal) {
                goal.setVisible(false);
            }
        }
    },

    createGame: function () {
        var fieldSize = this.fieldSize = {
            width: PinballGame.FIELD_WIDTH * resolutionScale,
            height: PinballGame.FIELD_HEIGHT * resolutionScale
        };

        var gameView = this.gameView = new PinballGameView(this.pinball.game, fieldSize);
        this.machine.addChild(gameView);
        gameView.setPositionRound(cleverapps.styles.PinballScene.field);
    },

    givePrize: function (prizeIndex, callback) {
        this.progressGoals[prizeIndex].runAction(
            new cc.Spawn(
                new cc.ScaleTo(2).easing(cc.easeBackIn()),
                new cc.FadeOut(2)
            )
        );
        var rewardList = new RewardsList(
            RewardsConfig.PinballRewards[prizeIndex],
            {
                event: cleverapps.EVENTS.EARN.MISSION,
                onFinish: callback
            }
        );
        rewardList.receiveRewards();
        rewardList.collectRewardsAnimation(this.progressGoals[prizeIndex]);
    },

    updateProgress: function (progress, callback) {
        this.progress.setPercentage(progress, {
            animated: true,
            callback: callback
        });
    },

    updateChipsText: function (delta) {
        var styles = cleverapps.styles.PinballScene.header.deltaAnimationPosition;
        this.offerButton.updateState();
        this.chipsText.setString("%%" + this.mission.result);
        if (delta) {
            this.chipsText.stopAllActions();
            this.chipsText.runAction(new cc.Sequence(
                new cc.DelayTime(0.6),
                new cc.Show(),
                AnimationsLibrary.animateDelta(
                    delta,
                    {
                        target: this.chipsText,
                        x: styles.x,
                        t: styles.y,
                        font: cleverapps.styles.FONTS.PINBALL_CHIPS_TEXT
                    }
                )
            ));
        } else {
            this.chipsText.setVisible(!this.pinball.isAdAvailable());
        }
    },

    createButtons: function () {
        var styles = cleverapps.styles.PinballScene.buttons;
        var buttons = this.buttons = [];

        for (var index = 0; index < 5; index++) {
            var button = new cleverapps.Spine(bundles.pinball.jsons.arrow_json);
            button.disabled = true;
            cleverapps.UI.onClick(button, function (touch) {
                if (!button.disabled) {
                    this.onClicked(touch);
                }
            }.bind(this));
            buttons.push(button);
        }

        var layout = new cleverapps.Layout(buttons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        layout.setPositionRound(styles);
        this.machine.addChild(layout);
    },

    createRoll: function () {
        var styles = cleverapps.styles.PinballScene.roll;

        var roll = this.roll = new cleverapps.Spine(bundles.pinball.jsons.ball_json);
        roll.setAnimation(0, "animation2", true);
        roll.setVisible(false);

        var rollContainer = new cc.Node();
        rollContainer.setContentSize2(styles.width, roll.height);
        rollContainer.addChild(roll);
        roll.setPositionRound(styles.ball);
        this.gameView.addChild(rollContainer);
        rollContainer.setPositionRound(styles);
    },

    switchRollSound: function (play) {
        if (play && this.rollSounds === undefined) {
            this.rollSounds = this.roll.runAction(new cc.RepeatForever(
                new cc.Spawn(
                    new cc.Sequence(
                        new cc.CallFunc(function () {
                            this.balllSound = cleverapps.audio.playSound(bundles.pinball.urls.ball_json);
                        }.bind(this)),
                        new cc.DelayTime(2)
                    ),
                    new cc.Sequence(
                        new cc.CallFunc(function () {
                            this.arrowSound = cleverapps.audio.playSound(bundles.pinball.urls.arrows_sound);
                        }.bind(this)),
                        new cc.DelayTime(3)
                    )
                )
            ));
        } else {
            this.roll.stopAllActions();
            this.rollSounds = undefined;
            this.balllSound && cleverapps.audio.stopSound(this.balllSound);
            this.arrowSound && cleverapps.audio.stopSound(this.arrowSound);
        }
    },

    enable: function () {
        var styles = cleverapps.styles.PinballScene.roll;
        this.buttons.forEach(function (button) {
            button.setVisible(true);
            button.setAnimation(0, "idle", true);
            button.disabled = false;
        });
        if (this.roll) {
            this.roll.stopAllActions();
            this.roll.setVisible(true);
            this.roll.setPositionRound(styles.ball);
            this.switchRollSound(true);
            this.roll.runAction(
                new cc.RepeatForever(
                    new cc.Sequence(
                        new cc.MoveTo(
                            styles.rollAnimationDuration,
                            this.roll.getParent().width,
                            this.roll.getPosition().y
                        ).easing(cc.easeInOut(2)),
                        new cc.MoveTo(
                            styles.rollAnimationDuration,
                            0,
                            this.roll.getPosition().y
                        ).easing(cc.easeInOut(2))
                    )
                )
            );
        }
    },

    disable: function () {
        this.buttons.forEach(function (button) {
            if (!button.disabled) {
                button.setVisible(false);
            }
            button.disabled = true;
        });
        if (this.roll) {
            this.roll.stopAllActions();
            this.roll.setVisible(false);
            this.switchRollSound();
        }
    },

    onStartGame: function () {
        this.updateChipsText();
        this.disable();
    },

    onClicked: function (touch) {
        if (cleverapps.forces.isRunningForce()) {
            cleverapps.forces.closeRunningForce();
        }

        var pos = this.gameView.convertTouchToNodeSpace(touch);
        pos.y = this.gameView.height - cleverapps.styles.PinballScene.ball.offsetY;
        var absolutePosition = cc.p(pos.x / this.gameView.width, pos.y / this.gameView.height);
        this.pinball.startGame(cc.p(absolutePosition.x * PinballGame.FIELD_WIDTH, absolutePosition.y * PinballGame.FIELD_HEIGHT - 30));

        this.onStartGame();
    },

    updateState: function () {
        if (this.mission.result > 0 && !this.mission.isCompleted()) {
            this.enable();
        } else {
            this.disable();
        }
    },

    scaleGameField: function () {
        var styles = cleverapps.styles.PinballScene;
        this.machine && cleverapps.UI.inflateToBoundaries(this.machine, [], {
            maxScale: 1,
            padding: styles.padding[cleverapps.resolution.mode]
        });
        this.machine.setPositionRound(styles.machine);
    },

    onCompleted: function (callback) {
        var styles = cleverapps.styles.PinballScene.footer.completedText;
        this.disable();
        var text = cleverapps.UI.generateOnlyText("Pinball.completedText", cleverapps.styles.FONTS.WHITE_TEXT);
        this.footer.addChild(text);
        text.setPositionRound(styles);
        text.setCascadeOpacityEnabledRecursively(true);
        text.setOpacity(0);
        this.progress.runAction(
            new cc.Sequence(
                new cc.ScaleTo(1, 0).easing(cc.easeBackIn()),
                new cc.TargetedAction(text, new cc.FadeIn(1)),
                new cc.CallFunc(callback),
                new cc.RemoveSelf()
            )
        );
    },

    updateSize: function () {
        this.windowofferButton && this.windowofferButton.update();
        this._super();
    },

    getBackgroundStyles: function () {
        return {
            bundle: "pinball_bg",
            backgroundId: "background"
        };
    },

    getAudioStyles: function () {
        return {
            res: bundles.pinball.urls.music,
            delay: 1.0
        };
    },

    listBundles: function () {
        return ["pinball", "pinball_bg", "game"];
    },

    closeAction: function () {
        this.switchRollSound();
        this._super();
    }
});

PinballScene.BARRIER_IMAGE = {
    default: bundles.pinball.jsons.hook_json,
    left: bundles.pinball.frames.left_barrier_png,
    right: bundles.pinball.frames.right_barrier_png,
    romb: bundles.pinball.frames.romb_barrier_png,
    left_divider: bundles.pinball.frames.left_wanddown_png,
    right_divider: bundles.pinball.frames.right_wanddown_png
};

cleverapps.styles.PinballScene = {
    buttons: {
        x: { align: "center", dx: 2 },
        y: { align: "top", dy: -130 },
        margin: 101
    },

    padding: [
        {
            x: 10,
            y: 150
        },
        {
            x: 10,
            y: 150
        },
        {
            x: 10,
            y: 0
        }
    ],

    roll: {
        x: { align: "left", dx: 110 },
        y: { align: "top", dy: -8 },
        rollAnimationDuration: 2,
        width: 620,
        ball: {
            x: { align: "left", dx: 0 },
            y: { align: "top", dy: -5 }
        }
    },

    machine: {
        x: { align: "center" },
        y: { align: "center", dy: -50 },
        width: 953,
        height: 1100,
        background: {
            x: { align: "center", dx: 2 },
            y: { align: "center", dy: 90 },
            width: 819,
            height: 780
        }
    },

    header: {
        x: { align: "center" },
        y: { align: "top", dy: 150 },

        billet: {
            x: { align: "center" },
            y: { align: "top", dy: 31 },
            width: 650,
            height: 60
        },

        text: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -29 },
            maxWidth: 200,
            margin: 10
        },
        deltaAnimationPosition: {
            x: {
                align: "center",
                dx: 0
            },
            y: {
                align: "bottom",
                dy: -35
            }
        }
    },

    footer: {
        x: { align: "center", dx: 2 },
        y: { align: "bottom", dy: 48 },

        billet: {
            x: { align: "center" },
            y: { align: "bottom", dy: -90 },
            width: 650,
            height: 120
        },

        progress: {
            x: { align: "center", dx: -5 },
            y: { align: "bottom", dy: 34 },
            width: 750,
            prize: {
                x: { align: "left", dx: -30 },
                y: { align: "center" }
            }
        },

        completedText: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -40 }
        }
    },

    field: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 73 }
    },

    currency: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -200 }
    },

    ball: {
        offsetY: 10
    }
};
