/**
 * Created by mac on 6/22/20
 */

var TileGenerator = function () {
    this._index = -1;
    this._cache = [];
};

TileGenerator.prototype.reset = function (tiles, maxColors) {
    this._cache = cleverapps.Random.shuffle(this.listTiles(tiles, maxColors));
    this._index = -1;
};

TileGenerator.prototype.getAvailableSkins = function (colors) {
    var requiredForLevel = Math.max(colors, TileGenerator.INIT_TILES + cleverapps.simple.getCurrent() * TileGenerator.OPEN_PER_SIMPLE_STAGE);
    var availableSkins = TileGenerator.getExistingSkins();

    var skins = availableSkins.default;

    if (cleverapps.gameModes.replaceTiles) {
        skins = cleverapps.Random.shuffle(availableSkins.event).concat(availableSkins.default);
        skins = skins.slice(0, requiredForLevel);
        return skins;
    }

    var availableOnLevel = Math.min(requiredForLevel, skins.length);

    var openedSkins = skins.splice(0, availableOnLevel);

    var newSkins = TileGenerator.getNewSkins();
    openedSkins = openedSkins.slice(0, openedSkins.length - newSkins.length);
    openedSkins = cleverapps.Random.shuffle(openedSkins);

    return newSkins.concat(openedSkins);
};

TileGenerator.prototype.listTiles = function (tiles, maxColors) {
    var cache = [];

    var skins = this.getAvailableSkins(maxColors);

    var existingAmounts = [];
    for (var j = 0; j < 100; j++) {
        existingAmounts.push(0);
    }
    tiles.forEach(function (data) {
        if (data.value !== undefined) {
            existingAmounts[data.value]++;
        }
    });

    var random = tiles.filter(function (data) {
        return data.value === undefined;
    }).length;

    for (j = 0; j < existingAmounts.length; j++) {
        if (existingAmounts[j] % 3 !== 0) {
            var remaining = 3 - (existingAmounts[j] % 3);
            for (var t = 0; t < remaining && random > 0; t++) {
                random--;
                cache.push(j);
            }
        }
    }

    for (var i = 0; i < random; i++) {
        var block = Math.floor(i / 3);
        var colorNumber = block % maxColors;
        cache.push((skins[colorNumber]));
    }

    return cache;
};

TileGenerator.prototype.next = function () {
    this._index++;
    if (this._index === this._cache.length) {
        this._index = 0;
    }
    return this._cache[this._index];
};

TileGenerator.prototype.clear = function () {
    delete this._cache;
};

TileGenerator.getExistingSkins = function () {
    var skins = { default: [] };
    var currSkin = 0;

    while (bundles.card.frames["skin_" + currSkin]) {
        skins.default.push(currSkin);
        currSkin++;
    }

    if (cleverapps.gameModes.replaceTiles) {
        skins.event = [];
        currSkin = 0;
        while (bundles.card.frames["skin_event_" + currSkin]) {
            skins.event.push("event_" + currSkin);
            currSkin++;
        }
    }

    return skins;
};

TileGenerator.getNewSkins = function (isRandom) {
    var existingSkins = TileGenerator.getExistingSkins().default;
    var newSkins = [];

    var openedTiles = TileGenerator.INIT_TILES + TileGenerator.OPEN_PER_SIMPLE_STAGE * (cleverapps.simple.getCurrent() - 1);

    if (isRandom) {
        openedTiles = Math.round(Math.random() * (existingSkins.length - TileGenerator.OPEN_PER_SIMPLE_STAGE));
    }

    for (var index = 0; index < TileGenerator.OPEN_PER_SIMPLE_STAGE; index++) {
        if (openedTiles + index < existingSkins.length) {
            newSkins.push(existingSkins[openedTiles + index]);
        }
    }

    return newSkins;
};

TileGenerator.INIT_TILES = 8;
TileGenerator.OPEN_PER_SIMPLE_STAGE = 2;