/**
 * Created by Reda on 17.09.2024
 */

var AdsButtonView = cc.Node.extend({
    ctor: function (pinball) {
        this._super();
        this.pinball = pinball;
        var styles = cleverapps.styles.PinballAdsButton;

        var button = this.button = new cleverapps.UI.Button({
            text: "##+1%%",
            width: styles.width,
            height: styles.height,
            font: cleverapps.styles.FONTS.PINBALL_CHIPS_TEXT,
            icons: {
                "%%": bundles.pinball.frames.pb_token_png
            },
            iconMaxHeight: 2,
            onClicked: function () {
                this.pinball.acceptChipsOffer();
            }.bind(this)
        });

        this.addChild(button);
    },

    updateState: function () {
        var silent = this.visible === this.pinball.isAdAvailable();
        this.pinball.isAdAvailable() ? this.enable(silent) : this.disable(silent);
    },

    enable: function (silent) {
        if (silent) {
            this.setVisible(true);
        } else {
            this.setScale(0.4);
            this.runAction(
                new cc.Sequence(
                    new cc.Show(),
                    new cc.ScaleTo(0.2, 1)
                )
            );
        }
    },

    disable: function (silent) {
        if (silent) {
            this.setVisible(false);
        } else {
            this.runAction(
                new cc.Sequence(
                    new cc.DelayTime(0.2),
                    new cc.ScaleTo(0.2, 0.4),
                    new cc.Hide()
                )
            );
        }
    }

});

cleverapps.styles.PinballAdsButton = {
    width: 220,
    height: 80
};