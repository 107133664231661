/**
 * Created by andrey on 19.08.2020.
 */

var WeakJokerCard = function (options) {
    Card.call(this, options);

    this.feature = "weakjoker";
};

WeakJokerCard.prototype = Object.create(Card.prototype);
WeakJokerCard.constructor = WeakJokerCard;

WeakJokerCard.prototype.setupValue = function (value) {
    if (!cleverapps.environment.isEditorScene()) {
        this.setRandomValue();
    }

    if (value !== undefined) {
        this.setRank(value.charAt(0));
    }
};

WeakJokerCard.prototype.getViewClass = function () {
    return WeakJokerCardView;
};

WeakJokerCard.prototype.getSkin = function () {
    if (!this.isOpen()) {
        return "backsideg";
    }

    if (cleverapps.config.editorMode && this.value === undefined) {
        return "unknowng";
    }

    return this.getRank() + "g";
};

WeakJokerCard.prototype.canPlay = function () {
    return this.isOpen() && !this.isBlocked();
};

WeakJokerCard.prototype.canBeAttacked = function () {
    return false;
};

WeakJokerCard.prototype.getMoveToOpenAnimationTime = function () {
    return Card.PLAY_TIMEOUT + WeakJokerCard.PLAY_FLASH_TIMEOUT;
};

WeakJokerCard.prototype.beforePlayCard = function (callback) {
    callback();

    var newCard = new Card({
        value: this.value,
        opened: true
    });
    newCard.setOwner(this.owner);
    var animationTime = this.getMoveToOpenAnimationTime();

    newCard.registerAnimationTime(animationTime + 0.1);
    Game.currentGame.open.replaceCurrentCard(this, newCard, animationTime * 1000);
};

WeakJokerCard.PLAY_FLASH_TIMEOUT = 0.4;