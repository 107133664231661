/**
 * Created by Andrey Popov on 12.05.20
 */

var Wildcard = function (options) {
    options = options || {};
    Card.call(this, options);

    this.feature = "wild";
};

Wildcard.prototype = Object.create(Card.prototype);
Wildcard.constructor = Wildcard;

Wildcard.prototype.getViewClass = function () {
    return WildCardView;
};

Wildcard.prototype.getSkin = function () {
    return "wild";
};

Wildcard.prototype.canPlay = function () {
    return this.isOpen() && !this.isBlocked();
};

Wildcard.prototype.canBeAttacked = function () {
    return false;
};

Wildcard.prototype.autoPlay = function () {
    Game.currentGame.counter.setTimeout(function () {
        if (Game.currentGame.open.getCurrentCard() instanceof Wildcard) {
            Game.currentGame.table.removeCard(this);
            Game.currentGame.hand.push(this, {
                insertIndex: cleverapps.Random.random(Game.currentGame.hand.stack.length),
                fromTable: true
            });
        } else {
            Game.currentGame.table.startPlayCard(this);
        }
        cleverapps.audio.playSound(bundles.game.urls.joker_effect);
    }.bind(this), 600);
};