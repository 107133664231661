/**
 * Created by slava on 19/3/20
 */

var UndoButton = cleverapps.UI.Button.extend({
    ctor: function (options) {
        var baseOptions = this.getOptions();
        baseOptions.onClicked = options.onPressed;

        this._super(baseOptions);
    },

    getOptions: function () {
        var styles = cleverapps.styles.UndoButton;
        var base = {
            width: styles.width,
            height: styles.height
        };

        if (cleverapps.config.type === "klondike") {
            base.content = new cc.Sprite(bundles.game.frames.undo_icon_png);
        } else {
            base.spine = bundles.game.jsons.undo_button_json;
        }

        return base;
    }
});

cleverapps.styles.UndoButton = {
    width: 78,
    height: 78
};