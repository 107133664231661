/**
 * Created by olga on 24.01.2024
 */

var EditorTableGrid = function (canvas, step) {
    this.step = step;
    this.width = Math.floor(canvas.width / step);
    this.height = Math.floor(canvas.height / step);
    this.center = this.getCenter();

    this.cells = [];
    for (var h = 0; h < this.height; h++) {
        this.cells.push([]);
        for (var w = 0; w < this.width; w++) {
            this.cells[h].push(EditorTableGrid.COLOR_NONE);
        }
    }
    this.getView = function () {};
    this.onChangeColor = function () {};
};

EditorTableGrid.prototype.setActive = function (active, card, deltaPos) {
    var color = active ? EditorTableGrid.COLOR_ACTIVE : EditorTableGrid.COLOR_NONE;
    var points = this.getPointsByCard(card, deltaPos);
    points.forEach(function (point) {
        if (this.existCell(point)) {
            this.cells[point.y][point.x] = color;
            this.onChangeColor(point.x, point.y, color);
        }
    }.bind(this));
    if (card.getRotation()) {
        this.fillInSpaces(active);
    }
};

EditorTableGrid.prototype.getCenter = function () {
    return cc.p(Math.floor(this.width / 2), Math.floor(this.height / 2));
};

EditorTableGrid.prototype.existCell = function (point) {
    return this.cells[point.y] && this.cells[point.y][point.x] !== undefined;
};

EditorTableGrid.prototype.clearCells = function () {
    for (var h = 0; h < this.height; h++) {
        for (var w = 0; w < this.width; w++) {
            this.cells[h][w] = EditorTableGrid.COLOR_NONE;
            this.onChangeColor(w, h, EditorTableGrid.COLOR_NONE);
        }
    }
};

EditorTableGrid.prototype.convertUnits = function (value) {
    return Math.round(value / this.step);
};

EditorTableGrid.prototype.getPointsByCard = function (card, deltaPos) {
    deltaPos = deltaPos || cc.p(0, 0);
    var cardWidth = this.convertUnits(Card.WIDTH * card.getScale());
    var cardHeight = this.convertUnits(Card.HEIGHT * card.getScale());
    var posX = this.convertUnits(card.x + deltaPos.x) + this.center.x - cardWidth / 2;
    var posY = this.convertUnits(card.y + deltaPos.y) + this.center.y - cardHeight / 2;

    var rotation = card.r;

    var points = [];

    for (var x = posX; x < posX + cardWidth; x++) {
        for (var y = posY; y < posY + cardHeight; y++) {
            points.push(cc.p(x, y));
        }
    }

    if (card.getRotation()) {
        var centerPoint = cc.p(posX + cardWidth / 2, posY + cardHeight / 2);
        points = this.rotatePoints(points, rotation, centerPoint);
    }

    return points;
};

EditorTableGrid.prototype.rotatePoints = function (points, angle, center) {
    var angleRadians = (Math.PI / 180) * angle;
    points.forEach(function (point) {
        var relativeX = point.x + 1 / 2 - center.x;
        var relativeY = point.y + 1 / 2 - center.y;

        var rotatedX = center.x + Math.sin(angleRadians) * relativeY - Math.cos(angleRadians) * relativeX;
        var rotatedY = center.y + Math.sin(angleRadians) * relativeX + Math.cos(angleRadians) * relativeY;

        point.x = Math.round(rotatedX - 1 / 2);
        point.y = Math.round(rotatedY - 1 / 2);
    }, this);

    return points;
};

EditorTableGrid.prototype.fillInSpaces = function (active) {
    var fillColor = active ? EditorTableGrid.COLOR_ACTIVE : EditorTableGrid.COLOR_NONE;
    var blankColor = active ? EditorTableGrid.COLOR_NONE : EditorTableGrid.COLOR_ACTIVE;
    for (var h = 1; h < this.height - 1; h++) {
        for (var w = 1; w < this.width - 1; w++) {
            var current = this.cells[h][w];
            var privX = this.cells[h][w - 1];
            var nextX = this.cells[h][w + 1];
            var privY = this.cells[h - 1][w];
            var nextY = this.cells[h + 1][w];
            if (current === blankColor && privX === fillColor && nextX === fillColor
                && privY === fillColor && nextY === fillColor) {
                this.cells[h][w] = fillColor;
                this.onChangeColor(w, h, fillColor);
            }
        }
    }
};

EditorTableGrid.COLOR_NONE = 0;
EditorTableGrid.COLOR_ACTIVE = 1;