/**
 * Created by mac on 6/28/20
 */

var Referee = function (options) {
    this.options = options;

    var levelNo = options.level - 1;
    var level = new Level(Math.floor(levelNo / 15), levelNo % 15);

    this.onResult = function (statistics) {
        console.log("Referee:");
        console.log(statistics);
    };

    this.prepare();

    level.load(function () {
        var started = Date.now();

        // console.log("Loaded level")

        this.fullSeason(level, function (statistics) {
            var elapsed = Date.now() - started;

            console.log("Time: " + elapsed + " rate: " + elapsed / statistics.runs);
            this.onResult(statistics);

            this.restore();
        }.bind(this));
    }.bind(this));
};

Referee.prototype.prepare = function () {
    this.oldCollect = Mark.prototype.collect;
    Mark.prototype.collect = function () {};

    this.oldInAnimation = Card.prototype.inAnimation;
    Card.prototype.inAnimation = function () {
        return false;
    };

    var oldSetTimeout = this.oldSetTimeout = setTimeout;
    // eslint-disable-next-line no-global-assign
    setTimeout = function (callback, timeout) {
        // console.log('SetTimeout ' + timeout, callback);
        // if (timeout === 400)
        // throw 1

        oldSetTimeout(callback, timeout);
    };

    this.oldTimeredThrottle = cleverapps.timeredThrottle;

    var oldGameBase = this.oldGameBase = GameBase;
    GameBase = function (level, options) {
        oldGameBase.apply(this, arguments);
        cleverapps.Random.seed(options.seed);
    };
};

Referee.prototype.restore = function () {
    Mark.prototype.collect = this.oldCollect;
    // eslint-disable-next-line no-global-assign
    setTimeout = this.oldSetTimeout;

    Card.prototype.inAnimation = this.oldInAnimation;

    cleverapps.timeredThrottle = this.oldTimeredThrottle;

    GameBase = this.oldGameBase;
};

Referee.prototype.singleGame = function (id, level, callback) {
    var tripeaks = new TriPeaks(level, {
        seed: id
    });

    var rapidTimeout = new RapidTimeout();
    tripeaks.counter.setTimeout = rapidTimeout.setTimeout.bind(rapidTimeout);

    var bot = new AutoBot(id, tripeaks, this.options);

    var totalMoves = 0;

    var stop = function (options) {
        moveProcessor = function () {};
        rapidTimeout.stop();
        tripeaks.stop();

        callback(options);
    };

    var moveProcessor = function () {
        // console.log('Move' + id + ' #', totalMoves)
        totalMoves++;
        if (totalMoves <= 100) {
            bot.makeMove();
        } else {
            console.log("TOO LONG: " + id);
            stop();
        }
    };

    bot.done = function (statistics) {
        if (this.options.log || 1) {
            console.log("DONE: " + id, statistics);
        }

        stop(statistics);
    }.bind(this);

    tripeaks.counter.registerStage(1000000, moveProcessor);
    tripeaks.shiftCard();
    tripeaks.counter.trigger();
};

Referee.prototype.fullSeason = function (level, callback) {
    var totals = {};
    var amount = 0;

    var playOnce = function (f, index) {
        if (this.options.draw) {
            if (Array.isArray(this.options.draw) && this.options.draw.indexOf(index) === -1 || this.options.draw !== index) {
                f();
                return;
            }
        }

        // console.log("Starting a game")
        this.singleGame(index, level, function (stats) {
            for (var key in stats) {
                totals[key] = (totals[key] || 0) * amount + stats[key];
            }

            amount++;

            for (var KEY in stats) {
                totals[KEY] /= amount;
            }

            totals.runs = amount;

            f();
        });
    }.bind(this);

    var runs = [];
    for (var i = 0; i < this.options.runs; i++) {
        runs.push(i);
    }

    var player = new ActionPlayer(runs.map(function (i) {
        return function (f) {
            setTimeout(function () {
                playOnce(f, i);
            }, 0);
        };
    }));

    player.play(function () {
        callback(totals);
    });
};