/**
 * Created by mac on 3/4/20
 */

var OpenCardsView = cc.Node.extend({
    ctor: function (openCards) {
        this._super();

        this.openCards = openCards;

        openCards.on("shift", this.shift.bind(this));
        openCards.on("pop", this.pop.bind(this));

        openCards.on("beforeReplace", this.beforeReplace.bind(this));
        openCards.on("replace", this.replace.bind(this));
        openCards.on("hidePrevious", this.hidePreviousCard.bind(this));

        this.openCards.cards.forEach(function (card, index) {
            var ViewClass = card.getViewClass();
            var view = new ViewClass(card);
            view.setRotation(0);
            view.setLocalZOrder(index);
            this.addChild(view);
            view.visible = index === (this.openCards.cards.length - 1);
        }.bind(this));

        this.openCards.getView = function () {
            return this;
        }.bind(this);
    },

    findPosForCard: function () {
        return cc.p(0, 0);
    },

    hidePreviousCard: function (card) {
        var index = this.openCards.cards.indexOf(card);
        if (index > 0) {
            var view = this.openCards.cards[index - 1].onGetView();
            view.visible = false;
        }
    },

    pop: function () {
        var newTop = this.openCards.getCurrentCard();
        if (newTop) {
            var newTopView = newTop.onGetView();
            newTopView.setVisible(true);
        }
    },

    shift: function (card, lastTop, silent) {
        var cardView = card.onGetView();

        cardView.shiftToOpen(this, {
            zIndex: this.openCards.cards.length,
            callback: function () {
                if (lastTop) {
                    var lastTopView = lastTop.onGetView();
                    if (lastTop.inAnimation()) {
                        var movingNode = cleverapps.scenes.getMovingNode(cardView);
                        var realParent = cardView.parent;
                        cardView.replaceParentSamePlace(movingNode, {
                            keepScale: true
                        });
                        var leftTime = lastTop.animationEndTime - cleverapps.timeouts.getTime();
                        cleverapps.timeouts.setTimeout(function () {
                            cardView.replaceParentSamePlace(realParent, {
                                keepScale: true
                            });
                            lastTopView.setVisible(false);
                        }, leftTime);
                    } else {
                        lastTopView.setVisible(false);
                    }
                }
            },
            silent: silent
        });
    },

    beforeReplace: function (newCard) {
        var ViewClass = newCard.getViewClass();
        var newView = new ViewClass(newCard);
        newView.setVisible(false);
        this.addChild(newView);
    },

    replace: function (oldCard, newCard) {
        var oldView = oldCard.onGetView();
        var newView = newCard.onGetView();

        var index = this.openCards.cards.indexOf(newCard);
        newView.setLocalZOrder(index + 1);
        if (index > 0) {
            var lastTop = this.openCards.cards[index - 1];
            lastTop.onGetView().setVisible(false);
        }

        oldView.removeFromParent();
        newView.setVisible(true);

        cleverapps.audio.playSound(bundles.game.urls.change_trump_effect);
    }
});