/**
 * Created by Andrey Popov on 1/25/23.
 */

var FlagsOnCardView = cc.Node.extend({
    ctor: function (flagsOnCard) {
        this._super();

        this.setAnchorPoint(0.5, 0.5);

        this.flagsOnCard = flagsOnCard;

        this.animation = new cleverapps.Spine(bundles.card.jsons.flags_json);
        this.animation.setAnimation(0, "rope_idle", true);
        this.animation.setPosition(cleverapps.styles.CardView.width / 2, cleverapps.styles.CardView.height / 2);
        this.animation.visible = cleverapps.environment.isSceneWithPreview();
        this.addChild(this.animation);
        this.flagViews = [];

        if (cleverapps.environment.isSceneWithPreview()) {
            this.addFlags();
        }

        flagsOnCard.onDestroy = this.createListener(function () {
            this.removeFromParent();
        }.bind(this));

        if (!cleverapps.environment.isSceneWithPreview()) {
            flagsOnCard.onShow = this.createListener(this.show);
            flagsOnCard.onHide = this.createListener(this.hide);
            flagsOnCard.onCrush = this.createListener(this.crush);
        }
    },

    show: function () {
        this.runAction(new cc.Sequence(
            new cc.DelayTime(FlagsOnCardView.SHOWUP_DELAY / 1000),
            new cc.CallFunc(function () {
                if (this.flagsOnCard.card instanceof Wildcard) {
                    this.animation.setScale(FlagsOnCardView.WILD_CARD_SCALE);
                }

                this.animation.visible = true;
                this.animation.setAnimationAndIdleAfter("rope_appearance", "rope_idle");
                if (!cleverapps.environment.isSceneWithPreview()) {
                    this.addFlags();
                }
                cleverapps.audio.playSound(bundles.game.urls.flag_appear_effect);
            }.bind(this))
        ));

        return 0;
    },

    addFlags: function () {
        this.flagsOnCard.flags.forEach(function (flag, index) {
            var flagAnimation = new cleverapps.Spine(bundles.card.jsons.flags_json);
            flagAnimation.setAnimation(0, "flag_appearance", false);
            flagAnimation.setPosition(flag.styles);
            flagAnimation.setSkin(flag.suit);
            flagAnimation.runAction(new cc.MoveBy(0.6, cleverapps.styles.FlagsOnCardView.appearDistance, 0));

            this.addChild(flagAnimation);

            flagAnimation.setCompleteListener(function () {
                flagAnimation.setCompleteListener();
                flagAnimation.setAnimation(0, flag.styles.animation, true);
            });
            this.flagViews.push(flagAnimation);

            if (cleverapps.environment.isEditorScene()) {
                this.addChangeSuitButton(flagAnimation, index);
            }
        }.bind(this));
    },

    crush: function (card, flagIndex, callback) {
        if (card) {
            var cardView = card.onGetView();

            cardView.runAction(cleverapps.UI.animateCard({
                cardView: cardView,
                position: function () {
                    return {
                        parent: this.parent,
                        point: this.getPosition()
                    };
                }.bind(this),
                duration: FlagsOnCard.INTERCEPT_TIMEOUT,
                animation: "flag_fly"
            }));
        }

        this.runAction(new cc.Sequence(
            new cc.DelayTime(FlagsOnCard.INTERCEPT_TIMEOUT),
            new cc.CallFunc(function () {
                var flagAnimation = this.flagViews[flagIndex];
                if (flagAnimation) {
                    flagAnimation.setAnimation(0, "flag_disappearance", false);
                    flagAnimation.setCompleteListener(function () {
                        flagAnimation.setCompleteListener();
                        flagAnimation.removeFromParent();
                        this.flagViews.splice(flagIndex, 0);
                    }.bind(this));
                }

                callback && callback();
            }.bind(this))
        ));
    },

    hide: function () {
        this.flagViews.forEach(function (flag) {
            flag.visible = false;
        });

        this.animation.setAnimation(0, "rope_disappearance", false);
        this.animation.setCompleteListener(function () {
            this.animation.setCompleteListener();
            this.animation.visible = false;
            this.flagViews.forEach(function (flag) {
                flag.removeFromParent();
            });
            this.flagViews = [];
        }.bind(this));
    },

    addChangeSuitButton: function (flag, index) {
        var buttonStyles = cleverapps.styles.FlagsOnCardView.button;
        var button = new cc.Node();
        button.setContentSize(buttonStyles);
        button.setPositionRound(buttonStyles);
        flag.addChild(button);

        cleverapps.UI.onClick(button, function () {
            for (var i = 0; i < index + 1 - this.flagsOnCard.flagSuits.length; i++) {
                this.flagsOnCard.flagSuits.push("unknown");
            }

            var suits = Card.SUITS.slice(0).concat("unknown");
            var currentSuitIndex = suits.indexOf(this.flagsOnCard.flagSuits[index]);
            this.flagsOnCard.flagSuits[index] = suits[(currentSuitIndex + 1) % suits.length];
            flag.setSkin(this.flagsOnCard.flagSuits[index]);
            Editor.currentEditor.save();
        }.bind(this));
    }
});

cleverapps.styles.FlagsOnCardView = {
    FLAG: {
        left: {
            x: -20,
            y: 50,
            animation: "flag_idle_left"
        },
        center: {
            x: 20,
            y: 60,
            animation: "flag_idle_center"
        },
        right: {
            x: 40,
            y: 70,
            animation: "flag_idle_right"
        }
    },
    appearDistance: 50,
    button: {
        x: 60,
        y: 20,
        width: 36,
        height: 36
    }
};

FlagsOnCardView.WILD_CARD_SCALE = 1.1;
FlagsOnCardView.SHOWUP_DELAY = 500;