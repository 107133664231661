/**
 * Created by Andrey Popov on 08.10.20
 */

var SharkCard = function (options) {
    Card.call(this, options);

    this.featureTrigger = Card.MOVES.PLAY | Card.MOVES.SHIFT;
    this.angryLevel = 0;

    Game.currentGame.counter.registerStage(202, SharkCard.process);

    this.feature = "shark";
};

SharkCard.prototype = Object.create(Card.prototype);
SharkCard.constructor = SharkCard;

SharkCard.prototype.getViewClass = function () {
    return SharkCardView;
};

SharkCard.prototype.flip = function () {
    if (this.isOpen()) {
        this.angryLevel = 0;
    }
    this.canFeatureProcess = false;
    Card.prototype.flip.call(this);
};

SharkCard.prototype.danceMove = function () {
    if (!this.canFeatureProcess) {
        return;
    }
    this.canFeatureProcess = false;

    this.angryLevel++;
    this.trigger("changeValue");
};

SharkCard.process = function () {
    var hungrySharks = Game.currentGame.table.cards.filter(function (card) {
        return card instanceof SharkCard && card.isOpen() && card.angryLevel >= SharkCard.MAX_ANGRY_LEVEL;
    });

    for (var i = 0; i < hungrySharks.length; i++) {
        var hand = Game.currentGame.hand;
        var shark = hungrySharks[i];
        var top = hand.top();
        if (!top || top instanceof Wildcard) {
            shark.angryLevel--;
            continue;
        }

        var cardToEat = hand.pop();
        shark.angryLevel -= SharkCard.MAX_ANGRY_LEVEL;

        var eatDelay = SharkCard.START_EAT_DELAY + i * 0.1;
        cardToEat.registerAnimationTime(SharkCard.EAT_CARD_TIME + eatDelay);
        shark.registerAnimationTime(SharkCard.EAT_CARD_TIME + eatDelay);
        shark.trigger("eatCard", cardToEat, eatDelay);

        Game.currentGame.counter.setTimeout(function () {
            Game.currentGame.hand.trigger("adjustPositions");
            Game.currentGame.hand.extra.update();
        }, (SharkCard.EAT_CARD_TIME + eatDelay) * 1000);
    }
};

SharkCard.prototype.beforePlayCard = function (callback) {
    this.trigger("removeDaggers");

    if (this.isNearToDestroy()) {
        callback();
    } else {
        Game.currentGame.counter.setTimeout(callback, 400);
    }
};

SharkCard.prototype.canBeReplacedWithBoosters = function () {
    return false;
};

SharkCard.MAX_ANGRY_LEVEL = 3;

SharkCard.EAT_CARD_TIME = 1.2;
SharkCard.START_EAT_DELAY = 0.4;