/**
 * Created by Andrey Popov on 22.09.20
 */

var SuitCard = function (options) {
    Card.call(this, options);

    this.feature = "suit";
};

SuitCard.prototype = Object.create(Card.prototype);
SuitCard.constructor = SuitCard;

SuitCard.prototype.setupSuitValue = function () {
    if (!cleverapps.environment.isEditorScene()) {
        this.setRandomValue();
    }

    if (this.options.value !== undefined) {
        this.setSuit(this.options.value.charAt(1));
    }
};

SuitCard.prototype.getViewClass = function () { 
    return SuitCardView;
};

SuitCard.prototype.getSkin = function () {
    if (cleverapps.config.editorMode && this.value === undefined) {
        return "unknown";
    }

    if (!this.isOpen() && !cleverapps.environment.isEditorScene()) {
        return "backside";
    }

    return this.getSuit();
};

SuitCard.prototype.isNext = function (otherCard) {
    return this.isEqualSuit(otherCard) || otherCard instanceof Wildcard;
};

SuitCard.prototype.canBeReplacedWithBoosters = function () {
    return true;
};