/**
 * Created by Ivan on 19.08.2024
 */

var ExtraBarrier = function (params) {
    this.params = params || {};
    this.stage = 0;
    this.active = true;
    this.prize = params.prize;
    this.pinballGame = params.pinballGame;

    Barrier.call(this, this.params);
};

ExtraBarrier.prototype = Object.create(Barrier.prototype);
ExtraBarrier.prototype.constructor = ExtraBarrier;

ExtraBarrier.prototype.onCollision = function () {
    if (!this.active) {
        return;
    }

    this.stage++;

    if (this.stage === ExtraBarrier.STAGES) {
        this.active = false;
        this.decelerationFactor = Barrier.DECELERATION_FACTOR;
        this.pinballGame.trigger("prize_highlight");
        this.prize = undefined;
    }

    this.trigger("changeStage", this.stage);
};

ExtraBarrier.prototype.handleCollision = function (ball) {
    var hasCollision = Barrier.prototype.handleCollision.call(this, ball);
    if (hasCollision) {
        this.onCollision();
    }

    return hasCollision;
};

ExtraBarrier.prototype.playSound = function () {
    var sound = bundles.pinball.urls["extrabarrier_sound_" + (
        Math.floor(Math.random() * 5) + 1
    )];
    cleverapps.audio.playSound(sound);
};

ExtraBarrier.prototype.getStagesLeft = function () {
    return ExtraBarrier.STAGES - this.stage;
};

ExtraBarrier.prototype.onStop = function () {
    this.stage = 0;
    this.active = true;
    this.decelerationFactor = this.params.decelerationFactor;

    this.trigger("changeStage", this.stage);
};

ExtraBarrier.STAGES = 5;