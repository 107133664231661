/**
 * Created by vladislav on 07.06.2021
 */

var SafeCard = function (options) {
    Card.call(this, options);

    this.requiredSuit = options.requiredSuit;
    this.requiredValue = options.requiredValue;

    this.suitOpened = false;
    this.valueOpened = false;

    this.feature = "safe";
};

SafeCard.prototype = Object.create(Card.prototype);
SafeCard.constructor = SafeCard;

SafeCard.prototype.getViewClass = function () {
    return SafeCardView;
};

SafeCard.prototype.toJSON = function () {
    return Object.assign(Card.prototype.toJSON.call(this), {
        requiredSuit: this.requiredSuit,
        requiredValue: this.requiredValue
    });
};

SafeCard.prototype.openSafe = function () {
    this.trigger("openSafe");

    Game.currentGame.table.removeCard(this);

    Game.currentGame.counter.setTimeout(this.spawnWildcard.bind(this), 2500);
};

SafeCard.prototype.spawnWildcard = function () {
    var rewardCard = TileFactory.Create({ feature: "wild" });
    this.trigger("spawn", rewardCard);

    Game.currentGame.counter.setTimeout(function () {
        Game.currentGame.hand.push(rewardCard, {
            insertIndex: cleverapps.Random.random(Game.currentGame.hand.stack.length),
            fromTable: true
        });

        Game.currentGame.counter.setTimeout(function () {}, 1000);
    }, 500);
};

SafeCard.prototype.canOpenSuit = function (card) {
    return (card.getSuit() === this.requiredSuit || card instanceof Wildcard) && !this.suitOpened;
};

SafeCard.prototype.canOpenValue = function (card) {
    return (card.getCardValue() === this.requiredValue || card instanceof Wildcard) && !this.valueOpened;
};

SafeCard.prototype.handleClick = function () {
    var currentCard = Game.currentGame.open.getCurrentCard();

    var canOpenSuit = this.canOpenSuit(currentCard);
    var canOpenValue = this.canOpenValue(currentCard);

    if (this.isOpen()) {
        if (!currentCard.inAnimation() && (canOpenSuit || canOpenValue)) {
            var open = Game.currentGame.open;
            var openedCard = open.cards.pop();

            var newTop = open.getCurrentCard();
            if (newTop) {
                open.trigger("pop", newTop);
            } else {
                newTop = Game.currentGame.hand.shift(Game.currentGame.table);
                open.shift(newTop);
            }

            if (canOpenValue && canOpenSuit) {
                this.valueOpened = true;
                this.suitOpened = true;

                this.trigger("openValueAndSuit", openedCard);
            } else if (canOpenValue) {
                this.valueOpened = true;

                this.trigger("openValue", openedCard);
            } else if (canOpenSuit) {
                this.suitOpened = true;

                this.trigger("openSuit", openedCard);
            }

            Game.currentGame.log.clear();

            if (this.suitOpened && this.valueOpened) {
                Game.currentGame.counter.setTimeout(this.openSafe.bind(this), SafeCard.FLY_TIME + 300);
            } else {
                Game.currentGame.counter.setTimeout(function () {}, 100);
            }

            return true;
        } 
        cleverapps.notification.create("SafeNotification", {
            image: bundles.card.frames.safe_png
        });

        return false;
    }

    return false;
};

SafeCard.prototype.canBeReplacedWithBoosters = function () {
    return false;
};

SafeCard.prototype.canPlay = function (previousCard) {
    return this.isOpen() && (this.canOpenSuit(previousCard) || this.canOpenValue(previousCard));
};

SafeCard.prototype.canBeAttacked = function () {
    return false;
};

SafeCard.prototype.getSkin = function () {
    return Card.VALUES[this.requiredValue] + "_" + this.requiredSuit;
};

SafeCard.prototype.getSize = function () {
    return {
        width: SafeCard.WIDTH,
        height: SafeCard.HEIGHT
    };
};

SafeCard.WIDTH = 240;
SafeCard.HEIGHT = 280;

SafeCard.FLY_TIME = 400;