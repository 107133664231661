/**
 * Created by mac on 6/22/20
 */

TileGenerator.prototype.listTiles = function () {
    var cache = [];

    Card.SUITS.forEach(function (suit) {
        Card.VALUES.forEach(function (value) {
            cache.push(value + suit);
        });
    });

    return cache;
};