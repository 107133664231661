/**
 * Created by andrey on 01.08.2022
 */

var HideAnimations = function (f) {
    var game = Game.currentGame;

    var actions = [
        game.clearTable.bind(game),

        function (f) {
            game.hand.hideExtra();
            f();
        }
    ];

    new ActionPlayer(actions).play(f);
};