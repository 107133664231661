/**
 * Created by Andrey Popov on 1/25/23.
 */

var ChainOnCardView = cc.Node.extend({
    ctor: function (chainOnCard) {
        this._super();

        this.setAnchorPoint(0.5, 0.5);

        this.animation = new cleverapps.Spine(bundles.card.jsons.lock_json);
        this.animation.setAnimation(0, "lock_idle", true);
        this.animation.setPosition(cleverapps.styles.CardView.width / 2, cleverapps.styles.CardView.height / 2);
        this.animation.visible = cleverapps.environment.isSceneWithPreview();
        this.addChild(this.animation);

        chainOnCard.onShowUp = this.createListener(this.showUp);
        chainOnCard.onHide = this.createListener(this.hide);
        chainOnCard.onDestroy = this.createListener(this.onDestroy);
    },

    showUp: function () {
        var appearance = new cleverapps.Spine(bundles.card.jsons.effects_json);
        appearance.setAnimation(0, "appearance", false);
        appearance.setPosition(cleverapps.styles.CardView.width / 2, cleverapps.styles.CardView.height / 2);
        appearance.setCompleteListenerRemove(function () {
            this.animation.visible = true;
        }.bind(this));
        this.addChild(appearance);

        cleverapps.audio.playSound(bundles.game.urls.decorator_appear_effect);

        return ChainOnCardView.SHOWUP_DELAY;
    },

    onDestroy: function () {
        var openAnimation = new cleverapps.Spine(bundles.card.jsons.lock_json);
        openAnimation.setAnimation(0, "lock_open_key", false);
        openAnimation.setCompleteListener(function () {
            openAnimation.runAction(new cc.RemoveSelf());
        });
        openAnimation.setPosition(this.width / 2, this.height / 2);
        this.addChild(openAnimation);
        openAnimation.replaceParentSamePlace(cleverapps.scenes.getMovingNode());

        this.animation.setAnimation(0, "lock_open", false);
        this.animation.setCompleteListener(function () {
            this.removeFromParent();
        }.bind(this));
    },

    hide: function () {
        this.animation.visible = false;
    }

});

ChainOnCardView.SHOWUP_DELAY = 500;