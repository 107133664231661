/**
 * Created by olga on 11.01.2024
 */

var EditorCardBlockView = cc.Node.extend({
    ctor: function (block) {
        this._super();

        this.block = block;
        this.selected = block.selected;
        this.setContentSize2(block);

        this.setPosition(block.getViewPosition().point);

        this.drawBorder();
        this.addControls();
        this.addAlignPoints();
        this.setLocalZOrder(block.getZ());

        block.onGetView = this.createListener(function () {
            return this;
        }.bind(this));
        block.onSelect = this.createListener(this.select.bind(this));
        block.onActivateAlignPoint = this.createListener(this.activateAlignPoint.bind(this));
        block.onDeactivateAlignPoints = this.createListener(this.deactivateAlignPoint.bind(this));
    }

});

EditorCardBlockView.prototype.drawBorder = function () {
    var styles = cleverapps.styles.EditorCardBlockView.border;
    var border = cleverapps.UI.createScale9Sprite(bundles.dev_resources.frames.border);
    border.setAnchorPoint(0, 0);
    var size = this.getContentSize();
    border.setContentSize(size.width + styles.thickness, size.height + styles.thickness);
    border.setColor(styles.color);
    this.addChild(border);
};

EditorCardBlockView.prototype.activateAlignPoint = function (align) {
    this.alignPoints[align].setVisible(true);
};

EditorCardBlockView.prototype.deactivateAlignPoint = function () {
    Object.keys(this.alignPoints).forEach(function (align) {
        this.alignPoints[align].setVisible(false);
    }.bind(this));
};

EditorCardBlockView.prototype.addAlignPoints = function () {
    var styles = cleverapps.styles.EditorCardBlockView.alignPoints;
    this.alignPoints = {};

    Object.values(EditorCardBlock.ALIGN).forEach(function (align) {
        var pointStyle = styles[align];
        var point = this.alignPoints[align] = new cc.Node();
        point.setPositionRound(pointStyle);
        point.setVisible(false);
        this.addChild(point);

        var image = new cc.Sprite(pointStyle.img);
        image.setRotation(pointStyle.rotation);
        if (!align.includes("center")) {
            image.setAnchorPoint(1, 0.5);
        }
        point.addChild(image);

        var line = this.createAlignLine(pointStyle.vertical);
        point.addChild(line);
    }.bind(this));
};

EditorCardBlockView.prototype.createAlignLine = function (vertical) {
    var styles = cleverapps.styles.EditorCardBlockView.alignLine;
    var line = new cc.Scale9Sprite(bundles.pixel.frames.pixel_png);
    line.setContentSize(vertical ? styles.thickness : styles.length, vertical ? styles.length : styles.thickness);
    line.setColor(styles.color);
    return line;
};

EditorCardBlockView.prototype.addControls = function () {
    this.controls = [];

    if (cleverapps.config.type === "solitaire") {
        this.controls = this.controls.concat(this.getRotationControls());
    }
    this.controls.push(this.getDeleteControl());

    this.controls.forEach(function (control) {
        this.addChild(control);
        control.setVisible(false);
    }.bind(this));
};

EditorCardBlockView.prototype.select = function (select) {
    var baseZ = this.block.getZ();
    this.setLocalZOrder(select ? baseZ + 1000 : baseZ);
    this.controls.forEach(function (control) {
        control.setVisible(select);
    });
};

EditorCardBlockView.prototype.getRotationControls = CardView.prototype.getRotationControls;
EditorCardBlockView.prototype.getDeleteControl = CardView.prototype.getDeleteControl;

cleverapps.styles.EditorCardBlockView = {
    border: {
        color: new cc.Color(0, 0, 255, 255),
        thickness: 2
    },
    alignLine: {
        length: 3000,
        thickness: 3,
        color: new cc.Color(255, 0, 0, 255)
    },
    alignPoints: {}
};

cleverapps.styles.EditorCardBlockView.alignPoints[EditorCardBlock.ALIGN.CENTER_X] = {
    x: { align: "center" },
    y: { align: "center" },
    rotation: 0,
    img: bundles.editor.frames.center_point_png,
    vertical: true
};

cleverapps.styles.EditorCardBlockView.alignPoints[EditorCardBlock.ALIGN.CENTER_Y] = {
    x: { align: "center" },
    y: { align: "center" },
    rotation: 0,
    img: bundles.editor.frames.center_point_png
};

cleverapps.styles.EditorCardBlockView.alignPoints[EditorCardBlock.ALIGN.UP] = {
    x: { align: "center" },
    y: { align: "top" },
    rotation: -90,
    img: bundles.editor.frames.side_point_png
};

cleverapps.styles.EditorCardBlockView.alignPoints[EditorCardBlock.ALIGN.DOWN] = {
    x: { align: "center" },
    y: { align: "bottom" },
    rotation: 90,
    img: bundles.editor.frames.side_point_png
};

cleverapps.styles.EditorCardBlockView.alignPoints[EditorCardBlock.ALIGN.LEFT] = {
    x: { align: "left" },
    y: { align: "center" },
    rotation: 180,
    img: bundles.editor.frames.side_point_png,
    vertical: true
};

cleverapps.styles.EditorCardBlockView.alignPoints[EditorCardBlock.ALIGN.RIGHT] = {
    x: { align: "right" },
    y: { align: "center" },
    rotation: 0,
    img: bundles.editor.frames.side_point_png,
    vertical: true
};
