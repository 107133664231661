/**
 * Created by Ivan on 17.06.2024
 */

var PinballMissionLogic = function (mission) {
    this.mission = mission;
    this.pinball = new Pinball(mission);

    mission.currency = mission.currency || 0;
};

PinballMissionLogic.prototype.isForceAvailable = function () {
    return this.pinball.isForceAvailable();
};

PinballMissionLogic.prototype.showForce = function () {
    return this.pinball.showForce();
};
