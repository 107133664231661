/**
 * Created by vladislav on 24/01/2023
 */

var EditorPaginationView = cleverapps.Layout.extend({
    ctor: function (pagination) {
        this.pagination = pagination;

        var styles = cleverapps.styles.EditorPaginationView;

        this.points = this.listPoints();

        this._super(this.points, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        this.pagination.setPage(this.pagination.getCurrent());

        this.pagination.on("changePage", this.createListener(this.changePage.bind(this)));
        this.pagination.on("addPage", this.createListener(this.changePage.bind(this)));
        this.pagination.on("removePage", this.createListener(this.changePage.bind(this)));

        this.setPositionRound(styles);
    },

    listPoints: function () {
        var points = [];

        for (var i = 0; i < this.pagination.getTotal(); i++) {
            var isCurrent = i === this.pagination.getCurrent();

            var image = bundles.editor.frames[isCurrent ? "editor_pagination_point_completed" : "editor_pagination_point"];
            var point = new cc.Sprite(image);
            points.push(point);

            if (!isCurrent) {
                cleverapps.UI.onClick(point, this.pagination.setPage.bind(this.pagination, i));
                cleverapps.UI.applyHover(point);
            }
        }

        var removeButton = this.createRemoveButton();
        var addButton = this.createAddButton();

        points.unshift(removeButton);
        points.push(addButton);

        return points;
    },

    changePage: function () {
        this.setItems(this.listPoints());
    },

    createRemoveButton: function () {
        var styles = cleverapps.styles.EditorPaginationView;

        return new cleverapps.UI.Button({
            noBg: true,
            content: new cc.Sprite(bundles.editor.frames.editor_minus_icon),
            width: styles.button.width,
            height: styles.button.height,
            onClicked: function () {
                if (Game.currentGame.table.cards.length === 0
                    || confirm("Delete screen no " + Game.currentGame.pagination.getCurrent() + "?")) {
                    Game.currentGame.pagination.removePage(Game.currentGame.pagination.getCurrent());
                }
            }
        });
    },

    createAddButton: function () {
        var styles = cleverapps.styles.EditorPaginationView;

        return new cleverapps.UI.Button({
            noBg: true,
            content: new cc.Sprite(bundles.editor.frames.editor_plus_icon),
            width: styles.button.width,
            height: styles.button.height,
            onClicked: function () {
                Game.currentGame.pagination.addPage();

                Game.currentGame.pagination.setPage(Game.currentGame.pagination.getTotal() - 1);
            }
        });
    }
});

cleverapps.styles.EditorPaginationView = {
    x: { align: "center", dx: 0 },
    y: { align: "top", dy: -30 },
    margin: 30,
    button: {
        width: 50,
        height: 50
    }
};