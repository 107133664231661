/**
 * Created by mac on 6/28/20
 */

var AutoBot = function (id, tripeaks, options) {
    this.id = id;
    this.tripeaks = tripeaks;
    this.options = options;

    this.statistics = {
        moves: 0,
        hand: 0,
        extra: 0,
        win: 0
    };

    this.done = function () {};
};

Card.prototype.toString = function () {
    if (this instanceof Wildcard) {
        return "WILD";
    }

    if (this instanceof IncDecCard) {
        return "+-" + this.getSkin();
    }

    if (this instanceof BombCard) {
        return "bomb" + this.getSkin();
    }

    return this.getSkin();
};

AutoBot.prototype.makeMove = function () {
    if (this.tripeaks.counter.isActive()) {
        throw 1;
    }

    if (this.tripeaks.table.isEmpty()) {
        if (this.statistics.extra === 0) {
            this.statistics.win = 100;
        }
        this.done(this.statistics);
        return;
    }

    var open = this.tripeaks.open.getCurrentCard();

    var cards = this.tripeaks.table.cards.filter(function (card) {
        return card.isOpen();
    });

    if (this.options.log) {
        console.log(this.id + " Open:", open.toString().toUpperCase());
        console.log(cards.map(function (card) {
            return card.toString();
        }));
    }

    var bestMove = this.findBestMove(open, cards, new Set());
    var card = bestMove.card;
    var length = bestMove.length;

    this.statistics.moves++;
    if (card) {
        if (this.options.log) {
            console.log("move " + card.toString().toUpperCase() + ": " + length);
        }
        this.tripeaks.table.cardClicked(card);
    } else if (!this.tripeaks.hand.isEmpty()) {
        if (this.options.log) {
            console.log("hand");
        }
        this.statistics.hand++;
        this.tripeaks.hand.cardClicked();
        this.tripeaks.counter.trigger();
    } else {
        if (this.options.log) {
            console.log("extra draw");
        }
        this.statistics.extra++;
        this.tripeaks.hand.push();
        this.tripeaks.counter.trigger();
    }
};

AutoBot.CloneGame = function () {

};

AutoBot.prototype.findBestMove = function (open, cards, busy) {
    var matches = cards.filter(function (card) {
        if (busy.has(card)) {
            return false;
        }
        return card.isNext(open) || open.isNext(card);
    });

    var bestMove = {
        card: undefined,
        score: 0
    };

    matches.forEach(function (match) {
        busy.add(match);

        var res = this.findBestMove(match, cards, busy);

        busy.delete(match);

        if (res.score + 1 > bestMove.score) {
            bestMove = {
                card: match,
                score: res.score + 1
            };
        }
    }, this);

    return bestMove;
};