/**
 * Created by mac on 4/28/20
 */

var EditorCardTable = function () {
    TileTable.apply(this, arguments);

    this.selected = new SelectionSet();
    this.blocks = [];
    this.selectBlocksMode = false;
    this.snapMode = false;
    this.setGrid();
};

EditorCardTable.prototype = Object.create(TileTable.prototype);
EditorCardTable.constructor = EditorCardTable;

EditorCardTable.prototype.getAmountOfCards = function () {
    return this.cards.reduce(function (count) {
        return count + 1;
    }, 0);
};
EditorCardTable.prototype._calcRectSuper = EditorCardTable.prototype.calcRect;
EditorCardTable.prototype.calcRect = function () {
    return cc.rect(0, 0, this.canvas.width, this.canvas.height);
};

EditorCardTable.prototype.setOrientation = function (orientation) {
    this.orientation = orientation;

    this.canvas = this.getCanvas();
    this.setGrid();

    var screen = Game.currentGame.level.content.screens[Game.currentGame.pagination.getCurrent()];
    var cards = screen.cards;
    if (!cards || screen.horizontalCards && screen.horizontalCards.length && (orientation === TileTable.ORIENTATION_HORIZONTAL)) {
        cards = screen.horizontalCards;
    }
    this.setCards(cards, TileTable.ListMagicCards(screen), screen.maxValue);
    if (this.snapMode) {
        this.toggleSnapMode();
        this.toggleSnapMode();
    }
    this.flipNormalize();
    this.setRect();
    this.updateSize();
    Editor.currentEditor.history.reset();
    Editor.currentEditor.save();

    this.clearSelection();
    if (this.selectBlocksMode) {
        this.updateBlocks();
    }
    this.trigger("editorChangeOrientation");
};

EditorCardTable.prototype.getOrientation = function () {
    return this.orientation;
};

EditorCardTable.prototype.needAdaptToCanvas = function () {
    var rect = this._calcRectSuper();
    if (this.getOrientation() === TileTable.ORIENTATION_HORIZONTAL) {
        return rect.height > this.canvas.height;
    } 
    return rect.width > this.canvas.width;
};

EditorCardTable.prototype.recreate = function (card, newCard) {
    this.selected.delete(card);

    newCard.opened = card.opened;
    var index = this.cards.indexOf(card);
    this.cards[index] = newCard;
    this.trigger("recreate", card, newCard);

    Game.currentGame.counter.trigger();
};

EditorCardTable.prototype.unselect = function (card) {
    var toUnSelect = [card];
    if (this.selectBlocksMode) {
        var block = this.blocks.find(function (block) {
            return block.cards.includes(card);
        });
        block.select(false);
        toUnSelect = block.cards;
    }

    toUnSelect.forEach(function (card) {
        this.selected.delete(card);
        this.selectCard(card, false);
    }.bind(this));
};

EditorCardTable.prototype.select = function (card) {
    var toSelect = [card];
    if (this.selectBlocksMode) {
        var block = this.blocks.find(function (block) {
            return block.cards.includes(card);
        });
        block.select(true);
        toSelect = block.cards;
    }

    toSelect.forEach(function (card) {
        this.selected.add(card);
        this.selectCard(card, true);
    }.bind(this));
};

EditorCardTable.prototype.toggleSelectBlocksMode = function () {
    this.clearSelection();
    this.selectBlocksMode = !this.selectBlocksMode;
    if (this.selectBlocksMode) {
        this.createBlocks();
    } else {
        this.removeBlocks();
    }
};

EditorCardTable.prototype.setGrid = function () {
    var gridStep = Card.GRID_XY;
    if (cleverapps.config.type === "tile3") {
        gridStep = Card.WIDTH / 2;
    }
    this.grid = new EditorTableGrid(this.getCanvas(), gridStep);
};

EditorCardTable.prototype.toggleSnapMode = function () {
    this.clearSelection();
    this.grid.clearCells();
    this.snapMode = !this.snapMode;
    if (!this.snapMode) {
        this.flipNormalize();
    }
    this.trigger("toggleSnapMode", this.snapMode);
};

EditorCardTable.prototype.createBlocks = function () {
    this.blocks = cleverapps.cardBlockHandler.findCardsClusters().map(function (block) {
        return new EditorCardBlock(block);
    });
    this.trigger("createCardsBlocks", this.blocks);
};

EditorCardTable.prototype.removeBlocks = function () {
    this.trigger("removeCardsBlocks");
    this.blocks = [];
};

EditorCardTable.prototype.updateBlocks = function () {
    this.removeBlocks();
    this.createBlocks();
};

EditorCardTable.prototype.getBlocks = function (selected) {
    return this.blocks.filter(function (block) {
        if (selected) {
            return block.selected;
        } 
        return !block.selected;
    });
};

EditorCardTable.prototype.alignBlocks = function (deltaPos) {
    this.blocks.forEach(function (block) {
        block.deactivateAlignPoints();
    });
    var selectedBlocks = this.getBlocks(true);
    var unSelectedBlocks = this.getBlocks(false);
    var eps = 20;
    var deviations = { x: {}, y: {} };

    unSelectedBlocks.forEach(function (unselected) {
        selectedBlocks.forEach(function (selected) {
            var selectedAlignLines = selected.getAlignLines();
            var unselectedAlignLines = unselected.getAlignLines();
            ["x", "y"].forEach(function (coord) {
                selectedAlignLines[coord].forEach(function (selectedLine) {
                    selectedLine[coord] += deltaPos[coord];
                    unselectedAlignLines[coord].forEach(function (unselectedLine) {
                        var dif = unselectedLine[coord] - selectedLine[coord];
                        if (Math.abs(dif) < eps) {
                            deviations[coord][dif] = deviations[coord][dif] || [];
                            selectedLine[coord] += dif;
                            deviations[coord][dif] = deviations[coord][dif].concat([selectedLine, unselectedLine]);
                        }
                    });
                });
            });
        });
    });

    var minDeviation = {};
    ["x", "y"].forEach(function (coord) {
        minDeviation[coord] = Object.keys(deviations[coord]).map(function (key) {
            return Number(key);
        }).sort(function (a, b) {
            return Math.abs(b) - Math.abs(a);
        })[0];

        if (minDeviation[coord] !== undefined) {
            deviations[coord][minDeviation[coord]].forEach(function (line) {
                line.block.activateAlignPoint(line.align);
            });
        }
    });

    deltaPos.x += minDeviation.x || 0;
    deltaPos.y += minDeviation.y || 0;
};

EditorCardTable.prototype.cardHeld = function (card, maxDepth) {
    var q = new Set([card]);
    var depth = 0;
    while (q.size > 0) {
        var it = q.values();
        var p = it.next().value;
        q.delete(p);

        this.select(p);

        depth++;
        if (maxDepth !== undefined && depth >= maxDepth) {
            break;
        }

        var cardsToAdd = [];
        for (var i = this.cards.indexOf(p) - 1; i >= 0; i--) {
            if (q.has(this.cards[i])) {
                continue;
            }

            if (this.cards[i].overlaps(p)) {
                cardsToAdd.push(this.cards[i]);
            }
        }

        cardsToAdd.forEach(function (cardToAdd) {
            q.add(cardToAdd);
        });
    }

    return true;
};

EditorCardTable.prototype.existOpenCardByLocation = function (pos) {
    return this.cards.filter(function (card) {
        return card.x === pos.x && card.y === pos.y && card.isOpen();
    }).length > 0;
};

EditorCardTable.prototype.selectArea = function (areaNode) {
    this.clearSelection();
    var pos = this.cards[0].onGetView().parent.convertToNodeSpace(areaNode.parent.convertToWorldSpace(areaNode.getPosition()));
    var rect = cc.rect(pos.x, pos.y, areaNode.width, areaNode.height);

    this.cards.forEach(function (card) {
        if (cc.rectIntersectsRect(card.onGetView().getBoundingBox(), rect)) {
            this.select(card);
        }
    }.bind(this));
};

EditorCardTable.prototype.editorClicked = function (pos) {
    var center = cc.rectGetCenter(this.rect);
    pos = cc.pSub(pos, center);

    var orangery = Editor.currentEditor.orangery;

    if (!orangery.selected) {
        if (this.getSelectedCard()) {
            this.clearSelection();
        }
    } else {
        pos.x = Card.STICK_TO_GRID(pos.x, this.grid.step);
        pos.y = Card.STICK_TO_GRID(pos.y, this.grid.step);

        if (this.existOpenCardByLocation(pos)) {
            pos.x += Card.WIDTH / 2;
        }

        var command = this.createNewCardCommand(Object.assign({}, orangery.selected, pos));

        Editor.currentEditor.history.add(command);
    }
};

EditorCardTable.prototype.randomizeCards = function () {
    this.cards.forEach(function (card) {
        if (card.random) {
            card.value = undefined;
        }
    });

    if (cleverapps.config.type === "tile3") {
        Game.currentGame.generator.reset(this.cards, Editor.currentEditor.tileColorsEditor.getCurrentValue());
        this.cards.forEach(function (card) {
            if (card.random) {
                card.setRandomValue();
            }
        });
        TileTable.REPAINT(this.cards);
        this.cards.forEach(function (card) {
            card.trigger("changeValue");
        });
    }
};

EditorCardTable.prototype.insertCard = function (card, index) {
    if (index === undefined) {
        this.cards.push(card);
    } else {
        this.cards.splice(index, 0, card);
    }

    if (this.selectBlocksMode) {
        this.updateBlocks();
    }

    this.trigger("addEditor", card, index);

    Editor.currentEditor.save();
};

EditorCardTable.prototype.addNewCard = function (data) {
    var card;
    if (data.cardOptions || data.CardCtor) {
        var options = data.cardOptions || {};
        options.x = data.x;
        options.y = data.y;
        options.value = data.value || options.value;

        card = new (data.CardCtor || Card)(options);
        if (options.random) {
            card.value = undefined;
        }
    } else {
        card = TileFactory.Create(data);
    }

    card.magic = data.magic;
    card.open();
    card.setOwner(this);

    this.insertCard(card);

    if (!card.magic) {
        this.randomizeCards();
    }

    this.flipNormalize();

    Game.currentGame.counter.trigger();
    this.resort();
    Editor.currentEditor.save();

    return card;
};

EditorCardTable.prototype.putComponent = function (card, options) {
    if (options.cardOptions.marks) {
        var markType = options.cardOptions.marks[0];
        if (!card.marks) {
            card.marks = [];
        }

        var markIndex = -1;
        card.marks.forEach(function (mark, index) {
            if (mark === markType || (mark.type && mark.type === markType)) {
                markIndex = index;
            }
        });

        if (markIndex === -1) {
            card.marks.push(new Mark(markType));
            card.trigger("addMark", markType);
        } else {
            card.marks.splice(markIndex, 1);
            card.trigger("removeMark", markType);
        }
        return;
    }

    var ComponentClass = options.cardOptions.componentCtor;
    var component = card.findComponent(ComponentClass);
    if (component) {
        component.onDestroy();
        card.removeComponent(ComponentClass);
    } else {
        var newComponent = new ComponentClass(card, options.cardOptions.components[0]);
        card.components.push(newComponent);
        var ComponentViewClass = newComponent.getViewClass();
        card.onGetView().addChild(new ComponentViewClass(newComponent));
    }

    Editor.currentEditor.save();
};

EditorCardTable.prototype.createNewCardCommand = function (data) {
    var card;
    return {
        redo: function () {
            if (card) {
                this.insertCard(card);
            } else {
                card = this.addNewCard(data);
            }
            return card;
        }.bind(this),

        undo: function () {
            this.removeCardEditor(card);
        }.bind(this)
    };
};

EditorCardTable.prototype.resort = function () {
    TileTable.RESORT(this.cards);
    this.trigger("editorUpdateZ", this.cards);
};

EditorCardTable.prototype.removeCardEditor = function (card) {
    var index = this.cards.indexOf(card);
    this.cards.splice(index, 1);

    this.trigger("removeCardEditor", card);

    if (this.selected.has(card)) {
        this.selected.delete(card);
    }

    this.randomizeCards();

    this.flipNormalize();

    if (this.selectBlocksMode) {
        this.updateBlocks();
    }

    Editor.currentEditor.save();
};

EditorCardTable.prototype.removeCardsEditor = function () {
    var cards = this.selected.listSelected();
    this.clearSelection();

    var commands = cards.map(function (card) {
        var prevIndex = card.getZ();
        return {
            redo: function () {
                this.removeCardEditor(card);
            }.bind(this),
            undo: function () {
                this.insertCard(card, prevIndex);
            }.bind(this)
        };
    }, this);

    Editor.currentEditor.history.add({
        redo: function () {
            commands.forEach(function (command) {
                command.redo();
            });

            Game.currentGame.counter.trigger();
            Editor.currentEditor.save();
        },

        undo: function () {
            commands.forEach(function (command) {
                command.undo();
            });

            this.randomizeCards();
            this.flipNormalize();
            Editor.currentEditor.save();
        }.bind(this)
    });
};

EditorCardTable.prototype.clearSelection = function () {
    this.selected.forEach(function (card) {
        this.selectCard(card, false);
    }, this);
    this.selected.clear();

    if (this.selectBlocksMode) {
        this.blocks.forEach(function (block) {
            block.select(false);
        });
    }
};

EditorCardTable.prototype.selectAll = function () {
    this.clearSelection();
    this.cards.forEach(function (card) {
        this.selectCard(card, true);
        this.selected.add(card);
    }, this);

    if (this.selectBlocksMode) {
        this.blocks.forEach(function (block) {
            block.select(true);
        });
    }
};

EditorCardTable.prototype.showCardsByLayers = function () {
    this.cards.forEach(function (card) {
        card.trigger("setActive", Editor.currentEditor.isCardOnActiveLayer(card));
    });
};

EditorCardTable.prototype.editorRotateCardView = function (rotation) {
    var center = this.getSelectedCenter();
    var angle = rotation / 180 * Math.PI;
    var toRotate = this.selected.listSelected().concat(this.getBlocks(true));
    toRotate.forEach(function (node) {
        var x = node.x - center.x;
        var y = node.y - center.y;
        this.trigger("editorMove", [node], {
            x: x * Math.cos(angle) + y * Math.sin(angle) - x,
            y: -x * Math.sin(angle) + y * Math.cos(angle) - y,
            r: rotation
        });
    }.bind(this));
};

EditorCardTable.prototype.editorCardMove = function (pos) {
    var cards = this.selected.listSelected();
    if (this.selectBlocksMode) {
        this.alignBlocks(pos);
    }
    if (this.snapMode) {
        this.grid.clearCells();
        cards.forEach(function (card) {
            this.grid.setActive(true, card, pos);
        }.bind(this));
    }
    cards = cards.concat(this.getBlocks(true));
    this.trigger("editorMove", cards, pos);
};

EditorCardTable.prototype.editorCardMoveEnd = function (pos) {
    var cards = this.selected.listSelected().concat(this.getBlocks(true));

    if (this.selectBlocksMode) {
        this.alignBlocks(pos);
    }

    pos.x = Card.STICK_TO_GRID(pos.x, this.grid.step);
    pos.y = Card.STICK_TO_GRID(pos.y, this.grid.step);

    Editor.currentEditor.history.add({
        redo: function () {
            cards.forEach(function (card) {
                card.setPosition({
                    x: card.x + pos.x,
                    y: card.y + pos.y,
                    r: card.r + pos.r
                });
            });

            this.trigger("editorMoveEnd", cards);
            Game.currentGame.counter.trigger();
            Editor.currentEditor.save();
        }.bind(this),

        undo: Editor.currentEditor.restoreCards(cards)
    });
    if (!this.snapMode) {
        this.flipNormalize();
    }
};

EditorCardTable.prototype.editorRotateCard = function (rotation) {
    var cards = this.selected.listSelected().concat(this.getBlocks(true));

    Editor.currentEditor.history.add({
        redo: function () {
            var center = this.getSelectedCenter();
            var angle = rotation / 180 * Math.PI;
            cards.forEach(function (card) {
                var x = card.x - center.x;
                var y = card.y - center.y;
                card.setPosition({
                    x: card.x + x * Math.cos(angle) + y * Math.sin(angle) - x,
                    y: card.y + -x * Math.sin(angle) + y * Math.cos(angle) - y,
                    rotation: (card.getRotation() + rotation) % 360
                });
            });

            this.trigger("editorMoveEnd", cards);
            Game.currentGame.counter.trigger();
            Editor.currentEditor.save();
        }.bind(this),

        undo: Editor.currentEditor.restoreCards(cards)
    });
};

EditorCardTable.prototype.getSelectedCenter = function () {
    var rect = undefined;
    this.selected.forEach(function (card) {
        if (!rect) {
            rect = cc.rect(card.x, card.y, 0, 0);
        } else {
            rect = cc.rectUnion(rect, cc.rect(card.x, card.y, 0, 0));
        }
    });

    return rect && cc.rectGetCenter(rect);
};

EditorCardTable.prototype.listMagicCards = function () {
    return this.cards.filter(function (card) {
        return card.magic;
    });
};

EditorCardTable.prototype.removeMagicCards = function () {
    var manualCards = this.cards.filter(function (card) {
        return !card.magic;
    });

    this.listMagicCards().forEach(function (card) {
        this.trigger("removeCardEditor", card);
    }, this);

    this.cards = manualCards;

    Editor.currentEditor.save();
};

EditorCardTable.prototype.removeAllCards = function () {
    var cards = this.cards.slice();

    Editor.currentEditor.history.add({
        redo: function () {
            this.cards.forEach(function (card) {
                this.trigger("removeCardEditor", card);
            }.bind(this));
            this.cards = [];

            this.clearSelection();

            Editor.currentEditor.save();
        }.bind(this),

        undo: function () {
            cards.forEach(this.insertCard.bind(this));
        }.bind(this)
    });
};

EditorCardTable.prototype.flip = function (options) {
    var cards = this.selected.listSelected();

    Editor.currentEditor.history.add({
        redo: function () {
            var center = this.getSelectedCenter();
            cards.forEach(function (card) {
                card.setPosition({
                    x: options.x ? 2 * center.x - card.x : card.x,
                    y: options.y ? 2 * center.y - card.y : card.y,
                    rotation: -card.getRotation()
                });
            });

            this.trigger("editorMoveEnd", cards);

            Game.currentGame.counter.trigger();

            Editor.currentEditor.save();
        }.bind(this),

        undo: Editor.currentEditor.restoreCards(cards)
    });
};

EditorCardTable.prototype.getSelectedCard = function () {
    return this.selected.values().next().value;
};

EditorCardTable.prototype.changeOrder = function (moveUp) {
    var otherCards = moveUp ? this.cards : this.cards.reverse();
    for (var i = otherCards.length - 2; i >= 0; i--) {
        var current = otherCards[i];
        if (!this.selected.has(current)) {
            continue;
        }

        for (var j = i + 1; j < otherCards.length; j++) {
            var other = otherCards[j];
            if (this.selected.has(other)) {
                break;
            }

            otherCards[j - 1] = other;
            otherCards[j] = current;

            if (current.overlaps(other)) {
                break;
            }
        }
    }

    this.cards = moveUp ? otherCards : otherCards.reverse();

    this.cards.forEach(function (card) {
        card.trigger("updateZ");
    });

    this.flipNormalize();

    this.selected.forEach(function (card) {
        this.selectCard(card, true);
    }, this);

    Game.currentGame.counter.trigger();
    Editor.currentEditor.save();
};

EditorCardTable.prototype.selectCard = function (card, selected) {
    if (this.snapMode) {
        this.grid.setActive(selected, card);
    } else {
        card.trigger("editorColor", selected);
    }
};

EditorCardTable.CountCards = function (cards) {
    return cards.reduce(function (count, card) {
        if (card.stack) {
            return count + card.amount;
        }

        return count + 1;
    }, 0);
};

EditorCardTable.findAdjacentStacks = function (cards) {
    for (var i = 0; i < cards.length; i++) {
        for (var j = i + 1; j < cards.length; j++) {
            if (Math.abs(cards[i].x - cards[j].x) === 120 && cards[i].y === cards[j].y
                || Math.abs(cards[i].y - cards[j].y) === 120 && cards[i].x === cards[j].x) {
                var card1 = cards[i];
                var card2 = cards[j];
                var isStack1 = cards.filter(function (card) {
                    if (card.x === card1.x && Math.abs(card.y - card1.y) === 10) {
                        return true;
                    }
                    if (card.y === card1.y && Math.abs(card.x - card1.x) === 10) {
                        return true;
                    }
                    return false;
                }).length > 0;
                var isStack2 = cards.filter(function (card) {
                    if (card.x === card2.x && Math.abs(card.y - card2.y) === 10) {
                        return true;
                    }
                    if (card.y === card2.y && Math.abs(card.x - card2.x) === 10) {
                        return true;
                    }
                    return false;
                }).length > 0;

                if (isStack1 && isStack2) {
                    return true;
                }
            }
        }
    }
    return false;
};

EditorCardTable.COPY_MOVE_BY = {
    x: 60,
    y: 60
};

var SelectionSet = function () {
    this.set = new Set();
};

SelectionSet.prototype.listSelected = function () {
    return Array.from(this.set);
};

SelectionSet.prototype.add = function () {
    var res = this.set.add.apply(this.set, arguments);
    Editor.currentEditor.copyPaste.update();
    return res;
};

SelectionSet.prototype.delete = function () {
    var res = this.set.delete.apply(this.set, arguments);
    Editor.currentEditor.copyPaste.update();
    return res;
};

SelectionSet.prototype.clear = function () {
    var res = this.set.clear.apply(this.set, arguments);
    Editor.currentEditor.copyPaste.update();
    return res;
};

SelectionSet.prototype.has = function () {
    return this.set.has.apply(this.set, arguments);
};

SelectionSet.prototype.forEach = function () {
    return this.set.forEach.apply(this.set, arguments);
};

SelectionSet.prototype.values = function () {
    return this.set.values.apply(this.set, arguments);
};
