/**
 * Created by slava on 4/3/20
 */

var CardView = cc.Node.extend({
    ctor: function (card, options) {
        this._super();

        this.card = card;
        this.options = options || {};

        this.setContentSize2(cleverapps.styles.CardView);
        this.setAnchorPoint(0.5, 0.5);

        this.addControls();
        this.createAnimation();
        this.addChild(this.animation, -1);
        this.setCascadeColorEnabled(true);
        this.setCascadeOpacityEnabledRecursively(true);

        if (card.x !== undefined) {
            this.setRotation(card.getRotation());
        }

        this.markViews = this.card.marks.map(function (mark) {
            var markView = new MarkView(mark);
            markView.visible = false;
            if (mark.type !== "magnet") {
                markView.setRotation(-2 * this.getRotation());
            }
            markView.setLocalZOrder(10);
            this.addChild(markView);

            if (this.card.isOpen() || cleverapps.environment.isSceneWithPreview()) {
                markView.show(true);
            }
            return markView;
        }.bind(this));

        card.on("animateShowUp", this.screenShowUp.bind(this));
        card.on("hide", this.hide.bind(this));

        card.on("showTutorial", this.showTutorial.bind(this));
        card.on("hideTutorial", this.hideTutorial.bind(this));

        card.on("changeValue", this.onChangeValue.bind(this));

        card.on("flip", this.createListener(this.onFlip.bind(this)));
        card.on("wrongMove", this.wrongMove.bind(this));
        card.on("changeOwner", this.createListener(this.changeOwner.bind(this)));
        card.on("updateZ", function () {
            this.setLocalZOrder(card.getZ());
        }.bind(this));

        this.scale = this.card.getScale();

        card.onReturnToScreen = this.createListener(this.animateReturnToScreen.bind(this));
        card.on("moveToOpen", this.moveToOpen.bind(this));

        card.onGetView = this.createListener(function () {
            return this;
        }.bind(this));

        cleverapps.eventBus.on("changeFocus", function () {
            this.hideTutorial();
        }.bind(this));
    }
});

CardView.convertToViewPosition = function (p) {
    return {
        x: p.x * resolutionScale,
        y: p.y * resolutionScale
    };
};

CardView.createCardView = function (card) {
    var ViewClass = card.getViewClass();
    var cardView = new ViewClass(card);
    card.components.forEach(function (component) {
        var ComponentViewClass = component.getViewClass();
        cardView.addChild(new ComponentViewClass(component));
    });

    return cardView;
};

CardView.prototype.changeOwner = function (owner) {
    var ownerView = owner.getView();
    this.replaceParentSamePlace(ownerView, {
        keepScale: true
    });

    if (owner instanceof TileTable) {
        this.setLocalZOrder(this.card.getZ());
    } else if (owner instanceof OpenCards) {
        this.setLocalZOrder(owner.cards.length);
    } else {
        this.setLocalZOrder(0);
    }
};

CardView.prototype.addControls = function () {
    if (!cleverapps.environment.isAdministratorScene() && !cleverapps.environment.isEditorScene() && this.card.feature !== "streak") {
        if (this.onTouchHandler) {
            this.onTouchHandler.remove();
            this.onTouchHandler = undefined;
        }

        this.onTouchHandler = cleverapps.UI.onPressed(this, this.card.onClickListener.bind(this.card));
    }
};

CardView.prototype.wrongMove = function () {
    cleverapps.audio.playSound(bundles.game.urls.wrong_effect);
    this.runAction(new cc.Sequence(
        new cc.DelayTime(0.2 * Math.random()),
        new cc.RotateBy(0.08, -20),
        new cc.RotateBy(0.08, 40).easing(cc.easeInOut(0.5)),
        new cc.RotateBy(0.08, -40).easing(cc.easeInOut(0.5)),
        new cc.RotateBy(0.08, 20),
        new cc.RotateTo(0, this.card.getRotation())
    ));
};

CardView.prototype.getShuffleFinishPosition = function () {
    return this.card.getViewPosition().point;
};

CardView.prototype.onFlip = function (silent) {
    if (silent) {
        this.setIdleAnimation();
    } else {
        this.animation.setAnimation(0, this.card.isOpen() ? "open" : "close", false);
        this.animation.setCompleteListener(function () {
            this.animation.setCompleteListener();
            this.setIdleAnimation();
        }.bind(this));
    }
};

CardView.prototype.moveToOpen = function (duration, fromTable) {
    if (this.expldeAction) {
        return;
    }
    var finalScale = cleverapps.styles.OpenCardsView.slotSize / cleverapps.styles.CardView.width;

    if (this.moveToOpenAction && !this.moveToOpenAction.isDone()) {
        duration = this.moveToOpenAction._duration - this.moveToOpenAction._elapsed;
        this.stopAction(this.moveToOpenAction);
    }

    this.moveToOpenAction = this.runAction(
        cleverapps.UI.animateCard({
            cardView: this,
            position: this.card.getViewPosition(),
            duration: duration,
            overScaling: fromTable ? 1.25 : 1,
            scale: finalScale,
            midpoint: fromTable ? cleverapps.styles.CardView.moveMidpoint : false,
            debugLabel: this.card.owner && this.card.owner.debugLabel
        })
    );
};

CardView.prototype.animateReturnToScreen = function (index) {
    var tableView = cleverapps.scenes.getRunningScene().cardTableView;
    var newScale = tableView.newScale || tableView.scale;

    var calcReturnPosition = function () {
        var position = this.card.getViewPosition();
        var newOffset = tableView.animateOffset || cc.p(0, 0);
        var scale = tableView.scale;
        var centerNodeOffset = tableView.centerNodeOffset;

        if (newScale !== tableView.scale) {
            position.point.y *= newScale / scale;
            position.point.x *= newScale / scale;
            position.point.y += ((centerNodeOffset.y * scale) - (centerNodeOffset.y * newScale)) / scale;
            position.point.x += ((centerNodeOffset.x * scale) - (centerNodeOffset.x * newScale)) / scale;
        }

        position.point.y += newOffset.y;
        position.point.x += newOffset.x;

        return position;
    }.bind(this);

    if (cleverapps.config.type === "tile3") {
        var starparticles = new cleverapps.Spine(bundles.card.jsons.starparticles_json);
        this.addChild(starparticles);
        starparticles.setLocalZOrder(-1);
        var animationName = "center";
        starparticles.setPositionRound(this.width / 2, 0);
        var position = calcReturnPosition();
        if (position.point.x > this.x && Math.abs(position.point.x - this.x) > cleverapps.styles.CardView.directMoveDx) {
            animationName = "left";
        }
        if (position.point.x < this.x && Math.abs(this.x - position.point.x) > cleverapps.styles.CardView.directMoveDx) {
            animationName = "right";
        }
        starparticles.setAnimation(0, animationName, false);
        starparticles.setCompleteListenerRemove();
    }

    this.stopAllActions();
    this.runAction(
        new cc.Spawn(
            new cc.RotateTo(Card.RETURN_TIMEOUT, this.card.r),
            cleverapps.UI.animateCard({
                cardView: this,
                dynamicRatioOfParentScale: newScale / tableView.scale,
                position: calcReturnPosition,
                duration: Card.RETURN_TIMEOUT,
                midpoint: cleverapps.styles.CardView.moveMidpoint,
                delay: index ? index * 0.03 : false,
                test: true
            })
        )
    );
};

CardView.prototype.hide = function () {
    this.visible = false;
};

CardView.prototype.createAnimation = function () {
    this.animation = new cleverapps.Spine(this.options.animation_json || bundles.card.jsons.card_json);
    this.onChangeValue();
    this.setIdleAnimation();
    this.animation.setPosition(this.width / 2, this.height / 2);
    this.fitAnimationScale();
};

CardView.prototype.fitAnimationScale = function () {
    var scale = Card.WIDTH * resolutionScale / this.animation.width;
    this.animation.setScale(scale);
};

CardView.prototype.setIdleAnimation = function () {
    this.animation.setAnimation(0, this.card.isOpen() ? "idle_open" : "idle_close", true);
};

CardView.prototype.onChangeValue = function () {
    this.animation.setSkin(this.card.getSkin());
};

CardView.prototype.showTutorial = function () {
    this.finger = FingerView.hintClick(this, {
        repeatDelay: 10
    });

    if (!this.pointer) {
        var pointer = PointerView.create({
            target: this
        });
        pointer.setVisible(false);
        pointer.runAction(new cc.Sequence(
            new cc.DelayTime(5),
            new cc.Show()
        ));
        this.pointer = pointer;
    }
};

CardView.prototype.hideTutorial = function () {
    FingerView.remove(this.finger);
    PointerView.remove(this.pointer);
    this.pointer = undefined;
    this.finger = undefined;
};

CardView.prototype.screenShowUp = function (f, silent) {
    if (silent) {
        this.visible = true;
        f();
        return;
    }

    var scene = cleverapps.scenes.getRunningScene();
    var finalPosition = this.getPosition();
    var finalRotation = this.getRotation();
    var finalScale = this.getScale();
    var finalZOrder = this.getLocalZOrder();

    var cardOffset = this.height / Math.sin(cleverapps.styles.CardView.screenShowUp.rotation * Math.PI / 180);
    var position;

    if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
        position = cc.p(scene.width / 2, scene.height + cardOffset);
    } else {
        position = cc.p(-cardOffset, scene.height / 2);
    }

    this.setPositionRound(this.parent.convertToNodeSpace(scene.convertToWorldSpace(position)));
    this.setRotation(finalPosition.x > 0 ? cleverapps.styles.CardView.screenShowUp.rotation : -cleverapps.styles.CardView.screenShowUp.rotation);
    this.setLocalZOrder(100);

    this.visible = true;
    this.runAction(
        new cc.Sequence(
            new cc.Spawn(
                new cc.Sequence(
                    new cc.DelayTime(0.3),
                    new cc.ScaleTo(0.3, finalScale * 1.2).easing(cc.easeIn(1))
                ),
                new cc.MoveTo(0.8, finalPosition).easing(cc.easeInOut(2)),
                new cc.RotateTo(0.8, finalRotation),
                new cc.Sequence(
                    new cc.DelayTime(0.6),
                    new cc.ScaleTo(0.4, finalScale).easing(cc.easeOut(1))
                )
            ),
            new cc.CallFunc(function () {
                this.setPositionRound(finalPosition);
                this.setLocalZOrder(finalZOrder);
                f();
            }.bind(this))
        )
    );
};

CardView.prototype.animateLose = function (index) {
    var loseAnimation = undefined;
    if (this.card.isOpen()) {
        loseAnimation = index % 2 === 0 ? "lose_left_nominal" : "lose_right_nominal";
    } else {
        loseAnimation = index % 2 === 0 ? "lose_left_backside" : "lose_right_backside";
    }

    this.replaceParentSamePlace(cleverapps.scenes.getMovingNode(this), {
        keepScale: true
    });

    var timeScale = 0.8 + Math.random() * 0.9;
    this.animation.setTimeScale(timeScale);
    var animationTime = this.animation.getAnimationDuration(loseAnimation) / timeScale;

    var delayTime = 0.01 * index * timeScale;
    var flyTime = 2 / timeScale;
    var rotateTime = 0.1;
    var cardBox = this.getGlobalBoundingBox();
    var flyOnSceneTime = flyTime * ((cardBox.y + cardBox.height) / cleverapps.resolution.getBgSize().height);

    this.setCascadeOpacityEnabled(true);
    this.runAction(new cc.Sequence(
        new cc.DelayTime(delayTime),
        new cc.CallFunc(function () {
            this.animation.setAnimation(0, loseAnimation, false);
        }.bind(this)),
        new cc.Spawn(
            new cc.RotateTo(rotateTime, 0),
            new cc.MoveBy(flyTime, 0, -cleverapps.resolution.getBgSize().height).easing(cc.easeIn(4))
        ),
        new cc.RemoveSelf()
    ));

    this.runAction(new cc.Sequence(
        new cc.DelayTime(0.05 * index),
        new cc.PlaySound(bundles.game.urls.card_effect)
    ));

    return Math.max(delayTime + flyOnSceneTime, animationTime);
};

CardView.prototype.unlockSpawnAnimate = function (isFirst) {
    var styles = cleverapps.styles.TilesUnlockWindow;

    this.animation.setOpacity(0);
    this.icon.setOpacity(0);

    this.setScale(0.2);
    this.setPositionRound(styles.animation);

    var offsetX = isFirst ? -styles.tiles.offset : styles.tiles.offset;

    return new cc.Sequence(
        new cc.DelayTime(isFirst ? 0.2 : 0.4),
        new cc.Spawn(
            new cc.ScaleTo(1.5, styles.tiles.scale),
            new cc.CallFunc(function () {
                this.animation.runAction(new cc.FadeIn(0.5));
                this.icon.runAction(new cc.FadeIn(0.5));
            }.bind(this)),
            new cc.RotateBy(1.5, isFirst ? -360 : 360),
            new cc.MoveBy(1.5, offsetX, 3 * styles.height / 5)
        ).easing(cc.easeElasticOut(1.2))
    );
};

CardView.prototype.unlockEndAnimate = function (callback, isFirst) {
    this.replaceParentSamePlace(cleverapps.scenes.getRunningScene());
    this.setLocalZOrder(BaseWindow.WINDOWS_ZORDER + 3);

    var angle = isFirst ? -200 : 200;
    this.runAction(new cc.Sequence(
        new cc.DelayTime(isFirst ? 0.2 : 0),
        new cc.Spawn(
            new cc.MoveBy(1.5, 0, cleverapps.resolution.getSceneSize().height).easing(cc.easeElasticIn(0.6)),
            new cc.RotateBy(1.5, angle).easing(cc.easeIn(5))
        ),
        new cc.CallFunc(callback),
        new cc.RemoveSelf()
    ));
};

cleverapps.styles.CardView = {
    width: 0,
    height: 0,

    moveMidpoint: {
        x: 50,
        y: 250
    },

    screenShowUp: {
        rotation: 30
    },

    showUpOffset: 0,
    shuffleRadius: 250,
    directMoveDx: 150
};

cleverapps.styles.CardView.width = Card.WIDTH;
cleverapps.styles.CardView.height = Card.HEIGHT;
