/**
 * Created by andrey on 22.08.2020.
 */

var DoubleCardView = CardView.extend({
    ctor: function (card) {
        this._super(card, {
            animation_json: bundles.card.jsons.double_card_json
        });
    },

    setIdleAnimation: function () {
        this.animation.setAnimation(0, this.card.isOpen() ? "idle_open_double" : "idle_close", true);
    },

    onChangeValue: function () {
        this.animation.setSkin(this.card.getSkin());
    }
});