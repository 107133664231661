/**
 * Created by Andrey Popov on 12.05.20
 */

var FillHandCardView = CardView.extend({
    ctor: function (card) {
        this._super(card, {
            animation_json: bundles.card.jsons.fillhand_json
        });

        card.on("start", this.start.bind(this));
        card.on("spawn", this.spawn.bind(this));
        card.on("finish", this.finish.bind(this));
    },

    onFlip: function () {
    }
});

FillHandCardView.prototype.setIdleAnimation = function () {
    this.animation.setAnimation(0, "idle_close", true);
};

FillHandCardView.prototype.start = function () {
    this.replaceParentSamePlace(cleverapps.scenes.getMovingNode());
    this.animation.setAnimation(0, "fillhand", false);

    this.runAction(new cc.Spawn(
        new cc.ScaleTo(FillHandCardView.GET_UP_TIME, 1),
        new cc.RotateTo(FillHandCardView.GET_UP_TIME, 0)
    ));
};

FillHandCardView.prototype.finish = function () {
    this.animation.setAnimation(0, "fillhand_out", false);

    this.runAction(
        new cc.Sequence(
            new cc.DelayTime(0.7),
            new cc.RemoveSelf()
        )
    );
};

FillHandCardView.prototype.spawn = function (card) {
    var styles = cleverapps.styles.FillHandCardView;
    var ViewClass = card.getViewClass();
    var view = new ViewClass(card);
    view.setScale(styles.scale);
    view.setRotation(styles.rotation);
    view.setPositionRound(styles.position);
    this.setLocalZOrder(-1);
    this.addChild(view);

    cleverapps.audio.playSound(bundles.game.urls.plus_card_effect);

    if (this.card.amount > 0) {
        this.card.trigger("changeValue");
    }
};

FillHandCardView.GET_UP_TIME = 0.9;

cleverapps.styles.FillHandCardView = {
    position: {
        x: 50,
        y: 50
    },
    scale: 0.3,
    rotation: -180
};