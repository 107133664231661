/**
 * Created by Andrey Popov on 12/28/22.
 */

var CardsCounter = function () {
    this.onRefresh = function () {};

    this.update();
};

CardsCounter.prototype.update = function () {
    this.countOfCards = Game.currentGame.table.getAmountOfCards();

    this.isValid = (this.countOfCards % 3) === 0;

    if (!this.isValid) {
        this.lowValidValue = Math.floor(this.countOfCards / 3) * 3;
        this.upValidValue = Math.ceil(this.countOfCards / 3) * 3;
    }

    this.existsStackFromCards = false;

    for (var i = 0; i < Game.currentGame.level.content.screens.length; i++) {
        var screen = Game.currentGame.level.content.screens[i];
        var cards = screen.cards.concat(screen.magicH).concat(screen.magicV);
        this.existsStackFromCards = this.existsStackFromCards || EditorCardTable.findAdjacentStacks(cards);
    }

    this.onRefresh();
};