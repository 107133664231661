/**
 * Created by mac on 6/30/20
 */

var RapidTimeout = function () {
    this.line = [];

    this.run();
};

RapidTimeout.prototype.run = function () {
    this.runInterval = cleverapps.timeouts.setInterval(function () {
        if (this.line.length > 0) {
            var next = this.next();
            console.log("Found: ", next);
            next();
        }
    }.bind(this), 1);
};

RapidTimeout.prototype.stop = function () {
    cleverapps.timeouts.clearInterval(this.runInterval);
};

RapidTimeout.prototype.setTimeout = function (callback, timeout) {
    console.log("added " + timeout, callback);
    this.line.push({
        callback: callback,
        timeout: timeout
    });
};

RapidTimeout.prototype.next = function () {
    var min = 0;
    for (var i = 1; i < this.line.length; i++) {
        if (this.line[i].timeout < this.line[min].timeout) {
            min = i;
        }
    }

    var callback = this.line[min].callback;

    this.line.splice(i, 1);

    return callback;
};