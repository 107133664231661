/**
 * Created by Andrey Popov on 12.05.20
 */

Card.SUIT_HEARTS = "h";
Card.SUIT_CLUBS = "c";
Card.SUIT_DIAMONDS = "d";
Card.SUIT_SPADES = "s";

Card.SUITS = [Card.SUIT_HEARTS, Card.SUIT_CLUBS, Card.SUIT_DIAMONDS, Card.SUIT_SPADES];
Card.VALUES = ["2", "3", "4", "5", "6", "7", "8", "9", "t", "j", "q", "k", "a"];

Card.prototype._canPlay = Card.prototype.canPlay;
Card.prototype.canPlay = function (openedCard) {
    if (this._canPlay.apply(this, arguments)) {
        return this.isNext(openedCard);
    }
    return false;
};

Card.prototype.getSkin = function () {
    if (cleverapps.config.editorMode && this.value === undefined) {
        return this.isOpen() ? "unknown" : "backside_unknown";
    }

    if (!this.isOpen() && !cleverapps.environment.isEditorScene()) {
        return "backside";
    }

    return this.value;
};

Card.prototype.isEqualSuit = function (otherCard) {
    return this.getSuit() === otherCard.getSuit()
        || (otherCard.suits && otherCard.suits.indexOf(this.getSuit()) !== -1);
};

Card.prototype.isNext = function (otherCard) {
    if (otherCard instanceof Wildcard) {
        return true;
    }

    if (otherCard instanceof SuitCard) {
        return this.isEqualSuit(otherCard);
    }

    var values = this.values || [this.value];
    var otherValues = otherCard.values || [otherCard.value];

    for (var i = 0; i < values.length; i++) {
        for (var j = 0; j < otherValues.length; j++) {
            var value1 = Card.VALUES.indexOf(values[i].charAt(0).toLowerCase());
            var value2 = Card.VALUES.indexOf(otherValues[j].charAt(0).toLowerCase());

            var diff = Math.abs(value1 - value2);
            if (diff === 1 || diff === Card.VALUES.length - 1) {
                return true;
            }
        }
    }

    return false;
};

Card.prototype.getRank = function () {
    return this.value.charAt(0);
};

Card.prototype.getSuit = function () {
    return this.value.charAt(1);
};

Card.prototype.setRank = function (rank) {
    if (this.value === undefined) {
        this.value = rank + "x";
    } else {
        this.value = rank + this.value.charAt(1);
    }
};

Card.prototype.setSuit = function (suit) {
    if (this.value === undefined) {
        this.value = "x" + suit;
    } else {
        this.value = this.value.charAt(0) + suit;
    }
};

Card.prototype.getMoveToOpenAnimationTime = function () {
};

Card.prototype.getCardValue = function () {
    return Card.VALUES.indexOf(this.getRank());
};

Card.WIDTH = 112;
Card.HEIGHT = 160;
Card.GRID_XY = 8;
Card.GRID_ANGLE = 5;

Card.PLAY_TIMEOUT = 0.74;
Card.ANIMATE_LEFT_DURATION = 0.8;
Card.HAND_RETURN_TIMEOUT = 0.5;
Card.SHOW_UP_TIMEOUT = 0.5;
