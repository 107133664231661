/**
 * Created by andrey on 01.07.2020
 */

var ExtraHand = function (game, hand) {
    cleverapps.EventEmitter.call(this);

    this.game = game;
    this.hand = hand;
    this.game.prolongation.on("adsCanceled", this.updateOffer.bind(this), this);

    this.setEnable(!(this.game.level.episodeNo === 0 && this.game.level.levelNo < 1));
};

ExtraHand.prototype = Object.create(cleverapps.EventEmitter.prototype);
ExtraHand.prototype.constructor = ExtraHand;

ExtraHand.prototype.update = function () {
    if (cleverapps.config.type === "klondike") {
        return;
    }

    this.visible = this.hand.isEmpty() && this.enabled;

    if (!this.runningForce) {
        this.offer = this.game.prolongation.getOffer(Prolongation.TYPES.MOVES, !this.isForceAvailable());
        this.trigger("update");
    }
};

ExtraHand.prototype.updateOffer = function () {
    if (this.runningForce) {
        return;
    }
    this.offer = this.game.prolongation.getOffer(Prolongation.TYPES.MOVES, !this.isForceAvailable());
    this.hide();
    this.show();
};

ExtraHand.prototype.show = function () {
    this.visible = true;

    this.trigger("update");
};

ExtraHand.prototype.hide = function () {
    this.visible = false;

    this.trigger("update");
};

ExtraHand.prototype.onCardsClicked = function () {
    if (this.game.counter.isActive()) {
        return;
    }
    this.game.prolongation.acceptOffer(this.offer);

    if (cleverapps.forces.isRunningForce()) {
        cleverapps.forces.closeRunningForce();
    }
};

ExtraHand.prototype.showTutorialStep = function () {
    if (!cleverapps.environment.isSceneWithPreview() && this.offer.priceType === "free") {
        this.showForce();
        return true;
    }
};

ExtraHand.prototype.showForce = function () {
    cleverapps.meta.display({
        focus: "AddMovesForce",
        control: ["opencards"],
        actions: [
            function (f) {
                this.runningForce = true;
                this.update();

                setTimeout(f, 250);
            }.bind(this),

            function (f) {
                this.trigger("showForce");
                cleverapps.forces.onceForceClosed = f;
            }.bind(this),

            function (f) {
                setTimeout(f, 500);
            },

            function (f) {
                this.runningForce = false;
                this.update();

                setTimeout(f, 100);
            }.bind(this)
        ]
    });
};

ExtraHand.prototype.isForceAvailable = function () {
    if (!this.visible || this.game.cardsTutorial && this.game.cardsTutorial.card || this.game.outcome !== GameBase.OUTCOME_UNKNOWN) {
        return false;
    }

    if (this.game.level.episodeNo === 0 && this.game.level.levelNo <= 1) {
        return false;
    }

    return true;
};

ExtraHand.prototype.setEnable = function (enabled) {
    this.enabled = enabled;
};
