/**
 * Created by vladislav on 07.06.2021
 */

var SafeCardView = CardView.extend({
    ctor: function (card) {
        this._super(card, {
            animation_json: bundles.card.jsons.safe_card_json
        });

        var styles = cleverapps.styles.SafeCardView;
        this.setContentSize2(styles);

        this.animation.setPositionRound(styles.animation);
        this.animation.setAnimation(0, "idle_close_suit_value", true);

        this.card.on("openSuit", this.createListener(this.openSuit.bind(this)));
        this.card.on("openValue", this.createListener(this.openValue.bind(this)));
        this.card.on("openValueAndSuit", this.createListener(this.openValueAndSuit.bind(this)));
        this.card.on("openSafe", this.createListener(this.openSafe.bind(this)));
        this.card.on("spawn", this.createListener(this.spawn.bind(this)));

        if (!cleverapps.environment.isAdministratorScene() && !cleverapps.environment.isEditorScene()) {
            cleverapps.UI.onPressed(this, card.onClickListener.bind(card, card));
            cleverapps.UI.applyHover(this);
        }

        if (cleverapps.environment.isEditorScene()) {
            this.addEditorButtons();
        }
    },

    setIdleAnimation: function () {
        var idleAnimation = "idle_close_suit_value";
        if (this.card.suitOpened) {
            idleAnimation = "idle_value";
        }
        if (this.card.suitOpened) {
            idleAnimation = "idle_suit";
        }
        this.animation.setAnimation(0, idleAnimation, true);
    },

    fitAnimationScale: function () {
        var scale = SafeCard.WIDTH * resolutionScale / this.animation.width;
        this.animation.setScale(scale);
    },

    spawn: function (card) {
        var styles = cleverapps.styles.SafeCardView;

        var ViewClass = card.getViewClass();
        var view = new ViewClass(card);
        this.addChild(view);
        view.setPositionRound(styles.wildcard);
    },

    openSuit: function (card) {
        this.runCardAnimation(card, function () {
            if (this.card.valueOpened) {
                this.animation.setAnimation(0, "open_value_suit", false);
            } else {
                this.animation.setAnimationAndIdleAfter("open_suit", "idle_suit");
            }
        }.bind(this));
    },

    openValue: function (card) {
        this.runCardAnimation(card, function () {
            if (this.card.suitOpened) {
                this.animation.setAnimation(0, "open_suit_value", false);
            } else {
                this.animation.setAnimationAndIdleAfter("open_value", "idle_value");
            }
        }.bind(this));
    },

    openValueAndSuit: function (card) {
        this.runCardAnimation(card, function () {
            this.animation.setAnimation(0, "open_both", false);
        }.bind(this));
    },

    runCardAnimation: function (card, callback) {
        var scene = cleverapps.scenes.getRunningScene();
        var cardView = card.onGetView();
        cardView.replaceParentSamePlace(cleverapps.scenes.getMovingNode());

        var position = cleverapps.scenes.getMovingNode().convertToNodeSpace(this.convertToWorldSpace(cc.p(this.width / 2, this.height / 2)));
        var offScenePosition = cleverapps.scenes.getMovingNode().convertToNodeSpace(cc.p(scene.width / 2, scene.height + cardView.height));
        cardView.runAction(new cc.Sequence(
            new cc.PlaySound(bundles.game.urls.card_to_safe_effect),
            new cc.MoveTo(SafeCard.FLY_TIME / 1000, position).easing(cc.easeIn(2)),
            new cc.CallFunc(callback),
            new cc.Spawn(
                new cc.RotateBy(0.4, 360),
                new cc.MoveTo(0.4, offScenePosition)
            ),
            new cc.RemoveSelf()
        ));
    },

    openSafe: function () {
        this.animation.setAnimation(0, "safe_open", false);
        cleverapps.audio.playSound(bundles.game.urls.open_safe_effect);
        this.animation.setCompleteListener(function () {
            this.animation.setAnimation(0, "safe_close", false);
            this.animation.setCompleteListener(function () {
                this.runAction(new cc.Sequence(
                    new cc.ScaleTo(0.3, 0),
                    new cc.RemoveSelf()
                ));
            }.bind(this));
        }.bind(this));
    },

    onFlip: function () {
    }
});

if (cleverapps.config.debugMode) {
    SafeCardView.prototype.addEditorButtons = function () {
        var styles = cleverapps.styles.SafeCardView;

        var buttons = [
            function () {
                this.card.requiredValue = (this.card.requiredValue + 1) % Card.VALUES.length;
                this.animation.setSkin(Card.VALUES[this.card.requiredValue] + "_" + this.card.requiredSuit);
                Editor.currentEditor.save();
            }.bind(this),
            function () {
                this.card.requiredSuit = Card.SUITS[(Card.SUITS.indexOf(this.card.requiredSuit) + 1) % Card.SUITS.length];
                this.animation.setSkin(Card.VALUES[this.card.requiredValue] + "_" + this.card.requiredSuit);
                Editor.currentEditor.save();
            }.bind(this)
        ].map(function (func) {
            var button = new cc.Node();
            button.setAnchorPoint(0.5, 0.5);
            button.setContentSize2(styles.editorButtons);
            cleverapps.UI.onClick(button, func);
            return button;
        });

        var layout = new cleverapps.Layout(buttons, {
            margin: styles.editorButtons.margin,
            direction: cleverapps.UI.HORIZONTAL
        });
        this.addChild(layout);
        layout.setPositionRound(styles.editorButtons);
        layout.setLocalZOrder(10);
    };
}

cleverapps.styles.SafeCardView = {
    width: 300,
    height: 300,

    animation: {
        x: { align: "center", dx: -10 },
        y: { align: "center", dy: -10 }
    },

    wildcard: {
        x: { align: "center", dx: -10 },
        y: { align: "center", dy: 9 }
    },

    editorButtons: {
        width: 60,
        height: 120,
        margin: 15,
        x: { align: "center" },
        y: { align: "center", dy: -5 }
    }
};