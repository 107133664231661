/**
 * Created by Andrey Popov on 14.05.20
 */

var IncDecCard = function (options) {
    Card.call(this, options);
    this.dir = options.dir;

    this.featureTrigger = Card.MOVES.PLAY;

    this.feature = "incdec";
};

IncDecCard.prototype = Object.create(Card.prototype);
IncDecCard.constructor = IncDecCard;

IncDecCard.prototype.getViewClass = function () {
    return IncDecCardView;
};

IncDecCard.prototype.toJSON = function () { 
    return Object.assign(Card.prototype.toJSON.call(this), {
        dir: this.dir
    });
};

IncDecCard.prototype.flip = function () {
    this.canFeatureProcess = false;
    Card.prototype.flip.call(this);
};

IncDecCard.prototype.danceMove = function () {
    if (!this.canFeatureProcess) {
        return;
    }
    this.canFeatureProcess = false;

    this.change(this.dir);
    for (var i = 0; i < this.components.length; i++) {
        if (this.components[i].danceMove) {
            this.components[i].danceMove();
        }
    }
};

IncDecCard.prototype.change = function (diff) {
    var value = this.getCardValue();
    value += diff;

    if (value >= Card.VALUES.length) {
        value = 0;
    }

    if (value < 0) {
        value = Card.VALUES.length - 1;
    }

    this.setRank(Card.VALUES[value]);

    this.trigger("changeRank");
};

IncDecCard.prototype.canBeReplacedWithBoosters = function () {
    return false;
};