/**
 * Created by mac on 12/29/22
 */

var MagicStacks = function (table) {
    this.table = table;

    this.value = this.table.listMagicCards().length;

    this.findCanvas();

    MagicStacks._CACHED = {};

    this.onRefreshListener = function () {};

    var interval = cleverapps.timeouts.setInterval(this.updateCanvas.bind(this), 1000);
    Game.currentGame.on("stop", function () {
        cleverapps.timeouts.clearInterval(interval);
    });

    Game.currentGame.table.on("editorChangeOrientation", this.onChangeOrientation.bind(this));
};

MagicStacks.prototype.updateCanvas = function () {
    this.findCanvas();

    if (!cc.rectEqualToRect(this.canvas, this.lastCanvas)) {
        this.lastCanvas = this.canvas;
        Game.currentGame.table.trigger("drawMagicArea", this.canvas);
    }
};

MagicStacks.prototype.onChangeOrientation = function () {
    MagicStacks._CACHED = {};

    this.updateCanvas();
};

MagicStacks.prototype.refresh = function () {
    this.value = this.table.listMagicCards().length;
    this.onRefreshListener();
};

MagicStacks.prototype.findCanvas = function () {
    if (Game.currentGame.table.getOrientation() === TileTable.ORIENTATION_HORIZONTAL) {
        var maxx = -Infinity;
        this.table.cards.forEach(function (card) {
            if (!card.magic && card.x + Card.WIDTH / 2 > maxx) {
                maxx = card.x + Card.WIDTH / 2;
            }
        });

        var width = this.table.canvas.width / 2 - maxx;
        var height = this.table.canvas.height;

        this.canvas = {
            x: this.table.canvas.width - width,
            y: 0,
            width: width,
            height: this.table.canvas.height
        };
    } else {
        var miny = Infinity;
        this.table.cards.forEach(function (card) {
            if (!card.magic && card.y - Card.HEIGHT / 2 < miny) {
                miny = card.y - Card.HEIGHT / 2;
            }
        });

        width = this.table.canvas.width;
        height = this.table.canvas.height / 2 + miny;

        this.canvas = {
            x: 0,
            y: 0,
            width: width,
            height: height
        };
    }
};

MagicStacks.prototype.validateType = function (type, option) {
    if (type === 5) {
        for (var i = 0; i < option.numbers.length; i++) {
            if ([1, 2, 3].indexOf(option.numbers[i]) !== -1) {
                return false;
            }
        }
    }
    if (type === 0 || type === 1 || type === 2 || type === 3) {
        for (i = 0; i < option.numbers.length; i++) {
            if ([1, 2].indexOf(option.numbers[i]) !== -1) {
                return false;
            }
        }
    }

    if (Game.currentGame.table.getOrientation() === TileTable.ORIENTATION_HORIZONTAL) {
        if (type === 0 || type === 1 || type === 4) {
            return false;
        }
    } else if (type === 2 || type === 3) {
        return false;
    }

    if (type === 4) {
        for (i = 0; i < option.numbers.length; i++) {
            if ([6, 7, 8].indexOf(option.numbers[i]) !== -1) {
                return false;
            }
        }
    }

    return true;
};

MagicStacks.prototype.regenerate = function () {
    this.updateCanvas();

    var currentAmount = Game.currentGame.table.getAmountOfCards();

    var need = 3 - currentAmount % 3;
    if (need === 3) {
        need = 0;
    }

    this.setValue(this.value + need);
    this.refresh();
};

MagicStacks.prototype.setValue = function (value) {
    this.value = value;

    var table = Game.currentGame.table;
    table.removeMagicCards();

    var res = this.calculate(value);

    if (res) {
        res.forEach(function (data) {
            data.magic = true;
            table.addNewCard(data);
        });
    }
};

MagicStacks.prototype.calculate = function (n) {
    var options = MagicStacks.CONFIGS.map(function (cfg) {
        return MagicStacks.BRUTE_FORCE_NUMBERS(cfg, n);
    }).flat();

    if (options.length === 0) {
        console.log("No possible a b for " + n);
        return undefined;
    }

    var stackTypes = [];
    for (var i = 0; i < MagicStacks.STACKS.length; i++) {
        stackTypes.push(i);
    }

    var fitted = stackTypes.map(function (stackType) {
        return options.map(function (option) {
            if (!this.validateType(stackType, option)) {
                return undefined;
            }
            return this.fitToCanvas(option, stackType);
        }, this).filter(function (fitted) {
            return fitted !== undefined;
        });
    }, this).flat();

    if (fitted.length === 0) {
        console.log("No stacks for " + n);
        return undefined;
    }
    return cleverapps.Random.choose(fitted).map(this.relativeToTableCenter.bind(this));
};

MagicStacks.prototype.relativeToTableCenter = function (pos) {
    var center = cc.rectGetCenter(this.table.rect);
    return {
        x: this.canvas.x - center.x + pos.x,
        y: pos.y - center.y
    };
};

MagicStacks.MAX_STACK = 12;

MagicStacks.LETTER_TO_NUM = function (letter, option) {
    if (letter === " ") {
        return 0;
    }

    var n = letter.charCodeAt(0) - "a".charCodeAt(0);
    return option.a + n * option.d;
};

MagicStacks.IMAGINE_STACK_NODE = function (n, stackType) {
    if (n === 0) {
        return {
            node: {
                x: 0,
                y: 0
            },
            cards: []
        };
    }

    var cacheKey = 100 * stackType + n;
    if (MagicStacks._CACHED[cacheKey] !== undefined) {
        return MagicStacks._CACHED[cacheKey];
    }

    var cards = MagicStacks.STACKS[stackType](n);

    var minx = Infinity, maxx = -Infinity;
    var miny = Infinity, maxy = -Infinity;
    cards.forEach(function (card) {
        if (card.x - Card.WIDTH / 2 < minx) {
            minx = card.x - Card.WIDTH / 2;
        }
        if (card.x + Card.WIDTH / 2 > maxx) {
            maxx = card.x + Card.WIDTH / 2;
        }

        if (card.y - Card.HEIGHT / 2 < miny) {
            miny = card.y - Card.HEIGHT / 2;
        }
        if (card.y + Card.HEIGHT / 2 > maxy) {
            maxy = card.y + Card.HEIGHT / 2;
        }
    });

    var padding = MagicStacks.PADDING[stackType] || 0;

    if (Game.currentGame.table.getOrientation() === TileTable.ORIENTATION_HORIZONTAL) {
        miny -= padding;
        maxy += padding;
    } else {
        minx -= padding;
        maxx += padding;
    }

    var node = {
        x: maxx - minx,
        y: maxy - miny
    };

    MagicStacks._CACHED[cacheKey] = {
        node: node,
        cards: cards.map(function (card) {
            return {
                x: (card.x - minx) - node.x / 2,
                y: (card.y - miny) - node.y / 2
            };
        })
    };

    return MagicStacks._CACHED[cacheKey];
};

MagicStacks.prototype.fitRow = function (rowOption, length, stackType) {
    var isHorizontal = Game.currentGame.table.getOrientation() === TileTable.ORIENTATION_HORIZONTAL;

    var maxSize = 0;
    var bricksLength = 0;

    var prev = 0;
    var stacks = rowOption.cfg.split("").map(function (letter) {
        var stackN = MagicStacks.LETTER_TO_NUM(letter, rowOption);
        var s = MagicStacks.IMAGINE_STACK_NODE(stackN, stackType);

        if (isHorizontal) {
            if (s.node.x > maxSize) {
                maxSize = s.node.x;
            }

            if (s.node.y > 0) {
                prev = s.node.y;
            }
            bricksLength += s.node.y || prev;
        } else {
            if (s.node.y > maxSize) {
                maxSize = s.node.y;
            }

            if (s.node.x > 0) {
                prev = s.node.x;
            }
            bricksLength += s.node.x || prev;
        }

        return s;
    });

    var r = length - bricksLength;
    if (r < 0) {
        return undefined;
    }

    var d = r / 2;

    var t = d;
    var cards = stacks.map(function (s) {
        var pos;
        if (isHorizontal) {
            pos = {
                x: s.node.x / 2,
                y: t + s.node.y / 2
            };

            if (s.node.y > 0) {
                prev = s.node.y;
            }

            t += s.node.y || prev;
        } else {
            pos = {
                x: t + s.node.x / 2,
                y: s.node.y / 2
            };

            if (s.node.x > 0) {
                prev = s.node.x;
            }

            t += s.node.x || prev;
        }

        return s.cards.map(function (card) {
            if (isHorizontal) {
                return {
                    x: card.x + pos.x - maxSize / 2,
                    y: card.y + pos.y
                };
            }
            return {
                x: card.x + pos.x,
                y: card.y + pos.y - maxSize / 2
            };
        });
    });

    return {
        size: maxSize,
        cards: cards.flat()
    };
};

MagicStacks.prototype.fitToCanvas = function (option, stackType) {
    var isHorizontal = Game.currentGame.table.getOrientation() === TileTable.ORIENTATION_HORIZONTAL;

    var total = 0;

    var allFitted = true;
    var fitted = option.cfg.map(function (row) {
        var rowOption = {
            a: option.a,
            d: option.d,
            cfg: row
        };

        var fitted = this.fitRow(rowOption, isHorizontal ? this.canvas.height : this.canvas.width, stackType);
        if (fitted === undefined) {
            allFitted = false;
            return;
        }

        total += fitted.size;

        return fitted;
    }, this);

    var left = (isHorizontal ? this.canvas.width : this.canvas.height) - total;
    var t = Card.WIDTH;

    if (!allFitted || left < t * fitted) {
        return undefined;
    }

    var d = t;
    var cards = fitted.map(function (fitted) {
        var pos;
        if (isHorizontal) {
            pos = {
                x: t + fitted.size / 2,
                y: 0
            };

            t += d + fitted.size;
        } else {
            pos = {
                x: 0,
                y: t + fitted.size / 2
            };

            t += d + fitted.size;
        }

        return fitted.cards.map(function (card) {
            return {
                x: card.x + pos.x,
                y: card.y + pos.y
            };
        });
    });

    return cards.flat();
};

MagicStacks.BRUTE_FORCE_NUMBERS = function (cfg, n) {
    if (!Array.isArray(cfg)) {
        cfg = [cfg];
    }

    var total = new Array(10);
    for (var t = 0; t < total.length; t++) {
        total[t] = 0;
    }

    for (var i = 0; i < cfg.length; i++) {
        for (var j = 0; j < cfg[i].length; j++) {
            if (cfg[i][j] !== " ") {
                total[cfg[i].charCodeAt(j) - "a".charCodeAt(0)]++;
            }
        }
    }

    var options = [];
    for (var a = 1; a <= 10; a++) {
        for (var d = 1; d < 4; d++) {
            var sum = 0;
            var numbers = [];

            for (var k = 0; k < total.length; k++) {
                if (total[k] === 0) {
                    continue;
                }
                numbers.push(a + k * d);
                sum += total[k] * (a + k * d);
                if (a + k * d > MagicStacks.MAX_STACK) {
                    sum = Infinity;
                    break;
                }
            }
            if (sum === n) {
                options.push({
                    cfg: cfg,
                    a: a,
                    d: d,
                    numbers: numbers
                });
            }

            if (numbers.length === 1) {
                break;
            }
        }
    }

    return options;
};

// MagicStacks.CONFIGS = [
//     "a"
// ];

MagicStacks.CONFIGS = [
    "a",
    "aa",
    "aaa",
    "aaaa",
    "aaaaa",
    "aaaaaa",
    "a a",
    "aa aa",
    "aaa aaa",
    "a a a",
    "a bb a",
    "a aaa a",
    "a aba a",
    "a bcb a",
    "aa aa aa",
    "aa bb aa",
    "aba",
    "abba",
    "ab ba",
    "abcba",
    "abccba",
    "abc cba",
    [
        "aa",
        "aa"
    ],
    [
        "aa aa",
        "aa aa"
    ],
    [
        "aabaa",
        "aabaa"
    ],
    [
        "abbba",
        "abbba"
    ],
    [
        "aaa aaa",
        "abbba"
    ],
    [
        "abc cba",
        "abcba"
    ],
    [
        "abc cba",
        "aaaa"
    ],
    [
        "abc cba",
        "aaaaa"
    ],
    [
        "abc cba",
        "aaaaaa"
    ],
    [
        "aaa",
        "aaa"
    ],
    [
        "aaa",
        "aaa",
        "aaa"
    ],
    [
        "aaa aaa",
        "aa aa aa",
        "aaa aaa"
    ],
    [
        "aaa aaa",
        "aaa aaa",
        "aaa aaa"
    ],
    [
        "aaa aaa",
        "bbb bbb",
        "ccc ccc"
    ],
    [
        "aaa aaa",
        "abc cba",
        "aaa aaa"
    ],
    [
        "aaa bbb",
        "ccc ccc",
        "bbb aaa"
    ],
    [
        "aaaa",
        "aaaa"
    ],
    [
        "abba",
        "abba"
    ]
];

MagicStacks.DIRECTION = {
    DOWN: {
        x: 0,
        y: -Card.GRID_XY
    },

    UP: {
        x: 0,
        y: Card.GRID_XY
    },

    LEFT: {
        x: -Card.GRID_XY,
        y: 0
    },

    RIGHT: {
        x: Card.GRID_XY,
        y: 0
    }
};

MagicStacks.GENERATORS = {};
MagicStacks.GENERATORS.BY_DIRECTION = function (direction) {
    return function (n) {
        var cards = [];
        var x = 0;
        var y = 0;
        for (var i = 0; i < n; i++) {
            cards.push({
                x: x,
                y: y
            });

            x += direction.x;
            y += direction.y;
        }

        return cards;
    };
};

MagicStacks.GENERATORS.PYRAMID = function (n) {
    var nn = n;

    var level = 0;
    while (nn > 0) {
        level++;
        nn -= level * level;
    }

    var cards = [];
    var ll = 1;
    while (ll <= level && n > 0) {
        for (var i = 0; i < ll && n > 0; i++) {
            for (var j = 0; j < ll && n > 0; j++) {
                cards.push({
                    x: (i - (ll - 1) / 2) * Card.WIDTH,
                    y: (j - (ll - 1) / 2) * Card.HEIGHT
                });
                n--;
            }
        }
        ll++;
    }

    return cards.reverse();
};

MagicStacks.GENERATORS.STAIRS = function (n) {
    var pos = [{
        x: 0,
        y: 0
    }, {
        x: 1,
        y: 0
    }, {
        x: 1,
        y: 1
    }, {
        x: 0,
        y: 1
    }];

    var cards = [];
    for (var i = 0; i < n; i += 4) {
        for (var j = 0; j < 4 && i + j < n; j++) {
            cards.push({
                x: pos[j].x * Card.WIDTH / 2,
                y: pos[j].y * Card.HEIGHT / 2
            });
        }
    }

    return cards;
};

MagicStacks.STACKS = [
    MagicStacks.GENERATORS.BY_DIRECTION(MagicStacks.DIRECTION.DOWN),
    MagicStacks.GENERATORS.BY_DIRECTION(MagicStacks.DIRECTION.UP),
    MagicStacks.GENERATORS.BY_DIRECTION(MagicStacks.DIRECTION.LEFT),
    MagicStacks.GENERATORS.BY_DIRECTION(MagicStacks.DIRECTION.RIGHT),
    MagicStacks.GENERATORS.PYRAMID,
    MagicStacks.GENERATORS.STAIRS
];

MagicStacks.PADDING = {
    0: 20,
    1: 20,
    2: 20,
    3: 20,
    5: 20
};