/**
 * Created by Andrey Popov on 11.09.20
 */

var StreakCardView = CardView.extend({
    ctor: function (card) {
        this._super(card, {
            animation_json: bundles.card.jsons.streak_card_json
        });

        var styles = cleverapps.styles.StreakCardView;
        this.setContentSize2(styles.width, styles.height);
        this.animation.setPosition(this.width / 2, this.height / 2);

        this.card.on("incLevel", this.incLevel.bind(this));
        this.card.on("changeLevel", this.updateProgressBar.bind(this));
        this.card.on("reset", this.reset.bind(this));
        this.card.on("beforeSpawn", this.beforeSpawn.bind(this));
        this.card.on("spawn", this.spawn.bind(this));

        this.card.on("hide", this.hide.bind(this));

        this.addProgressBar();
        this.updateProgressBar(card.level, true);

        if (cleverapps.environment.isEditorScene()) {
            this.addIncDecBlock();
        }
    },

    incLevel: function (card, bone) {
        if (this.visible && bone) {
            var boneView = new MarkView(bone);
            card.onGetView().addChild(boneView);
            boneView.show(true);
            boneView.collect(this);
        }

        if (!this.collectionInProgress) {
            this.collectionInProgress = true;

            this.animation.setAnimation(0, "collection", false);
            this.animation.setCompleteListener(function () {
                this.animation.setCompleteListener();
                this.collectionInProgress = false;
                this.setIdleAnimation();
            }.bind(this));
        }

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.6),
            new cc.CallFunc(function () {
                this.updateProgressBar();
                cleverapps.audio.playSound(bundles.game.urls.dog_scan_effect, { throttle: 1000 });
            }.bind(this))
        ));
    },

    updateProgressBar: function (level, silent) {
        var levelToSet = level || this.card.level;
        if (levelToSet > this.card.counter) {
            return;
        }

        this.progressBar.setGoal(this.card.counter);
        this.progressBar.setPercentage(levelToSet, {
            animated: !silent
        });
        this.barText.setString(this.card.counter - levelToSet);
    },

    reset: function () {
        this.animation.setAnimation(0, "fail", false);
        this.animation.setCompleteListener(function () {
            this.animation.setCompleteListener();
            this.setIdleAnimation();
        }.bind(this));

        this.updateProgressBar();

        cleverapps.audio.playSound(bundles.game.urls.dog_sad_effect);
    },

    beforeSpawn: function () {
        this.runAction(new cc.Sequence(
            new cc.DelayTime(1),
            new cc.CallFunc(function () {
                this.animation.setAnimation(0, "full", false);
                this.animation.setCompleteListener(function () {
                    this.animation.setCompleteListener();
                    this.setIdleAnimation();
                    if (this.card.level === 0) {
                        this.updateProgressBar(0, true);
                    }
                }.bind(this));
            }.bind(this)),
            new cc.DelayTime(0.4),
            new cc.PlaySound(bundles.game.urls.dog_reward_effect)
        ));
    },

    spawn: function (card) {
        var ViewClass = card.getViewClass();
        var view = new ViewClass(card);
        this.addChild(view);
        view.setPosition(this.width / 2, this.height / 2 + cleverapps.styles.StreakCardView.card.offsetY);
    },

    appear: function () {
        this.animation.setAnimation(0, "open", false);
        this.animation.setCompleteListener(function () {
            this.animation.setCompleteListener();
            this.barIcon.visible = true;
            this.progressBar.visible = true;
            this.setIdleAnimation();
        }.bind(this));
    },

    onFlip: function () {
        if (this.card.isOpen()) {
            this.appear();
        }
    },

    animateLose: function () {
        if (this.progressBar) {
            this.progressBar.setVisible(false);
        }
        if (this.barIcon) {
            this.barIcon.setVisible(false);
        }
        this.animation.setAnimation(0, "close", false);
        this.animation.getAnimationDuration("close");
        return this.animation.getAnimationDuration("close");
    },

    addProgressBar: function () {
        var styles = cleverapps.styles.StreakCardView.progressBar;

        var progressBar = this.progressBar = new ScaledProgressBar({
            background: bundles.progress_bar.frames.bg_streakprogress,
            progress: bundles.progress_bar.frames.bar_streakprogress,
            minSize: cc.size(0, 0)
        });
        progressBar.setAnchorPoint(0, 0);
        progressBar.setPercentage(0, {
            silent: true
        });
        progressBar.setLength(styles.width);
        progressBar.setPositionRound(styles);

        var barIcon = this.barIcon = new cc.Sprite(bundles.card.frames.streak_progress_circle_png);
        barIcon.setPositionRound(styles.barIcon);
        this.addChild(barIcon, 1);

        var barText = this.barText = cleverapps.UI.generateImageText(this.card.counter, cleverapps.styles.FONTS.STREAK_PROGRESS_BAR_TEXT);
        barText.setPositionRound(styles.barText);
        barIcon.addChild(barText);
        this.addChild(progressBar);

        if (!cleverapps.environment.isSceneWithPreview()) {
            this.barIcon.visible = false;
            this.progressBar.visible = false;
        }
    },

    addIncDecBlock: function () {
        var incDecBlock = new IncDecBlock({
            value: this.card.counter,
            onChange: function (value) {
                this.card.counter = value;
                this.updateProgressBar(0, true);
                Editor.currentEditor.save();
            }.bind(this)
        });
        incDecBlock.setPosition(this.width / 2, this.height / 2);
        this.addChild(incDecBlock);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    STREAK_PROGRESS_BAR_TEXT: {
        size: 32,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.WHITE_TEXT_STROKE
    }
});

cleverapps.styles.StreakCardView = {
    width: 200,
    height: 200,
    progressBar: {
        width: 140,
        x: { align: "center", dx: 15 },
        y: { align: "bottom", dy: -7 },

        barIcon: {
            x: { align: "left", dx: -3 },
            y: { align: "bottom", dy: -30 }
        },
        barText: {
            x: { align: "left", dx: 18 },
            y: { align: "bottom", dy: 12 }
        }
    },
    card: {
        offsetY: -70
    }
};