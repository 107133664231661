/**
 * Created by vladislav on 11/01/2023
 */

var TileColorsEditorView = cleverapps.Layout.extend({
    ctor: function (tileColorsEditor) {
        var maxAmount = 0;
        while (bundles.card.frames["skin_" + maxAmount]) {
            maxAmount++;
        }

        var incDec = new IncDecBlock({
            value: tileColorsEditor.getCurrentValue(),
            range: [1, maxAmount],
            onChange: tileColorsEditor.setValue.bind(tileColorsEditor)
        });

        var styles = cleverapps.styles.TileColorsEditorView;

        var label = cleverapps.UI.generateTTFText("Colours:", cleverapps.styles.FONTS.EDITOR_BLUE_TEXT);
        this._super([label, incDec], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });

        tileColorsEditor.onChangeValue = this.createListener(function () {
            incDec._setValue(tileColorsEditor.getCurrentValue());
        });
    }
});

cleverapps.styles.TileColorsEditorView = {
    margin: 50
};