/**
 * Created by Andrey Popov on 01.09.20
 */

var CheeseCardView = CardView.extend({
    ctor: function (card) {
        this._super(card, {
            animation_json: bundles.card.jsons.mouse_json
        });

        this.card.on("feed", this.createListener(this.feed.bind(this)));
    },

    feed: function (mouseCard) {
        cleverapps.audio.playSound(bundles.game.urls.mouse_card_effect);
        this.runAction(new cc.Sequence(
            cleverapps.UI.animateCard({
                cardView: this,
                position: mouseCard.getViewPosition.bind(mouseCard),
                duration: CheeseCard.PLAY_TIMEOUT,
                animation: cleverapps.UI.animateCard.ANIMATIION_BIT,
                replaceParentBack: false
            }),
            new cc.CallFunc(function () {
                this.animation.setAnimation(0, "bounce2", false);
                this.animation.setCompleteListener(function () {
                    this.animation.setCompleteListener();
                    this.runAction(new cc.RemoveSelf());
                }.bind(this));
            }, this)
        ));
    },

    setIdleAnimation: function () {
        this.animation.setAnimation(1, this.card.isOpen() ? "cheese_idle" : "idle_close", true);
    }
});