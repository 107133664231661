/**
 * Created by mac on 2/24/21
 */

cleverapps.override(cleverapps.Tool.game, {
    openCard: function () {
        if (Game.currentGame.open.isFull()) {
            return;
        }

        var missed = Game.currentGame.table.listMissed();
        if (missed.length !== 0) {
            Game.currentGame.table.startPlayCard(missed[0]);
        }
    },

    addJackpot: function () {
        Game.currentGame.addJackpot(function () {});
    },

    movesReset: function () {
        while (!Game.currentGame.hand.isEmpty()) {
            Game.currentGame.shiftCard();
        }
    },

    addHardReward: function () {
        Game.currentGame.addHardReward(10);
    },

    nextScreen: function () {
        var page = Game.currentGame.pagination.getCurrent();

        var screen = Game.currentGame.level.content.screens[page];

        Game.currentGame.table.setCards([], [], screen.maxValue);
        Game.currentGame.table.setRect();
        Game.currentGame.table.updateSize();
        Game.currentGame.counter.trigger();
    }
});

cleverapps.override(cleverapps.Tool.Debug, {
    toggleGoldOnCards: function () {
        cleverapps.flags.goldOnCardsDisabled = !cleverapps.flags.goldOnCardsDisabled;

        cleverapps.notification.create((cleverapps.flags.goldOnCardsDisabled ? "Disabled" : "Enabled") + " level gold");
    },

    handDiffToogleUser: cleverapps.config.type === "solitaire" ? ToolFolder.create(function () {
        var res = {};
        var update = function () {
            if (res["old_user"]) {
                cleverapps.user.registered = TriPeaks.START_HAND_AB_TEST_TIME + 100;
                cleverapps.abTest.logEvent(cleverapps.ABTest.Tests.handdiff, "dau_new");
            } else {
                cleverapps.user.registered = TriPeaks.START_HAND_AB_TEST_TIME - 100;
                cleverapps.abTest.logEvent(cleverapps.ABTest.Tests.handdiff, "dau_old");
            }
            cleverapps.toolModel.refresh();
        };

        if (cleverapps.user.registered < TriPeaks.START_HAND_AB_TEST_TIME) {
            res["old_user"] = update;
        } else {
            res["new_user"] = update;
        }

        return res;
    }) : undefined
});
