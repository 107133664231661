/**
 * Created by andrey on 19.08.2020.
 */

var WeakJokerCardView = CardView.extend({
    ctor: function (card) {
        this._super(card, {
            animation_json: bundles.card.jsons.weakjoker_json
        });
    }
});