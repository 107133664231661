/**
 * Created by Ivan on 01.08.2024
 */

var Prize = function (position, radius, index) {
    Body.call(this, position, radius);
    this.index = index;
};

Prize.prototype = Object.create(Body.prototype);
Prize.prototype.constructor = Prize;

Prize.prototype.calcDist = function (ball) {
    var ballPos = ball.position;
    var ballRadius = ball.radius;
    var prizeRadius = this.radius;

    var distance = cc.pDistance(ballPos, this.position);
    if (distance <= ballRadius + prizeRadius) {
        return 0;
    }

    return cc.pDistance(ball.position, this.position);
};
