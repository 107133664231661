/**
 * Created by denis on 7 august 2020
 */

var Dice = function () {
    cleverapps.EventEmitter.call(this);
};

Dice.prototype = Object.create(cleverapps.EventEmitter.prototype);
Dice.prototype.constructor = Dice;

Dice.prototype.roll = function () {
    this.number = cleverapps.Random.choose([1, 2, 3, 4, 5, 6]);
    this.trigger("roll", this.number);
};

Dice.prototype.reward = function () {
    setTimeout(function () {
        var cards = [];

        for (var i = 0; i < this.number || 0; i++) {
            cards.push({
                feature: "regular",
                x: 0,
                y: 0
            });
        }

        cards.map(function (card) {
            return TileFactory.Create(card, {
                noMarks: true
            });
        }).forEach(function (card, i) {
            setTimeout(function () {
                this.trigger("reward", card, function () {
                    Game.currentGame.hand.push(card);
                });
            }.bind(this), i * 200);
        }, this);
    }.bind(this), 1800);
};