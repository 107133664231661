/**
 * Created by denis on 7 august 2020
 */

var DiceView = cc.Node.extend({
    ctor: function (dice) {
        this._super();

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(cleverapps.styles.DiceView.size);
        this.setPositionRound(cleverapps.styles.DiceView.position);

        this.dice = dice;

        this.dice.on("roll", this.roll.bind(this));
        this.dice.on("reward", this.reward.bind(this));
    },

    roll: function (number) {
        cleverapps.audio.playSound(bundles.game.urls.appearance_dice_effect);

        var animation = new cleverapps.Spine(bundles.boosters_before_game.jsons.boost_dice_json);
        animation.setAnimation(0, "open_" + number, false);
        animation.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(animation);

        animation.runAction(new cc.Sequence(
            new cc.DelayTime(1.6 + number * 0.2),
            new cc.ScaleTo(0.2, 0),
            new cc.RemoveSelf()
        ));
    },

    reward: function (card, callback) {
        var styles = cleverapps.styles.DiceView.card;

        var ViewClass = card.getViewClass();
        var cardView = new ViewClass(card);

        cardView.setPositionRound(this.width / 2, this.height / 2 + styles.offsetY);
        cardView.setScale(0);
        this.addChild(cardView);

        cleverapps.audio.playSound(bundles.game.urls.plus_card_effect, {
            throttle: 150
        });

        cardView.runAction(new cc.Sequence(
            new cc.ScaleTo(0.1, 1),
            new cc.CallFunc(callback)
        ));
    }
});

cleverapps.styles.DiceView = {
    size: {
        width: 400,
        height: 400
    },

    position: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 }
    },

    card: {
        offsetY: 75
    }
};
