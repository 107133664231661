/**
 * Created by mac on 4/27/20
 */

var EditorHandView = HandView.extend({
    ctor: function (hand, open, log) {
        this._super(hand, open, log);

        var amountGauge = new IncDecBlock({
            radius: cleverapps.styles.EditorHandView.amount.radius,
            value: hand.stack.length,
            hint: "cards amount",
            range: [0, 30],

            onInc: function () {
                hand.push();
                Editor.currentEditor.save();
                this.adjustPositions(true);
            }.bind(this),

            onDec: function () {
                var card = hand.stack.pop();
                hand.trigger("shift");
                card.trigger("editorRemove");
                Editor.currentEditor.save();
                this.adjustPositions(true);
            }.bind(this)
        });

        amountGauge.setPositionRound(cleverapps.styles.EditorHandView.amount.position);
        this.addChild(amountGauge);

        var difficultyGauge = new IncDecBlock({
            radius: cleverapps.styles.EditorHandView.difficulty.radius,
            value: hand.difficulty,
            icons: "updown",
            hint: "difficulty",

            onChange: function (value) {
                hand.difficulty = value;
                Editor.currentEditor.save();
            }
        });

        difficultyGauge.setPositionRound(cleverapps.styles.EditorHandView.difficulty.position);
        this.addChild(difficultyGauge);

        this.setPositionRound(cleverapps.styles.EditorHandView);
    }
});

cleverapps.styles.EditorHandView = {
    x: {
        align: "left",
        dx: 700
    },
    y: 90,
    amount: {
        radius: 50,
        position: {
            x: { align: "right", dx: 230 },
            y: { align: "bottom", dy: 0 }
        }
    },
    difficulty: {
        radius: 50,
        position: {
            x: { align: "right", dx: 230 },
            y: { align: "bottom", dy: -60 }
        }
    }
};