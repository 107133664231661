/**
 * Created by Andrey Popov on 08.10.20
 */

var SharkCardView = CardView.extend({
    ctor: function (card) {
        this._super(card);

        this.setAnchorPoint(0.5, 0.5);

        card.on("hide", this.hide.bind(this));
        card.on("changeValue", this.changeValue.bind(this));

        this.updateDaggersVisibility();

        if (!cleverapps.environment.isSceneWithPreview()) {
            card.on("removeFromTable", this.updateDaggersVisibility.bind(this));
            card.on("eatCard", this.eatCard.bind(this));
            card.on("removeDaggers", this.removeDaggers.bind(this));
        }
    },

    hide: function () {
        if (this.daggers) {
            this.daggers.removeFromParent();
        }
        this.removeFromParent();
    },

    changeValue: function () {
        if (this.daggers) {
            this.daggers.setAnimationAndIdleAfter(this.getOpenAnimationName(), this.getIdleAnimationName());
        }
        cleverapps.audio.playSound(bundles.game.urls.shark_effect);
    },

    animateReturnToScreen: function () {
        this._super();

        this.updateDaggersVisibility();
    },

    updateDaggersVisibility: function () {
        var visible = this.card.isOpen() || cleverapps.environment.isSceneWithPreview();
        if (!this.daggers && visible) {
            this.daggers = new cleverapps.Spine(bundles.card.jsons.shark_json);
            this.daggers.setAnimationAndIdleAfter("appear", this.getIdleAnimationName());
            this.daggers.setPosition(cleverapps.styles.CardView.width / 2, cleverapps.styles.CardView.height / 2);
            this.addChild(this.daggers);
        } else if (!visible && this.daggers) {
            this.removeDaggers();
        }
    },

    getOpenAnimationName: function () {
        return "open_" + Math.min(2, (this.card.angryLevel > 0 ? this.card.angryLevel - 1 : 2));
    },

    getIdleAnimationName: function () {
        return "idle_" + Math.min(2, this.card.angryLevel);
    },

    onFlip: function () {
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.CallFunc(this.updateDaggersVisibility.bind(this))
        ));
        CardView.prototype.onFlip.call(this);
    },

    animateLose: function (index) {
        if (this.daggers) {
            this.daggers.removeFromParent();
        }
        return CardView.prototype.animateLose.call(this, index);
    },

    attack: function () {
        if (this.daggers) {
            this.daggers.removeFromParent();
        }
        CardView.prototype.attack.call(this);
    },

    eatCard: function (card, eatDelay) {
        var cardView = card.onGetView();
        var timeScale = cleverapps.styles.SharkCardView.normalDistance / cc.pDistance(this.convertToWorldSpace(), cardView.convertToWorldSpace());

        this.runAction(new cc.Sequence(
            new cc.DelayTime(eatDelay),
            new cc.CallFunc(function () {
                if (this.daggers) {
                    this.daggers.setAnimationAndIdleAfter("finish", "idle_0");
                }
            }.bind(this)),
            new cc.DelayTime(0.4),

            new cc.CallFunc(function () {
                cardView.runAction(new cc.Sequence(
                    new cc.PlaySound(bundles.game.urls.shark_effect_eat),
                    new cc.Spawn(
                        cleverapps.UI.animateCard({
                            cardView: cardView,
                            position: {
                                parent: this.parent,
                                point: this.getPosition()
                            },
                            duration: 0.333 / timeScale,
                            animation: "ice_fly"
                        }),
                        new cc.ScaleTo(0.3, 0.7)
                    ),
                    new cc.RemoveSelf()
                ));
            }.bind(this))
        ));
    },

    removeDaggers: function () {
        if (this.daggers) {
            this.daggers.setAnimation(0, "off_card" + Math.min(2, this.card.angryLevel), false);
            this.daggers.setCompleteListenerRemove();
            this.daggers = undefined;

            cleverapps.audio.playSound(bundles.game.urls.shark_effect_collect);
        }
    }
});

cleverapps.styles.SharkCardView = {
    normalDistance: 800
};