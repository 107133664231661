/**
 * Created by mac on 3/4/20
 */

var OpenCardsView = cc.Node.extend({
    ctor: function (openCards) {
        this._super();

        this.openCards = openCards;

        this.slots = [];
        this.slotsAmount = this.openCards.limit;

        this.updateSize();

        for (var i = 0; i < this.slotsAmount; i++) {
            this.addSlot();
        }

        this.bg = new cleverapps.Spine(bundles.card.jsons.opencards_bg_json);
        this.bg.setAnimation(0, "idle", false);
        this.addChild(this.bg, -1);

        var fg = this.fg = new cleverapps.Spine(bundles.card.jsons.opencards_fg_json);
        fg.setAnimation(0, "idle", false);
        this.addChild(fg, 1000);

        this.setAnchorPoint(0.5, 0.5);
        this.setupChildren();

        openCards.on("explode", this.createListener(this.explode.bind(this)));
        openCards.on("overflow", this.createListener(this.overflowAnimation.bind(this)));
        openCards.on("warning", this.createListener(this.setWarning.bind(this)));
        openCards.on("addExtraSlot", this.createListener(this.addExtraSlot.bind(this)));
        openCards.on("removeExtraSlot", this.createListener(this.removeExtraSlot.bind(this)));
        openCards.on("hide", this.hideAnimation.bind(this));

        this.openCards.getView = function () {
            return this;
        }.bind(this);

        this.createWarningAnimation();
        this.createCards();
    },

    updateSize: function () {
        var styles = cleverapps.styles.OpenCardsView;
        this.setContentSize(this.slotsAmount * styles.slotSize, styles.slotSize);
        var scale = cleverapps.resolution.getSceneSize().width / (this.width + 2 * cleverapps.styles.OpenCardsView.scenePaddingX);
        this.setScale(Math.min(scale, 1));
    },

    setupChildren: function () {
        var styles = cleverapps.styles.OpenCardsView;
        this.bg.setPositionRound(styles.bg);
        this.fg.setPositionRound(styles.fg);
        this.setPositionRound(cleverapps.styles.OpenCardsView.position);
    },

    completeAnimationOnResize: function () {
        this.fg.stopAllActions();
        if (this.fg.parent !== this) {
            this.fg.replaceParentSamePlace(this);
            this.fg.setScale(1);
        }
    },

    hideAnimation: function () {
        var styles = cleverapps.styles.OpenCardsView;
        this.runAction(
            new cc.Sequence(
                new cc.MoveBy(0.3, 0, styles.hideYOffset).easing(cc.easeBackIn(2)),
                new cc.Hide()
            )
        );
    },

    addSlot: function () {
        var styles = cleverapps.styles.OpenCardsView;
        var slot = new cc.Node();
        slot.setAnchorPoint(0.5, 0.5);
        slot.setContentSize2(styles.slotSize, styles.slotSize);
        var slotPosition = cc.p(this.slots.length * styles.slotSize + styles.slotSize / 2, 0 + styles.slotSize / 2);
        slot.setPosition(slotPosition);
        this.slots.push(slot);
        this.addChild(slot);
    },

    addExtraSlot: function (extraSlot) {
        var movingNode = cleverapps.scenes.getMovingNode(this);

        this.fg.replaceParentSamePlace(movingNode, {
            keepScale: true
        });

        for (var i = 0; i < this.openCards.cards.length; i++) {
            var cardView = this.openCards.cards[i].onGetView();

            cardView.replaceParentSamePlace(movingNode, {
                keepScale: true
            });
        }

        this.slotsAmount++;
        this.addSlot();
        this.updateSize();
        this.createExtraSlotButton(extraSlot);

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.23),
            new cc.CallFunc(function () {
                for (var i = 0; i < this.openCards.cards.length; i++) {
                    var cardView = this.openCards.cards[i].onGetView();
                    cardView.realParent = this;
                    cardView.moveToOpen(0.25);
                }
            }.bind(this))
        ));

        this.fg.runAction(new cc.Sequence(
            new cc.DelayTime(0.5),
            new cc.CallFunc(function () {
                this.fg.replaceParentSamePlace(this);
                this.fg.setScale(1);
            }.bind(this))
        ));

        this.warningAnimation.setAnimation(0, "animation2", true);

        this.bg.setAnimationAndIdleAfter("open_btn", "idle_btn");
        this.fg.setAnimationAndIdleAfter("open_btn", "idle_btn");
    },

    removeExtraSlot: function () {
        this.removeChild(this.slots.pop());
        var movingNode = cleverapps.scenes.getMovingNode(this);

        this.fg.replaceParentSamePlace(movingNode, {
            keepScale: true
        });

        for (var i = 0; i < this.openCards.cards.length; i++) {
            var cardView = this.openCards.cards[i].onGetView();

            cardView.replaceParentSamePlace(movingNode, {
                keepScale: true
            });
        }

        this.slotsAmount--;
        this.updateSize();

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.23),
            new cc.CallFunc(function () {
                for (var i = 0; i < this.openCards.cards.length; i++) {
                    var cardView = this.openCards.cards[i].onGetView();
                    cardView.realParent = this;
                    cardView.moveToOpen(0.25);
                }
            }.bind(this))
        ));

        this.fg.runAction(new cc.Sequence(
            new cc.DelayTime(0.5),
            new cc.CallFunc(function () {
                this.fg.replaceParentSamePlace(this);
                this.fg.setScale(1);
            }.bind(this))
        ));

        this.warningAnimation.setAnimation(0, "animation", true);
        this.setWarning(this.openCards.checkWarning());

        this.bg.setAnimationAndIdleAfter("close_btn", "idle");
        this.fg.setAnimationAndIdleAfter("close_btn", "idle");
    },

    createExtraSlotButton: function (extraSlot) {
        this.extraSlotButton = new ExtraSlotButton(extraSlot);

        this.extraSlotButton.setAnchorPoint(0.5, 1);
        this.extraSlotButton.setPosition(cleverapps.styles.OpenCardsView.slotSize / 2, cleverapps.styles.OpenCardsView.slotSize);
        this.slots[this.slots.length - 1].addChild(this.extraSlotButton);
        this.extraSlotButton.animateAppear();
    },

    findPosForCard: function (index) {
        return this.slots[index].getPosition();
    },

    overflowAnimation: function () {
        var shakeAction = AnimationsLibrary.shake(this, {
            power: 2,
            moveTime: 0.05,
            delayTimeAfterMove: 0.01,
            points: [
                { x: 0, y: 3, r: 0 },
                { x: 0, y: 0, r: 0 },
                { x: 3, y: 3, r: 0 },
                { x: 0, y: 1, r: 0 },
                { x: -3, y: 3, r: 0 },
                { x: 0, y: 0, r: 0 },
                { x: 0, y: 2, r: 0 },
                { x: 0, y: 0, r: 0 }
            ]
        });
        this.stopAllActions();
        this.runAction(shakeAction);
    },

    createWarningAnimation: function () {
        var styles = cleverapps.styles.OpenCardsView.warning;
        var warningAnimation = this.warningAnimation = new cleverapps.Spine(bundles.card.jsons.open_warning_json);
        warningAnimation.setAnimation(0, "animation", true);
        warningAnimation.setPositionRound(styles);
        this.addChild(warningAnimation);
        warningAnimation.setVisible(false);
    },

    setWarning: function (warning) {
        this.warningAnimation.setVisible(warning);
    },

    explode: function (cards, isLast) {
        var animationNames = cleverapps.shuffle(CardView.getExplodeAnimationNames());
        var cardViews = cards.map(function (card) {
            return card.onGetView();
        });
        var explode3Cards = function () {
            cleverapps.audio.playSound(bundles.game.urls.collection_effect);
            cardViews.forEach(function (cardView, i) {
                cardView.explode(animationNames[i]);
            });
        };
        if (isLast) {
            cardViews[0].runAction(
                new cc.Spawn(
                    new cc.Sequence(
                        new cc.DelayTime(0.1),
                        new cc.RotateBy(0.4, 360)
                    ),
                    new cc.Sequence(
                        new cc.MoveBy(0.25, 0, cleverapps.styles.OpenCardsView.explodeJump.dy).easing(cc.easeOut(3)),
                        new cc.MoveBy(0.25, 0, -cleverapps.styles.OpenCardsView.explodeJump.dy).easing(cc.easeIn(3)),
                        new cc.CallFunc(explode3Cards)
                    )
                )
            );
        } else {
            explode3Cards();
        }
    },

    createCards: function () {
        this.openCards.cards.forEach(function (card) {
            var cardView = CardView.createCardView(card);
            this.addChild(cardView);
            cardView.setPositionRound(card.getViewPosition().point);
            cardView.setScale(cleverapps.styles.OpenCardsView.slotSize / cleverapps.styles.CardView.width);
        }, this);
    }
});

cleverapps.styles.OpenCardsView = {
    position: [
        {
            x: { align: "center" },
            y: { align: "bottom", dy: 200 }
        },
        {
            x: { align: "center" },
            y: { align: "bottom", dy: 48 }
        },
        {
            x: { align: "center" },
            y: { align: "bottom", dy: 48 }
        }
    ],
    bg: {
        x: { align: "center", dx: -1 },
        y: { align: "bottom", dy: -35 }
    },

    fg: {
        x: { align: "center", dx: -1 },
        y: { align: "bottom", dy: -28 }
    },

    warning: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -16 }
    },

    slotSize: 120,

    scenePaddingX: 30,

    explodeJump: {
        dy: 300
    },

    hideYOffset: -400
};